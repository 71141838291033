import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2'; // Import the Doughnut chart component
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin for data labels
import { ChartContain } from '../../../style/AM/chart/Chart';


// Register the necessary Chart.js components and the plugin
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);



const DoughnutChart = ({ data }) => {
  // Chart options (same as before)
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
        color: '#fff',
        formatter: (value) => {
          if (value === 0) return null;
          return value;
        },
        anchor: 'center',
        align: 'center',
        font: {
          weight: 'bold',
          size: 16,
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true, // Enable tooltips
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `Value: ${value}`;
          },
        },
      },
    },
  };


  return (
    <ChartContain>
      <Doughnut data={data} options={options} />
    </ChartContain>
  );
};

export default DoughnutChart;
