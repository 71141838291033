import NewPassword from './pages/login/Newpassword';
import SendOtp from './pages/login/Sendotp';
import GetOtp from './pages/login/Getotp';
import Forgot from './pages/login/Forgot';
import Login from './pages/login/Login';
import Support from './pages/login/Support';
import TicketHistory from './pages/login/TicketHistory';
import Profile from './pages/Profile/Profile';
import Home from './pages/AM/Home';
// import Forgot from './pages/login/Forgot';
// import GetOtp from './pages/login/Getotp';
// import SendOtp from './pages/login/Sendotp';
// import NewPassword from './pages/login/Newpassword';
import HoHome from './pages/HO/Home';
import CreateMVRManual from './pages/HO/CreateMVRManual';
import AuthGuard from './guards/AuthGuards';
import MVRManualCreation from './pages/HO/MVRManualCreation';
import QVRManualCreation from './pages/HO/QVRManualCreation';
import AMDStatus from './pages/AM/AMDStatus';
import MVRFormFill from './pages/AM/MVRFormFill';
import Approval from './pages/HO/Approval';
import ScheduleVisit from './pages/AM/ScheduleVisit';
import MoMStatus from './pages/AM/MoMStatus';
import MoMStatusUpdate from './pages/AM/MoMStatusUpdate';
import MoMEscalatedPoints from './pages/AM/MoMEscalatedPoints';
import Analytics from './pages/AM/Analytics';
import Gallery from './pages/AM/Gallery';
import MVRSchedule from './pages/MVRSchedule';
import MVRMom_Form from './pages/MVRMom_Form';
import PopupNotification from './Components/PopUp';
import MVR_ScoreCard from './pages/MVR_ScoreCard';
import MVRApproval from './pages/HO/MVRApproval';
import HierachyMaster from './pages/Hierachy/HierachyMaster';
import MvrTable from './Components/MvrTable/MvrTable';
import Dealer_Master from './pages/Dealer/Dealer_Master';
import LogicMaster from './pages/HO/LogicMaster';
import HoReport from './pages/HO/HoReport';
import MoMEscalation from './pages/AM/MoMEscalation';
import Credential from './pages/HO/Credential';



// extra page for demo modal open
import ModalPage from './pages/ModalPage';


const routes = [
  { path: '/', element: <Login /> },
  { path: '/forgot', element: <Forgot /> },
  { path: '/get-otp', element: <GetOtp /> },
  { path: '/send-otp', element: <SendOtp /> },
  { path: '/new-password', element: <NewPassword /> },
  { path: '/home', element: <AuthGuard><Home /></AuthGuard> },
  { path: '/support', element: <AuthGuard><Support /></AuthGuard> },
  { path: '/ticket-history', element: <AuthGuard><TicketHistory /></AuthGuard> },
  { path: '/profile', element: <AuthGuard><Profile /></AuthGuard> },

  // AM links
  { path: '/amdstatus', element: <AuthGuard><AMDStatus /></AuthGuard> },
  { path: '/mvrformfill', element: <AuthGuard><MVRFormFill /></AuthGuard> },
  { path: '/schedulevisit', element: <AuthGuard><ScheduleVisit /></AuthGuard> },
  { path: '/momstatus', element: <AuthGuard><MoMStatus /></AuthGuard> },
  { path: '/momstatusupdate', element: <AuthGuard><MoMStatusUpdate /></AuthGuard> },
  { path: '/momescalatedpoints', element: <AuthGuard><MoMEscalatedPoints /></AuthGuard> },
  { path: '/mom-escalation', element: <AuthGuard><MoMEscalation/></AuthGuard>},
  { path: '/analytics', element: <AuthGuard><Analytics /></AuthGuard> },
  { path: '/gallery', element: <AuthGuard><Gallery /></AuthGuard> },
  // ho links 
  { path: '/hohome', element: <AuthGuard><HoHome /></AuthGuard> },
  { path: '/hocreatemanual', element: <AuthGuard><CreateMVRManual /></AuthGuard> },
  { path: '/mvrmanualcreation', element: <AuthGuard><MVRManualCreation /></AuthGuard> },
  { path: '/qvrmanualcreation', element: <AuthGuard><QVRManualCreation /></AuthGuard> },
  { path: '/approval', element: <AuthGuard><Approval /></AuthGuard> },
  { path: '/mvr-schedule', element: <AuthGuard><MVRSchedule /></AuthGuard> },
  { path: '/mvr-mom-form', element: <AuthGuard><MVRMom_Form /></AuthGuard> },
  { path: '/mvr-score-card', element: <AuthGuard><MVR_ScoreCard /></AuthGuard> },
  { path: '/mvr-approval', element: <AuthGuard><MVRApproval /></AuthGuard> },
  { path: '/hierarchy-master', element: <AuthGuard><HierachyMaster /></AuthGuard> },
  { path: '/mvr-logic-master', element: <AuthGuard><LogicMaster/></AuthGuard>},
  { path: '/reports', element: <AuthGuard><HoReport /></AuthGuard> },
  { path: '/credential', element: <AuthGuard><Credential /></AuthGuard> },

  // Dealer
  { path: '/dealer-master', element: <AuthGuard><Dealer_Master/></AuthGuard>},

  // extra for demo modal open this page can delete
  { path: '/modal', element: <AuthGuard><ModalPage /></AuthGuard> },
  { path: '/mvr-preview', element: <AuthGuard><MvrTable /></AuthGuard> },
  { path: '/popup-notification', element: <AuthGuard><PopupNotification/></AuthGuard>},
  
];

export default routes;
