import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import { PostPopUpData } from "../services/popUpService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetPopUpData,
  DeletePopUpData,
  UpdatePopUpData,
} from "../services/popUpService";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CloseIcon from "@mui/icons-material/Close";
import { CommanTableContain } from "../style/table";
import "../css/popUp.css";
import ConfirmationDialog from "./utils/Confirmation";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const PopupNotification = () => {
  const [rows, setRows] = useState([]);
  const [acceptedFileType, setAcceptedFileType] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [isDisable, setDisbled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [formData, setFormData] = useState({
    validFrom: "",
    validTo: "",
    message: "",
    documentType: "image",
    toHo: "",
    toRm: "",
    toZm: "",
    toAm: "",
    toDealer: "",
    document: null,
  });

  const handleAddRow = () => {
    setRows([
      {
        id: rows.length + 1,
        DocumentType: "",
        message: "",
        document: "",
        fromDate: null,
        toDate: null,
        ho: false,
        rm: false,
        zm: false,
        am: false,
        dealer: false,
        isEditing: true,
        isNewRow: true,
      },
      ...rows,
    ]);
  };

  const fetchPopUpData = async () => {
    try {
      const response = await GetPopUpData();// Add this log to inspect the response data
      if (response?.data) {
        const formattedRows = response.data.map((item) => ({
          id: item.id,
          DocumentType: item.documentType === "image" ? "image" : "PDF",
          message: item.message,
          document: item.fileUrl,
          fromDate: dayjs(item.validFrom),
          toDate: dayjs(item.validTo),
          ho: item.toHo || false,
          rm: item.toRm || false,
          zm: item.toZm || false,
          am: item.toAm || false,
          dealer: item.toDealer || false,
          isEditing: false,
        }));
        setRows(formattedRows);
      }
    } catch (error) {
      toast.error("Error fetching data.");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPopUpData();
  }, []);

  const handleInputChange = (id, field, value) => {
    setRows(
      rows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleDropdownChange = (selectedType) => {
    setDropdownValue(selectedType);
    if (selectedType === "Document") {
      setAcceptedFileType(".pdf");
    } else if (selectedType === "Photo") {
      setAcceptedFileType(".jpg, .jpeg, .png, .webp");
    } else {
      setAcceptedFileType("");
    }
  };

  const handleFileChange = (id, event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected.");
      return;
    }
    const maxFileSize = 5 * 1024 * 1024;
    const isValidFile = acceptedFileType
      .split(",")
      .some((type) => file.type.includes(type.trim()));

    if (!isValidFile) {
      toast.error("Unsupported file type! Please select a valid file.");
      event.target.value = "";
      return;
    }

    if (file.size > maxFileSize) {
      toast.error("File size exceeds the 5 MB limit.");
      event.target.value = "";
      return;
    }
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, document: file } : row))
    );
  };

  const handleEdit = (id) => {
    const row = rows.find((row) => row.id === id);

    if (row.isEditing) {
      handleSave(id);
    } else {
      setRows(
        rows.map((row) => (row.id === id ? { ...row, isEditing: true } : row))
      );
    }
  };

  const handleSave = async (id) => {
    const row = rows.find((row) => row.id === id);

    // Check for missing required fields
    if (!row.message || !row.fromDate || !row.toDate) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Prepare FormData
    const updatedData = new FormData();
    updatedData.append("validFrom", dayjs(row.fromDate).format("YYYY-MM-DD"));
    updatedData.append("validTo", dayjs(row.toDate).format("YYYY-MM-DD"));
    updatedData.append("message", row.message);
    updatedData.append(
      "documentType",
      row.DocumentType === "PDF" ? "pdf" : "image"
    );
    updatedData.append("toHo", row.ho ? 1 : 0);
    updatedData.append("toRm", row.rm ? 1 : 0);
    updatedData.append("toZm", row.zm ? 1 : 0);
    updatedData.append("toAm", row.am ? 1 : 0);
    updatedData.append("toDealer", row.dealer ? 1 : 0);

    // Append the file if it exists
    if (row.document instanceof File) {
      updatedData.append("document", row.document);
    }

    try {
      let response;

      if (row.isNewRow) {
        // For new row, send POST request
        response = await PostPopUpData({ payload: updatedData }); // Sending payload with FormData
        if (response?.success) {
          toast.success("Row added successfully!");
          setRows(
            (prevRows) =>
              prevRows.map((r) =>
                r.id === id ? { ...r, isEditing: false, isNewRow: false } : r
              ) // Only update the edited row
          );
        }
      } else {
        // For existing row, send PUT request
        response = await UpdatePopUpData({ id, payload: updatedData });
        if (response?.success) {
          toast.success("Row updated successfully!");
          setRows((prevRows) =>
            prevRows.map((r) => (r.id === id ? { ...r, isEditing: false } : r))
          );
        }
      }
      fetchPopUpData();
    } catch (error) {
      toast.error("Error saving the row.");
      console.error("Error:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedId(null);
  };

  const handleOpenDialog = (id) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await DeletePopUpData({ id });
      if (response?.success) {
        setRows(rows.filter((row) => row.id !== id));
        toast.success("Notification deleted successfully!");
      } else {
        toast.error("Error deleting notification.");
      }
      handleCloseDialog();
    } catch (error) {
      toast.error("Error deleting notification.");
      console.error("Error deleting notification:", error);
    }
  };

  const handleModalOpen = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalImage("");
  };

  const formatDate = (date) => (date ? dayjs(date).format("DD-MM-YYYY") : "");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="popup-container">
        <div>
          <div className="popup-header">
            <h2>Pop-up Notification</h2>
          </div>
          <div className="add-row-button-container">
            <Button
              style={{
                marginLeft: "1rem", // Fixed property name
                color: "blue",
                padding: "6px",
                borderRadius: "4px", // Fixed property name
              }}
              onClick={handleAddRow}
            >
              New Item
            </Button>
          </div>
        </div>

        <CommanTableContain className="popup-table-container">
          <ToastContainer position="top-right" autoClose={5000} />
          <Table stickyHeader>
            <TableHead className="popup-table-header">
              <TableRow>
                <TableCell>Document Type</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>From Date</TableCell>
                <TableCell>To Date</TableCell>
                <TableCell>HO</TableCell>
                <TableCell>RM</TableCell>
                <TableCell>ZM</TableCell>
                <TableCell>am</TableCell>
                <TableCell>Dealer</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Select
                      value={row.DocumentType || "image"}
                      onChange={(e) => {
                        const selectedType = e.target.value;
                        handleInputChange(row.id, "DocumentType", selectedType);
                        handleDropdownChange(selectedType);
                      }}
                      disabled={!row.isEditing}
                    >
                      <MenuItem value="image">Image</MenuItem>
                      <MenuItem value="PDF">PDF</MenuItem>
                    </Select>
                  </TableCell>

                  <TableCell>
                    <textarea
                      value={row.message}
                      onChange={(e) =>
                        handleInputChange(row.id, "message", e.target.value)
                      }
                      disabled={!row.isEditing}
                      rows={2}
                      cols={30}
                      className="textarea-input"
                    />
                  </TableCell>

                  <TableCell>
                    {row.isEditing ? (
                      <Button variant="contained" component="label">
                        <FileUploadIcon />
                        <input
                          type="file"
                          accept={
                            row.DocumentType === "PDF"
                              ? "application/pdf"
                              : "image/*"
                          }
                          hidden
                          onChange={(e) => handleFileChange(row.id, e)}
                        />
                      </Button>
                    ) : row.DocumentType === "image" ? (
                      <img
                        className="table-image"
                        src={row.document}
                        alt="Doc"
                        onClick={() => handleModalOpen(row.document)}
                      />
                    ) : (
                      <a
                        href={row.document}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="outlined" color="primary">
                          <SummarizeIcon />
                        </Button>
                      </a>
                    )}
                  </TableCell>

                  <TableCell>
                    {row.isEditing ? (
                      <DatePicker
                        value={row.fromDate}
                        onChange={(date) =>
                          handleInputChange(row.id, "fromDate", date)
                        }
                        minDate={dayjs()}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : (
                      formatDate(row.fromDate)
                    )}
                  </TableCell>

                  <TableCell>
                    {row.isEditing ? (
                      <DatePicker
                        value={row.toDate}
                        onChange={(date) =>
                          handleInputChange(row.id, "toDate", date)
                        }
                        minDate={row.fromDate || dayjs()}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : (
                      formatDate(row.toDate)
                    )}
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={row.ho}
                      onChange={(e) =>
                        handleInputChange(row.id, "ho", e.target.checked)
                      }
                      disabled={!row.isEditing}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={row.rm}
                      onChange={(e) =>
                        handleInputChange(row.id, "rm", e.target.checked)
                      }
                      disabled={!row.isEditing}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={row.zm}
                      onChange={(e) =>
                        handleInputChange(row.id, "zm", e.target.checked)
                      }
                      disabled={!row.isEditing}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={row.am}
                      onChange={(e) =>
                        handleInputChange(row.id, "am", e.target.checked)
                      }
                      disabled={!row.isEditing}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      checked={row.dealer}
                      onChange={(e) =>
                        handleInputChange(row.id, "dealer", e.target.checked)
                      }
                      disabled={!row.isEditing}
                    />
                  </TableCell>

                  <TableCell>
                    <div className="button-group">
                      <button
                        className="button-done"
                        onClick={() => handleSave(row.id)}
                        disabled={!row.isEditing || isDisable}
                      >
                        <DoneIcon />
                      </button>
                      <button
                        className="button-edit"
                        onClick={() => handleEdit(row.id)}
                        disabled={row.isNewRow}
                      >
                        <EditNoteIcon />
                      </button>
                      <button
                        className="button-delete"
                        onClick={() => handleOpenDialog(row.id)}
                      >
                        <DeleteIcon />
                      </button>
                      <ConfirmationDialog
                        open={dialogOpen}
                        handleCancel={handleCloseDialog}
                        handleConfirm={() => handleDelete(selectedId)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CommanTableContain>

        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box className="popup-modal-box">
            <div className="modal-header">
              <p>Image Preview</p>
              <button onClick={handleModalClose} className="close-button">
                <CloseIcon />
              </button>
            </div>
            {modalImage ? (
              <img
                src={modalImage}
                alt="Preview"
                className="popup-modal-image"
              />
            ) : (
              <Typography>No image available</Typography>
            )}
          </Box>
        </Modal>
      </div>
    </LocalizationProvider>
  );
};

export default PopupNotification;
