import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    financialYears: [],
    locationData: [],
    mvrPeriods: [],
    qvrPeriods: [],
    regions: [],
    zones: [],
    areas: [],
    dealerCodeName: [],
    periods: [],
    success: false,
};

const filteredDataSlice = createSlice({
    name: 'filteredDropdownData',
    initialState,
    reducers: {
        setFilteredDropdownData(state, action) {
            return { ...state, ...action.payload };
        },
        updateDropDown(state, action) {
            const { key, value } = action.payload;
            if (state.hasOwnProperty(key)) {
                state[key] = value;
            } else {
                console.warn(`Key "${key}" does not exist in the state.`);
            }
        },
    },
});

export const { setFilteredDropdownData, updateDropDown } = filteredDataSlice.actions;
export default filteredDataSlice.reducer;
