import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FetchPreviewData } from "../../services/MvrTableService";
import "../../css/MvrTable.css";
import html2pdf from "html2pdf.js";
import PrintIcon from "@mui/icons-material/Print";
import { ModalContain } from "../../style/HO/MVRManualCreation";
import { useSelector } from "react-redux";
import { ImagePreview } from "../../style/HO/QVRManualCreation";

const ParameterTable = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const location = useLocation();
  const Drawer = useSelector((state) => state.drawer);
  const dataM = location.state || { mvrId: null };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await FetchPreviewData(dataM.mvrId);
        setData(result.data);
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [dataM.mvrId]);

  const handleModalOpen = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalImage("");
  };

  const downloadPDF = () => {
    const content = document.querySelector(".parameter-table-container");
    if (!content) {
      console.error("Content container not found");
      return;
    }
    setIsGeneratingPDF(true);

    html2pdf()
      .from(content)
      .set({
        margin: 10,
        filename: "parameter-table.pdf",
        html2canvas: {
          scale: 2,
          logging: false,
          useCORS: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      })
      .save()
      .finally(() => {
        setIsGeneratingPDF(false);
      });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Box className="parameter-table-container">
      <Table className="parameter-table">
  <TableBody>
    <TableRow>
      <TableCell className="table-cell-border" padding="0">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="table-cell-border table-font" >Version Name</TableCell>
              <TableCell className="table-cell-border">{data.versionName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-cell-border table-font">Procedure Number</TableCell>
              <TableCell className="table-cell-border">{data.procedureNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-cell-border table-font">Total Score</TableCell>
              <TableCell className="table-cell-border">{data.mvrTotalScore}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  </TableBody>
</Table>


        {data.parameters.map((parameter, pIndex) =>
          parameter.checklists?.map((checklist, checklistIn) => (
            <React.Fragment key={`${pIndex}-${checklistIn}`}>
              <TableContainer component={Paper} className="table-container">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="table-num">{pIndex + 1}</TableCell>
                      <TableCell className="table-cell-border" padding="0">
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className="table-cell-border table-font">
                                Parameter
                              </TableCell>
                              <TableCell className="table-cell-border">
                                {pIndex + 1}
                              </TableCell>
                              <TableCell className="table-cell-border">
                                {parameter.parameter}
                              </TableCell>
                              <TableCell className="table-cell-border table-font">
                                Standard
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="table-cell-border table-font">
                                Check Point
                              </TableCell>
                              <TableCell className="table-cell-border">
                              {`${pIndex + 1}.${checklistIn + 1}`}
                              </TableCell>
                              <TableCell className="table-cell-border">
                                {checklist.checklist}
                              </TableCell>
                              <TableCell
                                rowSpan={3}
                                className="table-cell-border table-row-highlight"
                              >
                                <img
                                  src={
                                    checklist.imageSample ||
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                  }
                                  alt="Preview"
                                  className="table-image"
                                  onClick={() =>
                                    handleModalOpen(checklist.imageSample)
                                  }
                                  crossOrigin="anonymous"
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="table-cell-border table-font">
                                Ideal Requirement
                              </TableCell>
                              <TableCell className="table-cell-border">
                               
                              </TableCell>
                              <TableCell className="table-cell-border">
                                {checklist.idealRequirement}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="table-cell-border table-font">
                                Scoring Criteria
                              </TableCell>
                              <TableCell className="table-cell-border"></TableCell>
                              <TableCell className="table-cell-border">
                                {checklist.criterionBody}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))
        )}
      </Box>

      {modalOpen && (
        <ModalContain className={Drawer ? " " : "DrawerOpen"}>
          <ImagePreview>
            <div className="ModalHeader">
              <h4>Image Preview </h4>
              <button className="CloseButton" onClick={handleModalClose}>
                <CloseIcon />
              </button>
            </div>
            <div className="ModalBody">
              {modalImage ? (
                <img
                  src={modalImage}
                  alt="Preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
          </ImagePreview>
        </ModalContain>
      )}

      <div className="Mom_table_evaluation_btn">
        <button
          className="print"
          onClick={downloadPDF}
          disabled={isGeneratingPDF}
        >
          {isGeneratingPDF ? "Generating..." : <PrintIcon />}
        </button>
      </div>
    </>
  );
};

export default ParameterTable;
