import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { Colors } from "../theme";
import { margin } from "polished";

export const ImagePreview = styled(Box)(() => ({
  // maxHeight: '400px',
  maxWidth: "500px",
  width: "100%",
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column", // Ensures header, body, and footer are stacked vertically
  "& .ModalHeader": {
    position: "relative",
    padding: "15px 10px",
    backgroundColor: Colors.primary,
    color: Colors.white,
    "& h4": {
      paddingRight: "20px",
      fontWeight: 400,
    },
    "& .CloseButton": {
      position: "absolute",
      right: "5px",
      top: "5px",
      backgroundColor: "transparent",
      color: Colors.white,
    },
  },
  "& .ModalBody": {
    flex: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    "& img": {
      margin: "auto",
      maxWidth: "400px",
      maxHeight: "300px",
      objectFit: "contain",
    },

    "& .box_btn_edit": {
      display: "flex",
      justifyContent: "Space-Between",
      paddingRight: "10px",
      alignItems: "Center",
      marginLeft: "20px",
      marginTop: "20px",
      "& .edit_btn": {
        backgroundColor: "#ff4500",
        width: "65px",
        padding: "7px",
        color: "#fff",
        fontWeight: "500",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "#b53302",
        },
      },
    },
  },
}));
