import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import {
  ScheduleVisitContain,
  ScheduleTabContain,
} from "../../style/AM/ScheduleVisit";
import { ButtonContain, Card, CardContain } from "../../style/Home";
import Filter from "../../Components/filters";
import Grid from "@mui/material/Grid";
import ExcelIcon from "../../assets/images/icons/excel.png";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CommanTableContain,
  ContainerCustom,
  SmartSearchContain,
} from "../../style/table";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Loader } from "../../utils/loader";
import {
  GetMvrVisits,
  handleVisitPlan,
  handleRevisedPlanAPI,
} from "../../services/scheduleVisit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import { NotAvailable } from "../../utils/constants";
// import { formatDate } from "react-datepicker/dist/date_utils";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from "react-redux";
import { resetFilter } from "../../features/filterSlice";

const ScheduleVisit = () => {
  const [dateValue, setDateValue] = useState();
  const [loader, setLoader] = useState(false);
  const [mvrVisits, setMvrVisits] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalVisitData, setTotalVisitData] = useState(null);
  const dispatch = useDispatch();
  const filtersValue = useSelector((state) => state.filterData);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [filterValues, setFilterValues] = useState({});

  const [loadingIndex, setLoadingIndex] = useState(null);

  const [changeColor, setChangeColor] = useState(false);
  
  // local storage roleType
  const roleType = JSON.parse(localStorage.getItem("user_cred")).roleType;
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    dispatch(resetFilter());
    setIsDispatched(true);
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };

  // tabs start
  const [selectedTab, setSelectedTab] = useState("dvr_visit");

  const handleChange = (event) => {
    setSelectedTab(event.target.value);
  };

  const GetMvrVisit = async (searchValue) => {
    try {
      setLoader(true);
      let payload = filtersValue;
      payload = {
        ...payload,
        page,
        offset: rowsPerPage,
        search: searchValue || "",
      };
      const res = await GetMvrVisits({ payload: payload });
      if (res.success) {
        setMvrVisits(res?.data?.visits ?? []);
        setTotalVisitData(res?.data?.totalCount ?? 0);
      }
    } catch (error) {
      console.log("error: ", error);
      if (error.code === "ERR_NETWORK") {
        toast.error("Network error");
      }
    } finally {
      setLoader(false);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchValue) => {
        await GetMvrVisit(searchValue);
      }, 1000),
    []
  );

  useEffect(() => {
    debouncedSearch(searchValue);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, debouncedSearch]);

  useEffect(() => {
    if (isDispatched) {
      GetMvrVisit();
    }
  }, [page, rowsPerPage, resetTrigger, isDispatched]);

  const handleSearch = async () => {
    await GetMvrVisit();
  };

  const radioButtons = [
    { value: "dvr_visit", label: "MVR Visit" },
    // { value: "monthly_visit", label: "Monthly Visit" },
    // { value: "de_visit", label: "QVR Visit" },
  ];

  const mvrTableCols = [
    "S.no.",
    "Dealer Code",
    "Dealer Ship Name",
    "Location",
    "Area",
    "Visit Plan",
    "Revised Visit Plan (Multiple revision are acceptable)",
    "Reason For Date Revision (Revised Visit Plan)",
    "Actual Visit Date (MVR Submitted)",
    "Days of Variance",
    "Reason For Variance (Actual Visit)",
    "Action",
  ];

  if (roleType !== "am") {
    mvrTableCols.pop();
  }

  const handleSubmit = async (e, visit, index) => {
    e.preventDefault();
    setLoadingIndex(index);
    try {
      if (visit.canPlanVisit) {
        if (!mvrVisits[index].visitPlan) {
          toast.error("Please fill Visit Plane Date.");
          setLoadingIndex(null);
          return;
        }
        // Construct the payload
        const payload = {
          dealerCode: visit?.dealerCode,
          visitPlannedDate: mvrVisits[index].visitPlan,
        };

        // Make the API call
        const res = await handleVisitPlan({ payload: payload });

        if (res.success) {
          toast.success("Successfully Submitted");
          setMvrVisits((prevData) => {
            const updatedDate = [...prevData];
            updatedDate[index] = {
              ...updatedDate[index],
              canPlanReVisit: true,
              canPlanVisit: false,
              enableEditing: false,
            };
            return updatedDate;
          });
        } else {
          // console.error('API Response indicates failure:', res);
          toast.error("Data not Submitted");
        }
      } else {
        const revisedVisitReason = mvrVisits[index].revisedVisitReason;
        let formattedDate = mvrVisits[index].revisedVisitPlan;
        if (
          formattedDate &&
          moment(formattedDate, "DD-MM-YYYY", true).isValid()
        ) {
          formattedDate = moment(formattedDate, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          );
        }
        if (!mvrVisits[index].revisedPlanDate && !revisedVisitReason) {
          toast.error("Please fill both the revised plan date and reason!");
          return;
        }

        if (revisedVisitReason.length < 10) {
          toast.error("Please enter a minimum of 10 character.");
          return;
        }

        const payload = {
          dealerCode: visit?.dealerCode,
          reVisitPlannedDate: formattedDate,
          reVisitReason: revisedVisitReason,
        };
        try {
          const response = await handleRevisedPlanAPI({ payload: payload });
          if (response.success) {
            toast.success("Revised Plan Submitted");
            setMvrVisits((prevData) => {
              const updatedDate = [...prevData];
              updatedDate[index] = {
                ...updatedDate[index],
                enableEditing: false,
              };
              return updatedDate;
            });
          } else {
            toast.error("Revised Plan Submission Failed");
          }
        } catch (error) {
          // console.error("Error submitting revised plan:", error);
          toast.error(
            error?.response?.data?.error ?? "Failed to submit revised plan"
          );
        }
      }
    } catch (error) {
      // console.error('Error in handleSubmit:', error);
      toast.error("Data not Submitted");
    } finally {
      setLoadingIndex(null);
    }
  };

  useEffect(() => {
    setChangeColor(true);
  }, [mvrVisits]);

  const handleDateChanges = (value, index) => {
    const formattedDate = value ? value.format("YYYY-MM-DD") : null;
    setMvrVisits((prevData) => {
      const updatedDate = [...prevData];
      updatedDate[index] = {
        ...updatedDate[index],
        visitPlan: formattedDate,
        enableEditing: true,
      };
      return updatedDate;
    });
  };

  const handleRevisePlanDate = (value, index) => {
    const formattedDate = value ? value.format("YYYY-MM-DD") : null;
    setMvrVisits((prevData) => {
      const updatedDate = [...prevData];
      updatedDate[index] = {
        ...updatedDate[index],
        revisedVisitPlan: formattedDate,
        enableEditing: true,
      };
      return updatedDate;
    });
  };

  const handleRevisedReason = (event, index) => {
    setMvrVisits((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        revisedVisitReason: event.target.value,
        enableEditing: true,
      };
      return updatedData;
    });
  };

  const handleReset = () => {
    dispatch(resetFilter());
    setResetTrigger(Date.now());
    setTimeout(() => setResetTrigger(false), 0);
  };

  return (
    <>
      {loader && <Loader fullScreen={true} />}
      <ScheduleVisitContain>
        {/* filter start  */}
        <CardContain>
          <Card mt={1} className="Filter_bg">
            <Grid container spacing={1}>
              <Filter
                onFilterChange={handleFilterChange}
                reset={resetTrigger}
              ></Filter>

              <Grid item xs={12}>
                <ButtonContain>
                  <button onClick={handleSearch} className="SearchButton">
                    Search
                  </button>
                  <button className="ResetButton" onClick={handleReset}>
                    Reset
                  </button>
                  {/* <button className="ExportButton">
                    <img src={ExcelIcon} alt="Excel icon" />
                  </button> */}
                </ButtonContain>
              </Grid>
            </Grid>
          </Card>
        </CardContain>
        {/* filter end */}
        <CardContain>
          <Card mt={2}>
            {/* <ScheduleTabContain>
              <RadioGroup
                row
                value={selectedTab}
                onChange={handleChange}
                sx={{ justifyContent: "center" }}
              >
                {radioButtons.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}

              </RadioGroup>
            </ScheduleTabContain> */}

            {selectedTab === "dvr_visit" && (
              <>
                {/*  table start */}

                {/* <ContainerCustom>
                  <SmartSearchContain>
                    <TextField
                      label="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginLeft: "auto" }}
                    />
                    <SearchIcon />
                  </SmartSearchContain>
                </ContainerCustom> */}

                {/* Table */}
                <CommanTableContain>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {mvrTableCols.map((text, index) => (
                          <TableCell key={index}>{text}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mvrVisits.map((visit, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {parseInt(index + 1) + page * rowsPerPage}
                          </TableCell>
                          <TableCell>{visit?.dealerCode}</TableCell>
                          <TableCell>{visit?.dealerName}</TableCell>
                          <TableCell>
                            {visit?.city ? visit.city : NotAvailable}
                          </TableCell>
                          <TableCell>{visit?.area}</TableCell>
                          <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DatePicker
                                  label={visit?.visitPlan || "DD-MM-YYYY"}
                                  disabled={!visit?.canPlanVisit}
                                  maxData={dayjs().endOf("month")}
                                  value={dateValue}
                                  disablePast
                                  className="dateTimePicker"
                                  onChange={(newValue) => {
                                    handleDateChanges(newValue, index, visit);
                                  }}
                                  format="DD-MM-YYYY"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={
                                        dateValue
                                          ? dateValue.format("DD-MM-YYYY")
                                          : ""
                                      }
                                      placeholder="dd-MM-yyyy"
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DatePicker
                                  label={
                                    visit?.revisedVisitPlan || "DD-MM-YYYY"
                                  }
                                  maxDate={dayjs().endOf("month")}
                                  disablePast
                                  disabled={!visit?.canPlanReVisit}
                                  value={dateValue}
                                  className="dateTimePicker"
                                  onChange={(newValue) =>
                                    handleRevisePlanDate(newValue, index)
                                  }
                                  format="DD-MM-YYYY"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={
                                        dateValue
                                          ? dateValue.format("DD-MM-YYYY")
                                          : ""
                                      }
                                      placeholder="dd-MM-yyyy"
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell>
                            <textarea
                              disabled={!visit?.canPlanReVisit}
                              placeholder="Reason for revision"
                              value={
                                visit.reasonForVariance
                                  ? visit.reasonForVariance
                                  : ""
                              }
                              onChange={(event) =>
                                handleRevisedReason(event, index)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {visit?.actualVisitDate
                              ? visit.actualVisitDate
                              : NotAvailable}
                          </TableCell>
                          <TableCell>
                            {visit?.daysOfVariance
                              ? visit.daysOfVariance
                              : NotAvailable}
                          </TableCell>
                          <TableCell>
                            <textarea
                              value={visit?.reasonForVariance || ""}
                              disabled={true}
                              placeholder="Reason for variance"
                            />
                          </TableCell>
                          {roleType === "am" ? (
                            <TableCell>
                              {/* "action save" */}
                              <button
                                onClick={(e) => handleSubmit(e, visit, index)}
                                className="action save"
                                disabled={
                                  (visit.canPlanReVisit === false &&
                                    visit.canPlanVisit === false) ||
                                  !visit.enableEditing
                                }
                              >
                                {loadingIndex === index && (
                                  <ClipLoader color="#ffffff" size={15} />
                                )}
                                {loadingIndex === index ? "" : "Submit"}
                              </button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CommanTableContain>

                {/* table end */}
              </>
            )}
            {selectedTab === "monthly_visit" && (
              <>
                {/*  table start */}

                <ContainerCustom>
                  <SmartSearchContain>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginLeft: "auto" }}
                    />
                    <SearchIcon />
                  </SmartSearchContain>
                </ContainerCustom>

                {/* Table */}
                <CommanTableContain>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.no.</TableCell>
                        <TableCell>Dealer Code</TableCell>
                        <TableCell>Dealer Ship Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Visit Plan</TableCell>
                        <TableCell>
                          Revised Visit Plan{" "}
                          <span>(Multiple revision are acceptable)</span>
                        </TableCell>
                        <TableCell>
                          Reason For Date Revision{" "}
                          <span>(Revised Visit Plan)</span>
                        </TableCell>
                        <TableCell>
                          Actual Visit Date <span>(DVR Submitted)</span>
                        </TableCell>
                        <TableCell>Days of Variance</TableCell>
                        <TableCell>
                          Reason For Variance <span>(Actual Visit)</span>
                        </TableCell>
                        {roleType === "am" ? <TableCell>Action</TableCell> : ""}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CommanTableContain>

                {/* table end */}
              </>
            )}
            {selectedTab === "de_visit" && (
              <>
                {/*  table start */}

                <ContainerCustom>
                  <SmartSearchContain>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginLeft: "auto" }}
                    />
                    <SearchIcon />
                  </SmartSearchContain>
                </ContainerCustom>

                {/* Table */}
                <CommanTableContain>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.no.</TableCell>
                        <TableCell>Dealer Code</TableCell>
                        <TableCell>Dealer Ship Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Visit Plan</TableCell>
                        <TableCell>
                          Actual Visit Date <span>(DE Submitted)</span>
                        </TableCell>
                        <TableCell>
                          Reason For Variance <span>(Actual Visit)</span>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CommanTableContain>
              </>
            )}
          </Card>
        </CardContain>
        <TablePagination
          component="div"
          count={totalVisitData}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ScheduleVisitContain>
      <ToastContainer />
    </>
  );
};

export default ScheduleVisit;
