import React, { useRef, useState, useEffect } from "react";
import { ButtonContain, Card, CardContain } from "../../style/Home";
import Filter from "../../Components/filters";
import Grid from "@mui/material/Grid";
import Timeline from "../../Components/timeline";
import { IconButton, Modal, Box } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
// import Table from '../../Components/table/Table';
import ExcelIcon from "../../assets/images/icons/excel.png";
import { GuidelineContain } from "../../style/AM/MoMStatusUpdate";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ModalContain, } from "../../style/HO/MVRManualCreation";
import { ImagePreview } from "../../style/HO/QVRManualCreation";
import {
  CommanTableContain,
  ContainerCustom,
  PaginationContain,
  SmartSearchContain,
} from "../../style/table";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { GetMomData, MomRevisedUpdate, MomStatusDataUpdate, UploadMomEvidence } from "../../services/momService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { reverse } from "lodash";
import { DataArray } from "@mui/icons-material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Loader } from "../../utils/loader";

const MoMStatusUpdate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Drawer = useSelector((state) => state.drawer);
  const [search, setSearch] = useState("");
  const [mom, setMom] = useState([]);
  const momInputs = useRef([]);
  const [page, setPage] = useState(0);
  const [newEvidence, setNewEvidence] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalMomData, setTotalMomData] = useState(0);

  const [inputRef, inputRefIndex] = useState(undefined);
  const [evidence, setEvidence] = useState("");
  const [evidenceDetails, setEvidenceDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const selectedRow = location.state?.row;
  
  const handlePreviewClick = (data, index) => {
    setEvidence(data.evidenceUpload)
    setEvidenceDetails(data.momEvidenceDetails) 
    if (data.canComplete) {
      inputRefIndex(index)
    }else{
      inputRefIndex(undefined)
    }
  };

  const handleCloseModal = () => {
    setEvidence("");
  };


  const getMomData = async () => {
    try {
      const payload = {
        "page": page,
        "offset": rowsPerPage,
      }
      let res = await GetMomData(selectedRow?.dealerCode, selectedRow?.financialYear, selectedRow?.period, {payload});
      if (res.success){
        setMom(res.data.moms);
        setTotalMomData(res.data.totalCount);
      }
    } catch (error) {
      console.log("Error on loading mom data: ", error);
    }
  };

  useEffect(() => {
    getMomData();
  }, [page, rowsPerPage]);

  // handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  }

  // const handleFilterChange = (filterKey, value) => {
  //   setFilterValues((prev) => ({
  //     ...prev,
  //     [filterKey]: value,
  //   }));
  // };

  // table data end

  const tableRef = useRef();

  const handleScrollRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollBy({
        // left: 200, // Adjust the scroll amount
        left: tableRef.current.scrollWidth - tableRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  // handle reason data
  const handleRevisedReasonChange = (index, value) => {
    setMom((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        revisedReason: value
      };
      return newData;
    });
  };

  // handle revised date change
  const handleRevisedTargetDateChange = async (index, date) => {
    const revisedDate = date ? date.format("YYYY-MM-DD") : null;
    console.log(revisedDate, " selected revised date");
  
    setMom((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        revisedTargetDate: date ? date.format("DD-MM-YYYY") : null, 
      };
      return newData;
    });
  
    const payload = {
      momId: mom[index].momId,
      concernSource: mom[index].concernSource,
      revisedReason: mom[index].revisedReason,
      revisedDate: revisedDate || mom[index].revisedTargetDate
      ? dayjs(mom[index].revisedTargetDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
    };
  
    try {
      await MomRevisedUpdate({ payload });
    } catch (error) {
      console.log("Error on updating revised date: ", error);
      toast.error(error?.response?.data?.error ?? error?.message);
    }
  };
  

  // handle revised upload
  const handleReasonBlur = async (index, momId, concernSource, event) => {
    const newReason = event.target.value;
    setMom(prevData => {
      const newData = [...prevData];
      const index = newData.findIndex(data => data.momId === momId && data.concernSource === concernSource);
      newData[index] = {
        ...newData[index],
        revisedReason: newReason
      };
      return newData;
    });
  
    const payload = {
      momId: mom[index].momId,
      concernSource: mom[index].concernSource,
      revisedReason: mom[index].revisedReason,
      revisedDate: mom[index].revisedTargetDate
      ? dayjs(mom[index].revisedTargetDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      : null
    };
    try {
      await MomRevisedUpdate({ payload });
    } catch (error) {
      console.log('Error on updating data: ', error);
      toast.error(error?.response?.data?.error);
    }
  };
  
  // handle activity complete
  const handleActivityCompleteReason = (index, value) => {
    setMom(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        activityCompleteRemarks: value
      };
      return newData;
    });
  }

  const handleReopenMom = (index, value ) => {
    setMom(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        reopenRemarks: value
      };
      return newData;
    });
  }

  // handle mom status change
  const handleMomStatusChange = async (e, index, value, element) => {
    try{
      e.preventDefault()
    let reason = ""
    let message = ""

      if (value === 'close'){
        reason= element.activityClosureRemarks || "";
        message="Please enter closure remark first"
      }
      else if (value === 'complete'){
        reason=element.activityCompleteRemarks || ""
        if (!element.evidenceUpload){
          reason=""
        }
        message="Please first upload evidence and enter completion remark."
      }
      else if (value === 'reopen'){
        reason=element.reopenRemarks || ""
        message="Please enter reopen remark first"
      } else{
        toast.error("Invalid Input")
        return
      }

        if (!reason.trim()){
          toast.error(message)
          return
        }
  
        const payload = {
          momId: mom[index].momId,
          concernSource: mom[index].concernSource,
          reason: reason,
          momStatus: value,
        }

        let res = await MomStatusDataUpdate({payload});
        if (res.success){
          getMomData();
        }
    }catch(error){
      console.log('Error on updating data: ', error);
    }
  };

  
  const handleCloseMom = (index, value ) => {
    setMom(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        activityClosureRemarks: value
      };
      return newData;
    });
  }
  // handle file change
  const handleFileUpload = async (event, data, index) => {
    const file = event.target.files[0];
    try{
      setLoader(true);
      if (file) {
        const res = await UploadMomEvidence(file, data.momId, data.concernSource);
        if (res.success){
          setEvidence(null);
          getMomData();
        }
      }
    }catch(error){
      console.log("Error on uploading file: ", error);
    }finally{
      setLoader(false);
    }
  };
  
  return (
    <>
      {/* <CardContain className="Sticky">
        <Card mt={1} className="Filter_bg StickyChild"> */}
          {/* <Grid container pt={2}>
            <Grid item xs={10}>
              <Timeline />
            </Grid>
            <Grid item xs={2}>
              <ButtonContain
                sx={{ height: "100%", alignItems: "end", gap: "5px" }}
              >
                <button className="ExportButton" style={{ marginTop: "0" }}>
                  <img src={ExcelIcon} alt="Excel icon" />
                </button>
              </ButtonContain>
            </Grid>
          </Grid> */}
        {/* </Card>
      </CardContain> */}
      <CardContain>
        <Card mt={2}>
          <GuidelineContain>
            <div className="ScrollTable">
              <p>Please scroll right to update MoM Implementation Status</p>
              <button className="ScrollButton" onClick={handleScrollRight}>
                <ArrowForwardIcon />{" "}
              </button>
            </div>
            <div className="Guidelines">
              <p>
                <span>Guidelines : </span>
              Remarks will be saved at the status change.
              </p>
            </div>
          </GuidelineContain>
          {/* <Table headers={headers} data={data} defaultRowsPerPage={10} ref={tableRef} /> */}

          {/* Search Field */}
          {/* <ContainerCustom>
            <SmartSearchContain>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                marginleft="auto"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon />
            </SmartSearchContain>
          </ContainerCustom> */}
          {/* Table */}
          <CommanTableContain ref={tableRef}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    sx={{
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                      textAlign: "center",
                      position: "sticky",
                      top: 0,
                      zIndex: 5,
                    }}
                  >
                    MoM Points
                  </TableCell>
                  <TableCell
                    colSpan={10}
                    align="center"
                    sx={{
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                      textAlign: "center",
                      position: "sticky",
                      top: 0,
                      zIndex: 5,
                    }}
                  >
                    MoM Implementation Status
                  </TableCell>
                </TableRow>
                <TableRow className="SecondHeader">
                  <TableCell>Discussion/Concern Points</TableCell>
                  <TableCell>Discussion/Concern Points Source</TableCell>
                  <TableCell>Open Since Month (Days)</TableCell>
                  <TableCell>Countermeasure Plan</TableCell>
                  <TableCell>Target Date</TableCell>
                  {/* <TableCell>Acceptance Status</TableCell> */}
                  <TableCell>
                    Acceptance/Reconsideration Remarks By Dealer
                  </TableCell>
                  <TableCell>AM Remarks</TableCell>
                  <TableCell>Escalation Level</TableCell>
                  <TableCell>Revised Target Date (Entry By AM)</TableCell>
                  <TableCell>
                    Reason For Revised Target Date (Entry By AM)
                  </TableCell>
                  <TableCell>Activity Completed Date ( By AMD)</TableCell>
                  <TableCell>
                    Activity Completed Remarks (Entry By AMD)
                  </TableCell>
                  <TableCell>AMD Evidence (Photo Upload)</TableCell>
                  <TableCell>Mom Status (Entry By AM)</TableCell>
                  <TableCell>Reopen Date</TableCell>
                  <TableCell>Reopen Remarks</TableCell>
                  <TableCell>
                    Activity Closure Date (Display After closed By AM)
                  </TableCell>
                  <TableCell>Closure Remarks</TableCell>
                  {/* <TableCell>Escalated To RM</TableCell> */}
                </TableRow>
              </TableHead>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TableBody>
                  {mom.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                      <textarea value={data.concernPoint ? data.concernPoint : "-"} disabled>  </textarea> 
                        
                      </TableCell>
                      <TableCell>
                      <textarea value={data.concernSource ? data.concernSource : "-"} disabled></textarea>
                      </TableCell>
                      <TableCell>
                      <textarea value={data.openSince ? data.openSince : "-"} disabled></textarea>
                      </TableCell>
                      <TableCell>
                      <textarea value={data.counterMeasurePlan
                          ? data.counterMeasurePlan
                          : "-"} disabled></textarea>
                      </TableCell>
                      <TableCell>
                      <textarea value={data.targetDate ? data.targetDate : "-"} disabled></textarea>
                      </TableCell>
                      <TableCell>
                      <textarea value={data.acceptanceReconsiderationRemarks
                          ? data.acceptanceReconsiderationRemarks
                          : "-"} disabled></textarea>
                      </TableCell>
                      <TableCell>
                      <textarea value= {data.amRemarks ? data.amRemarks : "-"} disabled></textarea>
                       
                      </TableCell>
                      <TableCell>
                        <textarea value={data.escalationLevel ? data.escalationLevel : "-"} disabled></textarea>
                      </TableCell>
                      <TableCell>
                        <DatePicker
                          disabled={!data.editable || !data.canClose}
                          // maxData={dayjs().endOf('month')}
                          value={data?.revisedTargetDate ? dayjs(data.revisedTargetDate, "D-MM-YYYY") : null}
                          disablePast
                          className="dateTimePicker"
                          format="DD-MM-YYYY"
                          onChange={(event) => handleRevisedTargetDateChange(index, event)}
                        />
                      </TableCell>
                      <TableCell>
                        <textarea
                          disabled={!data.editable || !data.canClose}
                          value={data?.revisedReason ? data?.revisedReason : ""}
                          onChange={(e) => handleRevisedReasonChange(index, e.target.value)}
                          onBlur={(e) => handleReasonBlur(index, data.momId, data.concernSource, e)}
                          className="input_width"
                        ></textarea>

                      </TableCell>
                      <TableCell>
                        <DatePicker
                          disabled={true}
                          // maxData={dayjs().endOf('month')}
                          value={data?.activityCompleteDate ? dayjs(data.activityCompleteDate, "D-MM-YYYY") : null}
                          disablePast
                          className="dateTimePicker"
                          format="DD-MM-YYYY"
                        />
                      </TableCell>
                      <TableCell>
                        <textarea
                          disabled={!data.editable || !data.canComplete? true : false}
                          value={
                            data?.activityCompleteRemarks
                              ? data?.activityCompleteRemarks
                              : ""
                          }
                          onChange={(e) =>
                            handleActivityCompleteReason(index, e.target.value)
                          }
                          // onBlur={(e) => handlerMomStatusUpdate(index, data.momId, data.concernSource, e)}
                          className="input_width"
                        ></textarea>

                      </TableCell>
                      <TableCell>
                        {(data.editable && data.canComplete) && (
                          <input
                          ref={(e)=>{
                            momInputs.current[index] = e
                          }}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e, data, index)}
                        />
                        )}

                        {data?.evidenceUpload ? (
                          <div className="img_p_t" onClick={()=>handlePreviewClick(data, index)}>
                            <img
                              src={data?.evidenceUpload || ""}
                              alt="Preview"
                              className="img_table_preview"
                            />
                          </div>
                        ):(
                        <IconButton 
                          component="span" 
                          className="upload_icon_m"
                          onClick={() => momInputs.current[index].click()}
                          >
                            <UploadFileIcon />
                          </IconButton>
                        )}

                      </TableCell>
                      <TableCell>
                        <select 
                        value={data?.momStatus ? data?.momStatus : ""}
                        className="input_width"
                        onChange={(e) => handleMomStatusChange(e, index, e.target.value, data)}
                        disabled={ !data.editable || !(data.canClose || data.canComplete || data.canReopen)} 
                          >
                          <option disabled>{data?.momStatus}</option>
                          {data?.canClose && <option value="close">close</option>}
                          {data?.canComplete && <option value="complete">complete</option>}
                          {data?.canReopen && <option value="reopen">reopen</option>}
                        </select>
                      </TableCell>
                      <TableCell>
                        <DatePicker
                          // label="Activity Closure Date"
                          disabled={true}
                          className="revise_target"
                          value={data?.reopenDate ? dayjs(data.reopenDate, "D-MM-YYYY") : null}
                        />
                      </TableCell>
                      <TableCell>
                        <textarea
                          disabled={!data.editable || !data.canReopen}
                          value={data?.reopenRemarks ? data?.reopenRemarks : ""}
                          onChange={(e) => handleReopenMom(index, e.target.value)}
                          // onBlur={(e) => handleReopenMomUpdates(index, data.momId, data.concernSource, e)}
                          className="input_width"
                        ></textarea>
                      </TableCell>
                      <TableCell>
                        <DatePicker
                          disabled={true}
                          className="revise_target"
                          value={data?.activityClosureDate ? dayjs(data.activityClosureDate, "D-MM-YYYY") : null}
                        />
                      </TableCell>
                      <TableCell>
                        <textarea
                          disabled={!data.editable || !data.canClose}
                          value={
                            data?.activityClosureRemarks
                              ? data?.activityClosureRemarks
                              : ""
                          }
                          onChange={(e) => handleCloseMom(index, e.target.value)}
                          // onBlur={(e) => handleCloseMomUpdate(index, data.momId, data.concernSource, e)}
                          className="input_width"
                        ></textarea>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </LocalizationProvider>
            </Table>



            {evidence && (
                <ModalContain className={Drawer ? "" : "DrawerOpen"}>
                  <ImagePreview>
                    <div className="ModalHeader">
                    <h4>Evidence Upload {`(Uploaded On: ${evidenceDetails?.time})`}</h4>
                      <button
                        className="CloseButton"
                        onClick={handleCloseModal}

                      >
                        <CloseIcon />
                      </button>
                    </div>
                    <div className="ModalBody">
                      <img
                        src={evidence}
                        alt="Full Preview"
                        style={{ width: "100%", height: "auto", objectFit: "contain" }}
                      />
                      {!isNaN(inputRef) && (
                        <div className="box_btn_edit">
                          <button 
                            onClick = {()=>momInputs.current[inputRef].click()}
                            className="edit_btn"
                          > Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </ImagePreview>
                </ModalContain>
              )}




          </CommanTableContain>
          {/* Pagination */}
          <PaginationContain>
            <TablePagination
              component="div"
              count={totalMomData || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </PaginationContain>
        </Card>
      </CardContain>
      <ToastContainer />
      {loader && <Loader fullScreen={true} />}
    </>
  );
};

export default MoMStatusUpdate;
