import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandIcon from "@mui/icons-material/KeyboardArrowDown";
import CollapseIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { DatePickerContain, FormAccordianContain, FormModal, InvisibleLabel, UploadContain } from "../../style/formAccordian";
import { SmartSearchContain } from "../../style/table";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import evaluateImage from '../../assets/images/icons/employes.png';
import { ModalContain } from "../../style/HO/MVRManualCreation";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { ChangeAcceptReconsider, ChangeReconsiderationStatus, SaveCheckList, SaveCounterMeasure, SaveMom, SaveRemark, SaveTargetDate, UpdateAiRemarks, UpdateReconsiderationRemarks, UploadFiles, getCheckListHistory } from "../../services/mvrFormFill";
import { formatDatePickerDate } from "../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../utils/loader";
import HistoryIcon from '@mui/icons-material/History';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ScoreHistoryModal from '../../Components/Modal/ScoreHistoryModal';

const DynamicAccordion = ({ mvrFormData, checkListData, setCheckListData, setUpdateScore, currPIndex, parameterIndex = null, handleParameterScored }) => {
  console.log(checkListData, " checklist data");
  const fileInputRefs = useRef([]);
  const Drawer = useSelector((state) => state.drawer);
  const [expanded, setExpanded] = useState([]);
  const [dateValue, setDateValue] = useState();
  const [loader, setLoader] = useState(false)
  const [modalOpen, setOpenModal] = useState(false);

  const [editableIndex, setEditableIndex] = useState(null);
  const [editableSubIndex, setEditableSubIndex] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTime, setSelectedImageTime] = useState(null);
  const [selectedCheckListTitle, setSelectedCheckListTitle] = useState(null);
  const [isScoreHistoryModalOpen, setIsScoreHistoryModalOpen] = useState(false);

  const [checkListHistory, setCheckListHistory] = useState([]);
  const [checklistName, setCheckListName] = useState("");
  const allIds = checkListData.map((data) => data.id);

  const handleAccordionChange = (id) => {
    setExpanded((prev) =>
      prev.includes(id) ? prev.filter((data) => data !== id) : [...prev, id]
    );
  };

  const toggleAll = (action) => {
    setExpanded((prevExpanded) => {
      if (action === "expand") {
        return Array.from(new Set([...prevExpanded, ...allIds]));
      } else if (action === "collapse") {
        return prevExpanded.filter((id) => !allIds.includes(id));
      }
      return prevExpanded;
    });
  };

  const handleClick = (index, subIndex) => {
    if (fileInputRefs.current[index][subIndex]) {
      fileInputRefs.current[index][subIndex].click();
    }
  };

  const handleOpenModal = (imageSrc, title, uploadTime = null, index = null, imageIndex = null, editable = false) => {
    setSelectedImage(imageSrc);
    if (uploadTime) {
      setSelectedImageTime(uploadTime)
    }

    setEditableIndex(index)
    setEditableSubIndex(imageIndex)

    if (!editable) {
      setEditableIndex(null)
      setEditableSubIndex(null)
    }

    setSelectedCheckListTitle(title)
    setOpenModal(true);
  };


  const handleEditEvidence = () => {
    if (editableIndex !== null && editableSubIndex !== null) {
      fileInputRefs.current[editableIndex][editableSubIndex].click();
    }
  }

  const handleCloseModal = () => {
    setSelectedImage(null);
    setOpenModal(false);
    setSelectedImageTime(null)
    setSelectedCheckListTitle(null)

  };


  // PARAMETER SCORING
  const updateParameterScoring = () => {

    const [scoredQuestions, obtainedScore] = checkListData.reduce((acc, curr) => {
      if (curr.scoreReceived !== null || curr.nonApplicable) {
        acc[0]++
      }

      if (curr.scoreReceived && curr.scoreReceived > 0) {
        acc[1] += curr.scoreReceived
      }

      return acc
    }, [0, 0])

    handleParameterScored({
      scoredParameterIndex: parameterIndex,
      parameterScoredQuestions: scoredQuestions,
      parameterObtainedScore: obtainedScore
    })

  }
  // PARAMETER SCORING END


  const handleScoreChanges = async (e, checkList, index, controlData) => {
    const value = e.target.value;
    if (value) {
      const updatedCheckList = [...checkListData];
      if (updatedCheckList[index] && updatedCheckList[index].id === checkList.id) {

        const selectedControlData = controlData.find(item => item.value === Number(value));
        const scorePlaceholder = selectedControlData ? selectedControlData.display : 'N/A';
        updatedCheckList[index].scoreReceived = isNaN(parseInt(value)) ? 0 : parseInt(value);
        updatedCheckList[index].scorePlaceholder = scorePlaceholder;

        await saveCheckListData(updatedCheckList[index], index)
        updateParameterScoring()

      }
    }
  };

  const handleFileChange = async (e, checkList, checkListIndex, imageIndex) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setLoader(true);
        const res = await UploadFiles(file, checkList, mvrFormData, imageIndex)
        if (res.success && res.evidence) {
          const evidence = res.evidence
          const updatedCheckList = [...checkListData];
          if (updatedCheckList[checkListIndex] && updatedCheckList[checkListIndex].id === checkList.id) {
            updatedCheckList[checkListIndex].evidences[imageIndex] = evidence;
            setCheckListData(updatedCheckList);
            // toast.success('Evidence has been uploaded.')
          }
        }
      } catch (error) {
        console.log('error: ', error);
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setOpenModal(false)
        setLoader(false);
      }
    }
  };


  const handelMomChanges = async (e, parameter, index) => {
    const value = e.target.checked
    const updatedCheckList = [...checkListData];
    if (updatedCheckList[index] && updatedCheckList[index].id === parameter.id) {
      updatedCheckList[index].momPoint = value;
      if (!value) {
        updatedCheckList[index].targetDate = null;
      }
      setCheckListData(updatedCheckList);
      try {
        const res = await SaveMom(updatedCheckList[index], mvrFormData);
        if (res.success) {
          // toast.success('Mom has been saved.')
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      }
    }

  };

  const saveCheckListData = async (checkList, index) => {
    try {
      setLoader(true)
      const res = await SaveCheckList(checkList, mvrFormData);
      if (res.success) {
        const updatedCheckListData = checkListData.map((item, idx) => {

          if (res.naAction === "added" || res.naAction === "removed") {

            if (res.affectedChecklists.includes(item.checklistId)) {
              item.nonApplicable = res.naAction === "added"

              // PARAMETER SCORING
              if (res.naAction === "removed") {
                item.scoreReceived = null
              }

            }
          }

          return idx === index ? { ...item, ...res } : item
        });
        setCheckListData(updatedCheckListData);
        setUpdateScore(Date.now())

        // toast.success('Score has been saved.')
      }
    } catch (error) {
      console.log('error: ', error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    } finally {
      setLoader(false)
    }
  };

  const handleRemarkChanges = async (e, parameter, index) => {
    const value = e.target.value;
    const updatedCheckList = [...checkListData];
    if (updatedCheckList[index] && updatedCheckList[index].id === parameter.id) {
      updatedCheckList[index].remark = value;
      setCheckListData(updatedCheckList);
    }
  };

  const onBlurRemark = async (index) => {
    if (checkListData?.length) {
      const currCheckList = checkListData[index]
      try {
        setLoader(true)
        const res = await SaveRemark(currCheckList, mvrFormData);
        if (res.success) {
          // toast.success('Remarks has been saved.')
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setLoader(false)
      }
    }

  }

  const handleTargetDate = async (newDate, parameter, index) => {
    let formattedDate = formatDatePickerDate(newDate)
    formattedDate = formattedDate?.split('-').reverse().join('-')
    const updatedCheckList = [...checkListData];
    if (updatedCheckList[index] && updatedCheckList[index].id === parameter.id) {
      updatedCheckList[index].targetDate = formattedDate;
      try {
        setLoader(true)
        const res = await SaveTargetDate(parameter, mvrFormData)
        if (res.success) {
          // toast.success('Target date has been saved.')
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");

      } finally {
        setLoader(false)
      }
    }
    setCheckListData(updatedCheckList);
  }

  const handleCounterMeasure = (e, parameter, index) => {
    const value = e.target.value;
    const updatedCheckList = [...checkListData];

    if (updatedCheckList[index] && updatedCheckList[index].id === parameter.id) {
      updatedCheckList[index].counterMeasure = value;
      setCheckListData(updatedCheckList);
    }
  }

  const onBlurCounterMeasure = async (index) => {
    if (checkListData?.length) {
      const currCheckList = checkListData[index]
      try {
        setLoader(true)
        const res = await SaveCounterMeasure(currCheckList, mvrFormData)
        if (res.success) {
          // toast.success('Counter Measure has been saved.')
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setLoader(false)
      }
    }

  }

  const handleReconsiderRemark = async (e, checkList, index) => {
    const value = e.target.value;
    const updatedCheckList = [...checkListData];

    if (updatedCheckList[index] && updatedCheckList[index].id === checkList.id) {
      updatedCheckList[index].reconsideredRemark = value;
      if (!value) {
        updatedCheckList[index].reconsidered = false;
      }
      setCheckListData(updatedCheckList);

    }
  }

  const saveRemark = async (index) => {
    if (checkListData?.length) {
      const currCheckList = checkListData[index]
      try {
        setLoader(true)
        const res = await UpdateReconsiderationRemarks(currCheckList, mvrFormData);
        // if (res.success) {
        // }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setLoader(false)
      }
    }

  }

  const handleReconsiderCheckbox = async (e, checkList, index) => {
    const value = e.target.checked
    const updatedCheckList = [...checkListData];
    if (updatedCheckList[index] && updatedCheckList[index].id === checkList.id) {
      if (!updatedCheckList[index]?.reconsideredRemark) {
        toast.warning('Please add reconsideration remark first.')
        return
      }
      updatedCheckList[index].reconsidered = value;
      setCheckListData(updatedCheckList);
      try {
        setLoader(true)
        const res = await ChangeReconsiderationStatus(updatedCheckList[index], mvrFormData);
        if (res.success) {
          // toast.success('Mom has been saved.')
        }
      } catch (error) {
        console.log('error: ', error);
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setLoader(false)
      }
    }
  };

  const handleAIRemarks = async (e, checkList, index) => {
    const value = e.target.value;
    const updatedCheckList = [...checkListData];

    if (updatedCheckList[index] && updatedCheckList[index].id === checkList.id) {
      updatedCheckList[index].acceptRejectRemark = value;
      if (!value) {
        updatedCheckList[index].acceptRejectStatus = null;
      }
      setCheckListData(updatedCheckList);
    }
  }

  const handleAcceptRejectRadio = async (e, checkList, index) => {
    const updatedCheckList = [...checkListData];
    const value = e.target.value
    if (!checkListData[index].acceptRejectRemark) {
      toast.warning(`Please add ${value} remark first.`)
      e.preventDefault()
      return
    }
    if (updatedCheckList[index] && updatedCheckList[index].id === checkList.id) {
      updatedCheckList[index].acceptRejectStatus = value;
      const remarks = updatedCheckList[index].acceptRejectRemark
      if (!remarks) {
        updatedCheckList[index].acceptRejectStatus = null;
      }
      setCheckListData(updatedCheckList);

      try {
        setLoader(true)
        const res = await ChangeAcceptReconsider(updatedCheckList[index], mvrFormData)
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setLoader(false)
      }
    }
  }

  const saveAIRemark = async (index) => {
    if (checkListData?.length) {
      const currCheckList = checkListData[index]
      if (currCheckList?.acceptRejectRemark) {
        try {
          setLoader(true)
          const res = await UpdateAiRemarks(currCheckList, mvrFormData);
          // if (res.success) {
          // }
        } catch (error) {
          toast.error(error?.response?.data?.error ?? "Something went wrong");
        } finally {
          setLoader(false)
        }
      }
    }

  }

  // checklist history
  const getChecklistHistory = async (data) => {
    try {
      const res = await getCheckListHistory(mvrFormData?.financialYear, mvrFormData?.period, mvrFormData?.dealerCode, mvrFormData?.mvrId, data?.parameterId, data?.checklistId)

      if (res.success) {
        setCheckListHistory(res.checklists)
      }
      console.log(res, ' got my response');
    } catch (error) {
      console.log('Error on loading data: ', error)
    }
  }

  const openScoreHistoryModal = (data) => {
    getChecklistHistory(data)
    setIsScoreHistoryModalOpen(true);
  }
  const closeScoreHistoryModal = () => {
    setIsScoreHistoryModalOpen(false);
  }
  return (
    <>
      <FormAccordianContain>
        <div className="ExpandButtonContain">
          {/* <SmartSearchContain>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              margin="dense"
            />
            <SearchIcon />
          </SmartSearchContain> */}
          <div style={{ display: "flex", gap: "10px" }}>
            <Tooltip title="Expand All" arrow>
              <span>
                <Button
                  variant="contained"
                  onClick={() => toggleAll("expand")}
                  // disabled={expanded.length === allIds.length}
                  style={{
                    backgroundColor: "#4caf50",
                    color: "#fff",
                    minWidth: "40px",
                  }}
                >
                  <ExpandIcon />
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="Collapse All" arrow>
              <span>
                <Button
                  variant="contained"
                  onClick={() => toggleAll("collapse")}
                  // disabled={expanded.length === 0}
                  style={{
                    backgroundColor: "#f44336",
                    color: "#fff",
                    minWidth: "40px",
                  }}
                >
                  <CollapseIcon />
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>

        {checkListData?.map((data, index) => (
          <Accordion
            key={data.id}
            expanded={expanded.includes(data.id)}
            onChange={() => handleAccordionChange(data.id)}
            className="borderL"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="FormAccordianSummary borderB"
            >
              {data?.scoreReceived !== null &&
                (!data?.evidenceMandate || data?.evidences.filter(Boolean).length === data?.evidenceCount)
                && (
                  <span className="CheckIcon">
                    <CheckCircleIcon />
                  </span>
                )
              }


              <span className="momReconsider">{`${currPIndex}.${index + 1}`} {data.checklist}</span>

              <span className="ResivedScore">
                <p className="MomContain">

                  {data?.momPoint && (
                    <span className='MoM'>M</span>
                  )}
                  {data?.reconsidered && (
                    <span className='Reconsideration'>R</span>
                  )}
                  {data?.nonApplicable && (
                    <span className='Reconsideration NaApplicable'>N/A</span>
                  )}
                </p>
                <div>
                  {data?.scoreReceived !== undefined && data?.scoreReceived !== null && !data?.nonApplicable
                    ? data.scoreReceived
                    : "_"}
                  /{data.checklistTotal}
                </div>
              </span>
            </AccordionSummary>
            <AccordionDetails className="FormAccordianContent">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="View History" arrow>
                  <button onClick={() => {
                    openScoreHistoryModal(data);
                    setCheckListName(data.checklist);
                  }}
                    style={{
                      height: "25px",
                      width: "25px",
                      borderRadius: "50%",
                      backgroundColor: 'transparent'
                    }
                    }
                  >
                    <VisibilityIcon />
                  </button>
                </Tooltip>
              </div>
              <Grid container rowSpacing={1} columnSpacing={1}>
                {(data?.imageSample || data.evidenceCount > 0) && (
                  <Grid item xs={12} sm={4} md={3} xl={2}>
                    <div className="FieldContain" style={{ height: '100%' }}>
                      <label htmlFor='upload'>{data?.imageSample ? "Sample / Evaluator Uploads :" : "Evaluator Uploads :"}</label>
                      <UploadContain>
                        {data?.imageSample && (
                          <div className="UploadbtnContain">
                            <Tooltip title="Sample Image" arrow>
                              <button
                                className="UploadImg"
                                onClick={() => handleOpenModal(data?.imageSample, data?.checklist)}
                              >
                                <img src={data?.imageSample} alt="sample image" />
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        <div className="UploadDivider"></div>
                        {data?.evidenceCount > 0 && (
                          <>
                            {data?.evidences?.map((image, imageIndex) => (
                              <div key={imageIndex} className="UploadbtnContain">
                                <span className="start_mark">*</span>
                                {image ? (
                                  <>
                                    <Tooltip title={`Evaluator Image ${imageIndex + 1}`} arrow>
                                      <button
                                        className="UploadImg"
                                        onClick={() => handleOpenModal(image?.url, data?.checklist, image?.time, index, imageIndex, data?.editable)}
                                      >
                                        <img src={image.url} alt={`sample image ${imageIndex + 1}`} />
                                      </button>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip title="Evaluator Upload" arrow>
                                      <button disabled={!data?.editable} className="UploadImg" onClick={() => handleClick(index, imageIndex)}>
                                        {data?.evidenceType === 'image/*' ? <CameraAltIcon /> : <SummarizeIcon />}
                                      </button>
                                    </Tooltip>
                                  </>
                                )}
                                <input
                                  type="file"
                                  ref={(el) => {

                                    if (fileInputRefs.current[index]) {
                                      fileInputRefs.current[index][imageIndex] = el
                                    } else {
                                      const newRefArray = []
                                      newRefArray[imageIndex] = el
                                      fileInputRefs.current[index] = newRefArray
                                    }
                                  }}
                                  onChange={(e) => handleFileChange(e, data, index, imageIndex)}
                                  style={{ display: 'none' }}
                                  accept={data?.evidenceType || "image/*"}
                                  capture={data?.evidenceType === 'image/*' ? "camera" : 'file'}
                                />

                              </div>
                            ))}
                          </>
                        )}


                        {/* </div> */}
                      </UploadContain>

                    </div>
                  </Grid>

                )}
                <Grid item xs={12} sm={8} md={5} >
                  {/* <div className="DataContain"> */}
                  <div className="FieldContain">
                    <label htmlFor={`idealRequirement-${data.id}`}>Ideal Requirement :</label>
                    <textarea
                      type="text"
                      value={data?.idealRequirement || ""}
                      readOnly
                      disabled
                    />
                  </div>

                </Grid>
                <Grid item xs={12} sm={6} md={2} xl={3}>
                  <div className="FieldContain">
                    <label htmlFor={`scoringcriteria-${data.id}`}>Scoring Criteria :</label>
                    <textarea name={`scoringcriteria-${data.id}`} value={data?.scoringCriterion || ''} readOnly />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <div className="FieldContain">
                    <label htmlFor={`score-${data.id}`}>Score :</label>
                    <select name={`score-${data.id}`}
                      onChange={(e) => handleScoreChanges(e, data, index, data.controlData)}
                      value={data?.nonApplicable
                        ? -1
                        : data?.scoreReceived != null
                          ? data.scoreReceived
                          : data?.controlData?.[0]?.value || ""}

                      disabled={!data?.editable || (data?.nonApplicable && !data?.isIndependent)}
                    >
                      {data?.controlData?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.display}
                        </option>
                      ))}

                      {(data?.nonApplicableOption || data?.nonApplicable) && (
                        <option value={-1}>N/A</option>
                      )}
                    </select>
                  </div>

                </Grid>
                {/* <Grid item xs={6} md={3} lg={2}>
                <div className="FieldContain">
                  <label htmlFor={`target-${data.id}`}>Plan / Target :</label>
                  <input type="text" value="" readOnly disabled />
                </div>
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <div className="FieldContain">
                  <label htmlFor={`actual-${data.id}`}>Actual :</label>
                  <input type="text" value="" readOnly disabled />
                </div>
              </Grid> */}
                <Grid item xs={12} md={6} lg={8}>
                  <div className="FieldContain">
                    <label htmlFor={`remarks-${data.id}`}>Remarks :</label>
                    <textarea
                      type="text"
                      placeholder="Enter Your Remarks"
                      value={data?.remark || ""}
                      onChange={(e) => handleRemarkChanges(e, data, index)}
                      onBlur={() => onBlurRemark(index)}
                      disabled={!data?.editable}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                  {/* <div className="DataContain"> */}
                  <InvisibleLabel>Include Mom</InvisibleLabel>
                  <div className="FieldContain Checkbox">
                    <input disabled={data?.momDisabled || !data?.editable}
                      checked={data?.momPoint || false}
                      type="checkbox" id={`include-mom-${data.id}`}
                      onChange={(e) => handelMomChanges(e, data, index)}
                    />
                    <label htmlFor={`include-mom-${data.id}`}>Include in MoM</label>
                  </div>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                  <div className="FieldContain">
                    <label htmlFor={`target-date-${data.id}`}>Target Date :</label>
                    {/* <input type="date" /> */}
                    <DatePickerContain className="DatePickerContain">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="DD-MM-YYYY"
                          value={data?.targetDate ? dayjs(data.targetDate, "D-MM-YYYY") : null} // Ensure it's a valid dayjs object
                          className="dateTimePicker"
                          onChange={(newValue) => handleTargetDate(newValue, data, index)}
                          disabled={!data?.editable}
                          format="DD-MM-YYYY"
                          minDate={dayjs()}
                          // Replace renderInput with textField prop
                          textField={(params) => (
                            <TextField
                              {...params}
                              value={
                                dateValue
                                  ? dateValue.format("DD-MM-YYYY")
                                  : ""
                              }
                              placeholder="dd-MM-yyyy"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </DatePickerContain>

                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="FieldContain ">
                    <label htmlFor={`countermeasure-${data.id}`}>Countermeasure :</label>
                    <textarea
                      type="text"
                      placeholder="Enter Countermeasure"
                      value={data?.counterMeasure || ""}
                      onChange={(e) => handleCounterMeasure(e, data, index)}
                      onBlur={() => onBlurCounterMeasure(index)}
                      disabled={!data?.editable}

                    />
                  </div>
                </Grid>
                {(data?.reconsidered || data?.canReconsider) && (
                  <>
                    <Grid item xs={6} md={6} lg={6}>
                      <div className="FieldContain FieldContain_css FieldContain_cs_check Checkbox">
                        <input
                          disabled={!data?.canReconsider || false}
                          checked={data?.reconsidered}
                          onChange={(e) => handleReconsiderCheckbox(e, data, index)}
                          type="checkbox"
                          className="checkbox_n"
                          id="check_i"
                        />
                        <label htmlFor="check_i" className="l_check"> Request for Reconsideration</label>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <div className="FieldContain ">
                        <label htmlFor={`countermeasure-${data.id}`}>Dealer Remarks :</label>
                        <input
                          value={data?.reconsideredRemark}
                          disabled={!data?.canReconsider || false}
                          onChange={(e) => handleReconsiderRemark(e, data, index)}
                          onBlur={() => saveRemark(index)}
                          type="text"
                          placeholder="Enter Dealer Remarks"
                        />
                      </div>
                    </Grid>
                    {(data?.canAcceptReject || data?.acceptRejectStatus !== null) && (
                      <>
                        <Grid item xs={6} md={6} lg={6}>


                          <div className="FieldContain FieldContain_css Checkbox">
                            <label className="radio_btn Accept">
                              <input
                                type="radio"
                                className="radio_btn_n"
                                disabled={!data?.editable && !data?.canAcceptReject}
                                name={`counterMeasure_${index}`}
                                checked={data?.acceptRejectStatus?.toLowerCase() === "accept"}
                                value="Accept"

                                onChange={(e) => handleAcceptRejectRadio(e, data, index)}
                              />
                              Accept
                            </label>
                            <label className="radio_btn Reject">
                              <input
                                type="radio"
                                disabled={!data?.editable && !data?.canAcceptReject}
                                name={`counterMeasure_${index}`}
                                value="Reject"
                                checked={data?.acceptRejectStatus?.toLowerCase() === "reject"}
                                onChange={(e) => handleAcceptRejectRadio(e, data, index)}
                              />
                              Reject
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <div className="FieldContain">
                            <label htmlFor={`countermeasure-${data.id}`}>AI Remarks :</label>
                            <input
                              disabled={!data?.editable && !data?.canAcceptReject}
                              type="text"
                              placeholder="Enter AI Remarks"
                              value={data?.acceptRejectRemark || ""}
                              onChange={(e) => handleAIRemarks(e, data, index)}
                              onBlur={() => saveAIRemark(index)} // Uncomment and use your handler
                            />
                          </div>
                        </Grid>
                      </>
                    )}

                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}

        {modalOpen && (<ModalContain className={Drawer ? " " : "DrawerOpen"}
          open={modalOpen}>
          <FormModal>
            <div className="ModalHeader">
              <h4>{selectedCheckListTitle}</h4>
              <button
                className="CloseButton"
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="ModalBody">
              <img
                src={selectedImage}
                alt="Expanded View"
              />
            </div>
            <div className="box_btn_edit">
              {selectedImageTime && (
                <div className="ModalFooter">
                  <Typography variant="body2">{selectedImageTime}</Typography>

                </div>
              )}
              {editableIndex !== null && editableSubIndex !== null && (
                <button className="edit_btn" onClick={handleEditEvidence}>Edit</button>
              )}
            </div>
          </FormModal>


        </ModalContain>)}
        {isScoreHistoryModalOpen && <ScoreHistoryModal onClose={closeScoreHistoryModal} checklistName={checklistName} checklistHistoryData={checkListHistory} />}
      </FormAccordianContain>
      <ToastContainer />
      {loader && <Loader fullScreen={true} />}
    </>

  );
};

export default DynamicAccordion;
