import React, { useEffect, useState } from 'react'
import { Tab, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tabs, TextField } from '@mui/material';
import { ButtonContain, Card, CardContain, FieldContain, TabsContain } from '../../style/Home'
import Grid from '@mui/material/Grid';
import Filter from '../../Components/filters';
// import Table from '../../Components/table/Table';
import ExcelIcon from '../../assets/images/icons/excel.png';
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import { GetMvrTableData } from "../../services/amdWiseMvrService"
import { CommanTableContain, ContainerCustom, PaginationContain, SmartSearchContain } from '../../style/table';
import { Link } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import { NotAvailable } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilter } from "../../features/filterSlice";
import { set } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
const AMDStatus = () => {
  const [value, setValue] = useState('MVRTabs');
  const [isSticky, setIsSticky] = useState(false); // To track scroll position
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setTableData] = useState([]);
  const [count, setTotalCount] = useState();
  const [search, setSearch] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);
  const filtersValue = useSelector((state) => state.filterData);
  const [loader, setLoader] = useState(false)
  const [isDispatched, setIsDispatched] = useState(false)

  useEffect(() => {
    dispatch(resetFilter());
    setIsDispatched(true)
  }, [dispatch]);

  // set table data
  const getMvrTableData = async () => {
    try {
      setLoader(true);
      let payload = filtersValue
      payload = { ...payload, search: debouncedSearchTerm, page: page, offset: rowsPerPage }

      const res = await GetMvrTableData({ payload: payload });

      if (res.success) {
        const mappedData = res.data.MvrTableData.map(data => ({
          dealer_code: data.dealerCode,
          dealership_name: data.dealerName,
          region: data.region,
          zone: data.zone,
          area: data.area,
          city: data.city,
          evaluation_plan_date: data.evaluationDate,
          accepted_date: data.acceptedDate,
          evaluation_status: data.evaluationStatus,
          canFillMvrForm: data.canFillMvrForm,
          submittedByDealerDate: data.submittedByDealerDate,
          submittedByAmDate: data.submittedByAmDate,
          financialYear: data.financialYear,
          period: data.period,
          evaluatorName: data.evaluatorName,
          acceptanceRemarks: data.acceptanceRemarks,
          submissionRemarks: data.submissionRemarks,
          reconsiderationDate: data.reconsiderationDate,
          reconsiderationRemarks: data.reconsiderationRemarks,
        }))
        setTableData(mappedData);
        setTotalCount(res.data.totalCount);
      }
    } catch (error) {
      console.log('Error on fetching mvr table data');
    } finally {
      setLoader(false);
    }
  }

  // apply debounce for search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 2000);

    return () => clearTimeout(handler);
  }, [search])



  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };




  useEffect(() => {
    if (isDispatched) {
      getMvrTableData();
    }
  }, [page, rowsPerPage, debouncedSearchTerm, resetTrigger, isDispatched])

  // handle search functionality
  const handleSearch = async () => {
    await getMvrTableData();
  }

  // tabs handle change start
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // handle change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  }

  // position sticy start
  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // handle row click
  const handleLinkClick = (e, canFillMvrForm) => {
    if (!canFillMvrForm) {
      e.preventDefault();
      toast.error("You cannot fill MVR form before planning the visit date.");
      return;
    }
  };


  // handle reset functionality
  const handleReset = () => {
    dispatch(resetFilter())
    setResetTrigger(Date.now())
    setTimeout(() => setResetTrigger(false), 0)
  }


  return (
    <>
      <TabsContain className='TabsContain'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs example"
          className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
        >
          <Tab label="MVR" value="MVRTabs" />
          <Tab label="QVR" value="QVRTabs" />
        </Tabs>
      </TabsContain>
      {value === "MVRTabs" && (
        <>
          <CardContain>
            <Card mt={1} className='Filter_bg'>
              <Grid container spacing={1}>
                <Filter onFilterChange={handleFilterChange} reset={resetTrigger}></Filter>
                <Grid item xs={12}>
                  <ButtonContain>
                    <button className="SearchButton" onClick={handleSearch}>Search</button>
                    <button className="ResetButton" onClick={handleReset}>Reset</button>
                    {/* <button className="ExportButton"><img src={ExcelIcon} alt="Excel icon" /></button> */}
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
          <CardContain>
            <Card mt={2}>
              {/* <Table headers={headers} data={data} defaultRowsPerPage={10} /> */}
              {/* Search Field */}
              <ContainerCustom>
                <SmartSearchContain>
                  <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    fullWidth
                    marginleft="auto"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <SearchIcon />
                </SmartSearchContain>
              </ContainerCustom>
              {/* Table */}
              <CommanTableContain >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dealer Code</TableCell>
                      <TableCell>Dealership Name</TableCell>
                      <TableCell>Region</TableCell>
                      <TableCell>Zone</TableCell>
                      <TableCell>Area</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Evaluator Name</TableCell>
                      <TableCell>Evaluation Plan Date</TableCell>
                      <TableCell>Evaluation Status</TableCell>
                      <TableCell>Submission Date</TableCell>
                      <TableCell>Submission Remarks</TableCell>
                      <TableCell>Accepted Date</TableCell>
                      <TableCell>Acceptance Remarks</TableCell>
                      <TableCell>Reconsideration Date</TableCell>
                      <TableCell>Reconsideration Remarks</TableCell>
                      <TableCell>View Score Card</TableCell>
                      {/* {/* <TableCell>Sales Score</TableCell> */}
                      {/* <TableCell>CS Score</TableCell> */}
                      {/* <TableCell>Overall Status</TableCell>
                      <TableCell>PDF Signature by Dealer</TableCell>
                      <TableCell>Final Scoresheet & MoM</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link to="/mvrformfill" state={{ row }} onClick={(e) =>handleLinkClick(e, row.canFillMvrForm)}>{row.dealer_code}</Link>
                        </TableCell>
                        <TableCell>{row.dealership_name}</TableCell>
                        <TableCell>{row.region}</TableCell>
                        <TableCell>{row.zone}</TableCell>
                        <TableCell>{row.area}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.evaluatorName ? row.evaluatorName : NotAvailable}</TableCell>
                        <TableCell>{row.evaluation_plan_date}</TableCell>
                        <TableCell>{row.evaluation_status}</TableCell>
                        <TableCell>{row.submittedByAmDate ? row.submittedByAmDate : NotAvailable}</TableCell>
                        <TableCell>{row.submissionRemarks}</TableCell>
                        <TableCell>{row.accepted_date}</TableCell>
                        <TableCell>{row.acceptanceRemarks}</TableCell>
                        <TableCell>{row.reconsiderationDate}</TableCell>
                        <TableCell>{row.reconsiderationRemarks}</TableCell>
                        <TableCell><Link to={'/mvr-score-card'} state={{row}}><CreditScoreIcon/></Link></TableCell>
                        {/* <TableCell>{NotAvailable}</TableCell>
                        <TableCell>{NotAvailable}</TableCell>
                        <TableCell>{NotAvailable}</TableCell> */}
                        {/* <TableCell>{NotAvailable}</TableCell> */}
                        {/* <TableCell>{NotAvailable}</TableCell>
                        <TableCell>{NotAvailable}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CommanTableContain>

              {/* Pagination */}
              <PaginationContain>
                <TablePagination
                  component="div"
                  count={count || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </PaginationContain>
            </Card>
          </CardContain>
        </>
      )}
      {value === "QVRTabs" && (
        <>
          <CardContain>
            <Card mt={1} className='Filter_bg'>
              <Grid container spacing={1}>
                <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
                <Grid item xs={12}>
                  <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    <button className="ExportButton">Export</button>
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
        </>)}
      <ToastContainer/>
    </>
  )
}

export default AMDStatus