import { Box } from "@mui/material";
import { styled } from "@mui/system";


export const GuidelineContain = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px',
    flexWrap: 'wrap',
    marginBottom: '15px',
    '& .ScrollTable': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '10px',
        '& p': {
            fontSize: '13px',
        },
        '& .ScrollButton': {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            border: 'none',
            backgroundColor: '#000',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            '& svg': {
                fontSize: '15px',
            },
        }
    },
    '& .Guidelines': {
        '& p': {
            fontSize: '13px',
            '& span': {
                fontWeight: 'bold',
            }
        },
    }
}));


