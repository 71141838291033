import React from 'react';
import Grid from '@mui/material/Grid';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Typography, IconButton } from '@mui/material';
import { FooterContain } from '../../style/footer/Footer';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Footer Links
const footerLinks = [
  { label: 'Home', link: '/home', icon: <HomeIcon /> },
  { label: 'Visit Plan', link: '/schedulevisit', icon: <CalendarMonthIcon /> },
  { label: 'Analytics', link: '/analytics', icon: <TrendingUpIcon /> },
  { label: 'Support', link: '/support', icon: <SupportAgentIcon /> },
];

const Footer = () => {
  const location = useLocation();

  return (
    <FooterContain>
      <Grid container spacing={2} sx={{ padding: '0 10px' }}>
        {footerLinks.map((item, index) => (
          <Grid item key={index} xs={3}>
            <RouterLink
              to={item.link}
              style={{
                textDecoration: 'none',
                color: location.pathname === item.link ? '#ff4500' : 'inherit',
              }}
            >
              <IconButton
                sx={{
                  color: location.pathname === item.link ? '#ff4500' : 'inherit',
                }}
              >
                {item.icon}
              </IconButton>
              <Typography
                variant="body2"
                sx={{
                  color: location.pathname === item.link ? '#ff4500' : 'inherit',
                }}
              >
                {item.label}
              </Typography>
            </RouterLink>
          </Grid>
        ))}
      </Grid>
    </FooterContain>
  );
};

export default Footer;
