import { Box } from "@mui/material";
import { color, styled } from "@mui/system";
import { Colors } from "../theme";

export const FilterField = styled(Box)(() => ({
    '& input': {
        fontSize: '11px',
        padding: '0 5px',
        borderRadius: '5px',
        border: '1px solid gray',
        width: '100%',
        outline: 'none',
        height: '28px',
        backgroundColor: '#fff',
        color: Colors.black,
        '&:disabled,&:read-only': {
            backgroundColor: Colors.disabled
        },
        

    },
    '& input,& select': {
        fontSize: '11px',
        padding: '0 5px',
        borderRadius: '5px',
        border: '1px solid gray',
        width: '100%',
        outline: 'none',
        height: '28px',
        backgroundColor: '#fff',
        color: Colors.black,
    },
    '& select.select_d_bg': {
        backgroundColor: '#fff',
    },
    '& .SearchInput': {
        width: "100%",
        padding: "8px",
        boxSizing: "border-box",
        fontSize: '12px',
    },
    '& label': {
        display: 'block',
        fontSize: '12px',
        marginBottom: '5px',
        fontWeight: 500,
    },
    '& div.react-datepicker-wrapper': {
        width: '100%',
    },
    '& .List': {
        position: "absolute",
        top: "100%",
        left: 0,
        right: 0,
        backgroundColor: "white",
        border: "1px solid #ccc",
        listStyle: "none",
        maxHeight: "150px",
        overflowY: "auto",
        zIndex: 10,
        margin: 0,
        padding: 0,
        '& .TrueList': {
            padding: "8px",
            cursor: "pointer",
            borderBottom: "1px solid #eee",
            display: "flex",
            alignItems: "center",
            fontSize: '12px',
            '& .CheckBox': {
                marginRight: "8px",
                width: "12px",
                height: "12px",
            }
        },
        '& .FalseList': {
            fontSize: '12px',
            padding: "8px",
            cursor: "pointer",
            borderBottom: "1px solid #eee",
        },
    }

}))