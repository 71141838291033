import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  ButtonContain,
  Card,
  CardContain,
  CardCustomList,
  CardFooter,
  CardTitle,
  CustomButtonContain,
  CustomButtonContainMoM,
  CardFooterMoM,
  TableContain,
  TabsContain,
} from "../../style/Home";
import Grid from "@mui/material/Grid";
import Filter from "../../Components/filters";
import DoughnutChart from "../../Components/AM/charts/Chart";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Colors } from "../../style/theme";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDropdownData } from "../../features/filterParameterSlice";
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import { GetDashboardData } from "../../services/dashboardService";
import { resetFilter } from "../../features/filterSlice";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "../../utils/loader";
import ScoreHistoryModal from "../../Components/Modal/ScoreHistoryModal";
import PopUpModal from "../../Components/Modal/PopUpModal";
import moment from "moment";
import { UserPopUpNotification } from "../../services/popUpService";

function Home() {
  const [value, setValue] = useState("MVRTabs");
  const [isSticky, setIsSticky] = useState(false); // To track scroll position
  const [filterValues, setFilterValues] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [loader, setLoader] = useState(false);
  const filtersValue = useSelector((state) => state.filterData);
  const [resetTrigger, setResetTrigger] = useState(Date.now());
  const dispatch = useDispatch();
  const [isDispatched, setIsDispatched] = useState(false);
  // popUp Work
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
  const [popUpData, setPopUpData] = useState([]);

  const closePopupModal = () => {
    setIsPopupModalOpen(false);
  };

  const fetchPopUpData = async () => {
    try {
      const response = await UserPopUpNotification();
      if (response.success) {

        const currentStamp = moment().format('YYYY-MM-DD HH:mm:ss');
        localStorage.setItem("lastPopUpAt", currentStamp)

        const notificationArray = response?.data?.notifications ?? [] 

        if(!notificationArray.length) return;

        setPopUpData(notificationArray);
        setIsPopupModalOpen(true)
        
      } else {
        toast.error("No notifications found.");
      }
    } catch (error) {
      toast.error("Error Fetching Data",error);
      
    }
  };

  useEffect(() => {
    const lastPopUpAt = localStorage.getItem("lastPopUpAt");

    if (!lastPopUpAt) {
      fetchPopUpData();
      return;
    }

    const lastPopUpAtStamp = moment(lastPopUpAt, "YYYY-MM-DD HH:mm:ss");
    const currentStamp = moment();
    const hoursDifference = currentStamp.diff(lastPopUpAtStamp, "minutes");

    if (hoursDifference < 180) return;

    fetchPopUpData();
  }, []);

  useEffect(() => {
    dispatch(resetFilter());
    setIsDispatched(true);
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define the second table data with colors
  const secondTableData = [
    { label: "Total MoM", color: "blue" },
    { label: "Opened MoM", color: "#ff4500" },
    { label: "Completed MoM", color: "#71BC78" },
    { label: "Reopened MoM", color: "#f9b94b" },
    { label: "Closed MoM", color: "#009000" },
  ];

  // Prepare chart data (without filtering)
  const MoMchart = {
    labels: secondTableData.map((item) => item.label),
    datasets: [
      {
        data: (() => {
          const values = dashboardData?.momStatus
            ? Object.values(dashboardData?.momStatus)
            : [];
          values.pop();
          return values;
        })(),
        backgroundColor: secondTableData.map((item) => item.color),
        hoverBackgroundColor: secondTableData.map((item) => item.color),
      },
    ],
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
    },
  };

  // Excalation Table

  const excalationTableData = [
    { label: "Total Excalation", color: "blue" },
    { label: "Excalation to AI", color: "#71BC78" },
    { label: "Excalation to ZM", color: "#f9b94b" },
    { label: "Excalation to RM", color: "#ff4500" },
  ];

  const excalationChart = {
    labels: excalationTableData.map((item) => item.label),
    datasets: [
      {
        data: (() => {
          const values = dashboardData?.escStatus
            ? Object.values(dashboardData?.escStatus)
            : [];
          values.pop();
          return values;
        })(),
        backgroundColor: excalationTableData.map((item) => item.color),
        hoverBackgroundColor: excalationTableData.map((item) => item.color),
      },
    ],
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
    },
  };

  const filtersData = [
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "region",
      label: "Region",
      type: "select",
      options: [
        { value: "south", label: "South" },
        { value: "north", label: "North" },
        { value: "east", label: "East" },
        { value: "West", label: "West" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "zone",
      label: "Zone",
      type: "select",
      options: [
        { value: "delhi-south", label: "Delhi South" },
        { value: "delhi-north", label: "Delhi North" },
        { value: "delhi-east", label: "Delhi East" },
        { value: "delhi-West", label: "Delhi West" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "area",
      label: "Area",
      type: "select",
      options: [
        { value: "up-south", label: "UP South" },
        { value: "up-north", label: "UP North" },
        { value: "up-east", label: "UP East" },
        { value: "up-West", label: "UP West" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "year",
      label: "Financial Year",
      type: "select",
      options: [
        { value: "2024-2025", label: "2024-2025" },
        { value: "2023-2024", label: "2023-2024" },
        { value: "2022-2023", label: "2022-2023" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "period",
      label: "Period",
      type: "select",
      options: [
        { value: "H1", label: "H1" },
        { value: "H2", label: "H2" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "dealer-code-name",
      label: "Dealer Code Name",
      type: "select",
      options: [
        { value: "DL010001", label: "DL010001" },
        { value: "DL020002", label: "DL020002" },
        { value: "DL010003", label: "DL010003" },
        { value: "DL020004", label: "DL020004" },
      ],
    },
  ];

  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };

  const getDashboardData = async () => {
    try {
      setLoader(true);
      const payload = filtersValue;
      const res = await GetDashboardData({ payload: payload });
      if (res.success) {
        setDashboardData(res.data);
      }
    } catch (error) {
      console.log("error: ", error);
      if (error.code === "ERR_NETWORK") {
        toast.error("Network error");
      }
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleSearch = async () => {
    await getDashboardData();
  };

  useEffect(() => {
    if (isDispatched) {
      getDashboardData();
    }
  }, [resetTrigger, isDispatched]);

  // chart data start
  const MVRchart = {
    labels: [
      "Total AMD",
      "Submitted By AMD",
      "Submitted By AM",
      "Accepted By AMD",
      "Reconsideration By AMD",
      "Pending At AMD",
    ],
    datasets: [
      {
        data: (() => {
          const values = dashboardData?.mvrStatus
            ? Object.values(dashboardData?.mvrStatus)
            : [];
          values.pop(); // Remove the last value
          return values;
        })(),
        backgroundColor: [
          Colors.total,
          Colors.submitted,
          Colors.submitted,
          Colors.accepted,
          Colors.reconsidertion,
          Colors.pending,
        ],
        borderColor: [
          Colors.total,
          Colors.submitted,
          Colors.submitted,
          Colors.accepted,
          Colors.reconsidertion,
          Colors.pending,
        ],
        borderWidth: 1,
      },
    ],
  };

  const QVRchart = {
    labels: [
      "Total AMD",
      "Eligible AMD",
      "Visit Plan",
      "Evaluation By AM",
      "Reconsideration Request",
      "Accepted By AMD",
      "Avrage Score",
    ],
    datasets: [
      {
        data: [6, 1, 1, 1, 1, 1, 1],
        backgroundColor: [
          Colors.total,
          Colors.submitted,
          Colors.pending,
          Colors.total,
          Colors.reconsidertion,
          Colors.accepted,
          Colors.pending,
        ],
        borderColor: [
          Colors.total,
          Colors.submitted,
          Colors.pending,
          Colors.total,
          Colors.reconsidertion,
          Colors.accepted,
          Colors.pending,
        ],
        borderWidth: 1,
      },
    ],
  };
  // chart data end

  const handleReset = () => {
    dispatch(resetFilter());
    setResetTrigger(Date.now());
    setTimeout(() => setResetTrigger(false), 0);
  };

  return (
    <>
      <TabsContain>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs example"
          className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
        >
          <Tab label="MVR" value="MVRTabs" />
          {/* <Tab label="QVR" value="QVRTabs" /> */}
        </Tabs>
      </TabsContain>

      {isPopupModalOpen && <PopUpModal onClose={closePopupModal} data={popUpData}/>}
          <ToastContainer position="top-right" autoClose={5000} />
      {value === "MVRTabs" && (
        <>
          <CardContain>
            <Card mt={1} className="Filter_bg">
              <Grid container spacing={1}>
                <Filter
                  onFilterChange={handleFilterChange}
                  reset={resetTrigger}
                ></Filter>
                <Grid item xs={12}>
                  <ButtonContain>
                    <button onClick={handleSearch} className="SearchButton">
                      Search
                    </button>
                    <button onClick={handleReset} className="ResetButton">
                      Reset
                    </button>
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
          <CardContain mt={2}>
            <CardTitle variant="h4">MVR Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CardCustomList>
                    <li>
                      Total AMD :
                      <span className="StatusCount total">
                        {dashboardData?.mvrStatus?.total}
                      </span>
                    </li>
                    <li>
                      Submitted By AMD :
                      <span className="StatusCount submitted">
                        {dashboardData?.mvrStatus?.submittedByAmd}
                      </span>
                    </li>
                    <li>
                      Submitted By AM :
                      <span className="StatusCount submitted">
                        {dashboardData?.mvrStatus?.submittedByAm}
                      </span>
                    </li>
                    <li>
                      Accepted By AMD :
                      <span className="StatusCount accepted">
                        {dashboardData?.mvrStatus?.accepted}
                      </span>
                    </li>
                    <li>
                      Reconsideration By AMD :
                      <span className="StatusCount reconsidertion">
                        {dashboardData?.mvrStatus?.reconsidered}
                      </span>
                    </li>
                    <li>
                      Pending At AMD :
                      <span className="StatusCount pending">
                        {dashboardData?.mvrStatus?.pending}
                      </span>
                    </li>
                  </CardCustomList>
                </Grid>
                <Grid item xs={6}>
                  <DoughnutChart data={MVRchart} />
                </Grid>
              </Grid>
            </Card>
            <CardFooter>
              <div>
                <p>
                  {" "}
                  Total MVR Submitted :{" "}
                  <span className="MVRCount">
                    {dashboardData?.mvrStatus?.totalSubmitted}
                  </span>
                </p>
              </div>

              <CustomButtonContain>
                <Link to="/amdstatus">
                  <button className="Pointer">
                    AMD Wise Status <KeyboardDoubleArrowRightIcon />
                  </button>
                </Link>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>

          {/* Second Table */}

          <CardContain mt={2}>
            <CardTitle variant="h4">MoM Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CardCustomList>
                    <li>
                      Total MoM :
                      <span className="StatusCount" style={{ color: "blue" }}>
                        {dashboardData?.momStatus?.totalMom}
                      </span>
                    </li>
                    <li>
                      Opened MoM :
                      <span
                        className="StatusCount"
                        style={{ color: "#ff4500" }}
                      >
                        {dashboardData?.momStatus?.openMom}
                      </span>
                    </li>
                    <li>
                      Completed MoM :
                      <span
                        className="StatusCount"
                        style={{ color: "#71BC78" }}
                      >
                        {dashboardData?.momStatus?.completedMom}
                      </span>
                    </li>
                    <li>
                      Reopened MoM :
                      <span
                        className="StatusCount"
                        style={{ color: "#f9b94b" }}
                      >
                        {dashboardData?.momStatus?.reopenedMom}
                      </span>
                    </li>
                    <li>
                      Closed MoM :
                      <span
                        className="StatusCount"
                        style={{ color: "#009000" }}
                      >
                        {dashboardData?.momStatus?.closedMom}
                      </span>
                    </li>
                  </CardCustomList>
                </Grid>
                <Grid item xs={6}>
                  <DoughnutChart data={MoMchart} />
                </Grid>
              </Grid>
            </Card>
            <CardFooterMoM>
              <CustomButtonContainMoM>
                <Link to="/momstatus">
                  <button className="Pointer">
                    MoM Status <KeyboardDoubleArrowRightIcon />
                  </button>
                </Link>
              </CustomButtonContainMoM>
            </CardFooterMoM>
          </CardContain>

          {/* Third Table */}

          {/* <CardContain mt={2}>
            <CardTitle variant="h4">Escalation Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CardCustomList>
                    <li>
                      Total Escalation :
                      <span
                        className="StatusCount"
                        style={{ color: "blue" }}
                      >
                        {dashboardData?.escStatus?.totalEsc}
                      </span>
                    </li>
                    <li>
                      Escalation to AM :
                      <span
                        className="StatusCount"
                        style={{ color: "#71BC78" }}
                      >
                        {dashboardData?.escStatus?.amEsc}
                      </span>
                    </li>
                    <li>
                      Escalation to ZM :
                      <span
                        className="StatusCount"
                        style={{ color: "#f9b94b" }}
                      >
                        {dashboardData?.escStatus?.zmEsc}
                      </span>
                    </li>
                    <li>
                      Escalation to RM :
                      <span
                        className="StatusCount"
                        style={{ color: "#ff4500" }}
                      >
                        {dashboardData?.escStatus?.rmEsc}
                      </span>
                    </li>
                  </CardCustomList>
                </Grid>
                <Grid item xs={6}>
                  <DoughnutChart data={excalationChart} />
                </Grid>
              </Grid>
            </Card>


          </CardContain> */}
        </>
      )}
      {value === "QVRTabs" && (
        <>
          <CardContain>
            <Card mt={1} className="Filter_bg">
              <Grid container spacing={1}>
                <Filter
                  filters={filtersData}
                  onFilterChange={handleFilterChange}
                ></Filter>
                <Grid item xs={12}>
                  <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
          <CardContain mt={2}>
            <CardTitle variant="h4">QVR Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CardCustomList>
                    <li>
                      Total AMD :<span className="StatusCount total">6</span>
                    </li>
                    <li>
                      Eligible AMD :
                      <span className="StatusCount submitted">1</span>
                    </li>
                    <li>
                      Visit Plan :<span className="StatusCount pending">1</span>
                    </li>
                    <li>
                      Evaluation by AM :
                      <span className="StatusCount total">1</span>
                    </li>
                    <li>
                      Reconsideration Request :
                      <span className="StatusCount reconsidertion">1</span>
                    </li>
                    <li>
                      Accepted by AMD :
                      <span className="StatusCount accepted">1</span>
                    </li>
                    <li>
                      Avrage Score :
                      <span className="StatusCount pending">1</span>
                    </li>
                  </CardCustomList>
                </Grid>
                <Grid item xs={6}>
                  <DoughnutChart data={QVRchart} />
                </Grid>
              </Grid>
            </Card>
            <CardFooter>
              <div>
                <p> Scores of AMDs who have accepted the evaluaton</p>
              </div>

              <CustomButtonContain>
                <button>
                  AMD Evaluation Status <KeyboardDoubleArrowRightIcon />
                </button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>
          <CardContain mt={2}>
            <TableContain>
              <table className="table">
                <thead>
                  <tr className="TableTitle">
                    <th colSpan={7}>
                      <h4>MoM Status</h4>
                    </th>
                  </tr>
                  <tr>
                    <th>Total Points</th>
                    <th>Open Points</th>
                    <th>Completed By AMD</th>
                    <th>Completed By AM</th>
                    <th>Escalated To AM</th>
                    <th>Escalated To ZM</th>
                    <th>Escalated To RM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6</td>
                    <td>6</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </TableContain>
            <CardFooter>
              <div>
                <p style={{ visibility: "hidden" }}>
                  {" "}
                  Carry <span className="MVRCount"></span>
                </p>
              </div>

              <CustomButtonContain>
                <button>
                  QVR MoM Status <KeyboardDoubleArrowRightIcon />
                </button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>
        </>
      )}
      {loader && <Loader fullScreen={true} />}
    </>
  );
}

export default Home;
