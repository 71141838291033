import { apiCall } from "./authServices";

export const supportRequest = async ({ payload = null }) => {
    try {
      const endpoint = `api/support-master/generate-ticket`;
      const res = await apiCall({
        endpoint: endpoint,
        payload: payload,
        method: "post",
      });
      return res;
    } catch (error) {
      throw error;
    }
  };
  
