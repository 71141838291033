import { apiCall } from "./authServices";

export const PostGalleryData = async ({ payload = null }) => {
  try {
    const endpoint = `api/gallery/gallery-upload`
    const res = await apiCall({
      endpoint: endpoint,
      payload: payload,
      method: "post",
    });
    return res
  } catch (error) {
    throw error;
  }
}

export const GetDealerData = async () => {
  try {
    const endpoint = `api/gallery/get-dealers`
    const res = await apiCall({
      endpoint: endpoint,
      method: "get",
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const GetCurrentData = async () => {
  try {
    const endpoint = "api/gallery/current-gallery-upload";
    const res = await apiCall({
      endpoint: endpoint,
      method: "get",
    });
    return res;
  } catch (error) {
    throw error;
  }
};



export const DeleteGalleryData = async (id) => {
  try {
    const endpoint = `api/gallery/delete-gallery-data/${id}`;
    const res = await apiCall({ endpoint: endpoint, method: 'DELETE' });
    return res;
  } catch (error) {
    throw error;
  }
};


export const GetArchiveData = async () => {
  try {
    const endpoint = `api/gallery/archived-gallery-upload`;
    const payload = {
      page: 0,
      offset: 10,
    };
    const res = await apiCall({ endpoint: endpoint, method: 'post', payload: payload });
    return res;
  } catch (error) {
    throw error;
   
  }
};

export const UpdateData = async (id, validTo) => {
  try {
    const endpoint = `api/gallery/update-gallery-data/${id}`;
    const payload = {
      validTo: validTo,
    };
    const res = await apiCall({
      endpoint: endpoint,
      method: "patch",
      payload: payload,
    });
    return res;
  } catch (error) {
    throw error;
  }
};
