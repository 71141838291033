import React, { useEffect, useState } from "react";
import "../../css/Profile.css";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cropper from "react-easy-crop";
import Modal from "@mui/material/Modal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { MeData } from "../../services/MeServices";
import { AccountCircle } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";

function Profile() {
  const [profileImage, setProfileImage] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropper, setShowCropper] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const handleShowPassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const data = await MeData();
        setProfileData(data.user);
      } catch (err) {
        console.error("Error fetching profile data:", err);
        toast.error("Error fetching profile data:", err);
        setError("Failed to load profile data.");
      }
    };

    fetchProfileData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setCropImage(reader.result);
      reader.readAsDataURL(file);
      setShowCropper(true);
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Cropping logic here (if required for backend integration)
  };

  const handleUpload = () => {
    setProfileImage(cropImage);
    setShowCropper(false);
  };
  const getRoleDisplayName = (roleType) => {
    switch (roleType) {
      case "zm":
        return "Zonal Manager";
      case "dealer":
        return "Dealer";
      case "am":
        return "Area Manager";
      case "rm":
        return "Regional Manager";
      case "ho":
        return "Head Office";
      default:
        return "Unknown Role";
    }
  };

  return (
    <div className="profile-container">
       <ToastContainer position="top-right" autoClose={5000} />
      <div className="profile-header">
        <div className="profile-picture">
          {profileImage ? (
            <img src={profileImage} alt="Profile" className="profile-image" />
          ) : (
            <AccountCircle 
            sx={{
              backgroundColor: "#ff4500",
              color: "white",
              borderRadius: "50%",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              fontSize: "120px",
            }} 
          />
          
          )}
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {/* Uncomment if you want the edit functionality */}
          {/* <IconButton
        className="edit-icon"
        onClick={() => document.getElementById("fileInput").click()}
    >
        <EditIcon />
    </IconButton> */}
        </div>
      </div>

      <Modal
        open={showCropper}
        onClose={() => setShowCropper(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="cropper-modal"
          style={{ width: "80vw", height: "80vh" }}
        >
          <Cropper
            image={cropImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />

          <div className="cropper-actions">
            <button onClick={handleUpload} className="upload_button">
              Upload
            </button>
          </div>
        </div>
      </Modal>

      {profileData ? (
        <div className="user-info">
          <div className="user-detail">
            <label>User ID:</label>
            <input type="text" value={profileData.userId} disabled />
          </div>
          <div className="user-detail">
            <label>User Name:</label>
            <input type="text" value={profileData.userName} disabled />
          </div>
          <div className="user-detail">
            <label>User Email ID:</label>
            <input type="email" value={profileData.emailId} disabled />
          </div>
          <div className="user-detail">
            <label>User Type:</label>
            <input
              type="text"
              value={getRoleDisplayName(profileData?.roleType)}
              disabled
            />
          </div>

          {/* <div className="user-detail">
                    <label>Phone Number:</label>
                    <input type="text" value="+1234567890" disabled />
                </div>
                <div className="user-detail">
                    <label>Address:</label>
                    <input type="text" value="123 Main Street, City, Country" disabled />
                </div> */}
        </div>
      ) : (
        <div>Loading profile data...</div>
      )}
      {/* <div className="change-password">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setChangePassword(!changePassword);
                    }}
                >
                    Change Password
                </a>
                {changePassword && (
                    <>
                        <div className="password-form">
                            <div className="password-input-container">
                                <label>
                                    Current Password:
                                    <input
                                        type={showPassword.currentPassword ? "text" : "password"}
                                        placeholder="Enter current password"
                                    />
                                    <IconButton
                                        onClick={() => handleShowPassword("currentPassword")}
                                        className="eye_icon"
                                    >
                                        {showPassword.currentPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </label>

                                <label>
                                    New Password:
                                    <input
                                        type={showPassword.newPassword ? "text" : "password"}
                                        placeholder="Enter new password"
                                    />
                                    <IconButton
                                        onClick={() => handleShowPassword("newPassword")}
                                        className="eye_icon"
                                    >
                                        {showPassword.newPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </label>

                                <label>
                                    Confirm Password:
                                    <input
                                        type={showPassword.confirmPassword ? "text" : "password"}
                                        placeholder="Confirm new password"
                                    />
                                    <IconButton
                                        onClick={() => handleShowPassword("confirmPassword")}
                                        className="eye_icon"
                                    >
                                        {showPassword.confirmPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </label>
                            </div>

                            <button className="submit_button">Submit</button>
                        </div>
                    </>
                )}

            </div> */}
    </div>
  );
}

export default Profile;
