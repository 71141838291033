import { apiCall } from "./authServices";

export const MeData = async () => {
    try {
        const endpoint = "api/auth/me";
        const res = await apiCall({
            endpoint: endpoint,
            method: "GET",
        });
        return res;
    } catch (error) {
        throw error;
    }
};



