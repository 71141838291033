import { apiCall } from "./authServices";
import { getCurrentDateTime, getCurrentFinancialYear, getCurrentMonth, getCurrentTime, getLocation } from "../utils/helper";

export const MvrEvaluationData = async (row) => {
    try {
        const financialYear = row.financialYear;
        const period = row.period;
        const dealerCode = row.dealer_code;
        const response = await apiCall({
            endpoint: `api/evaluation/mvr/form-status/${financialYear}/${period}/${dealerCode}`,
            method: 'GET'
        })

        return response;
    } catch (error) {
        throw error;
    }
}
export const MvrFormFillFieldData = async (row) => {
    try {
        const financialYear = row.financialYear;
        const period = row.period;
        const dealerCode = row.dealer_code;
        const response = await apiCall({
            endpoint: `api/evaluation/mvr/form-parameters/${financialYear}/${period}/${dealerCode}`,
            method: 'GET'
        })

        return response;
    } catch (error) {
        throw error;
    }
}
export const GetParameterData = async (parameter, evaluationData, financialYear, period) => {
    try {
        // const financialYear = getCurrentFinancialYear();
        // const period = getCurrentMonth();
        const endpoint = `api/evaluation/mvr/form-checklists/${financialYear}/${period}/${evaluationData?.dealerCode}/mvr-id/${evaluationData?.mvrId}/parameter-id/${parameter?.parameterId}`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'GET'
        })
        if (response?.success && response?.checklists?.length) {
            for (let i = 0; i < response.checklists.length; i++) {
                const element = response.checklists[i];
                const id = `${element?.mvrId}_${element?.parameterId}_${element?.checklistId}`
                const controlArray = element.controlPlaceholders.map((display, index) => ({
                    display,
                    value: element.controlValues[index]
                }));
                controlArray.unshift({ display: "Select", value: '' })
                const uploadedEvidence = element?.evidences?.filter(value => value)?.length;
                element['uploadedEvidence'] = uploadedEvidence
                // element['editable'] = true
                element['controlData'] = controlArray
                element['id'] = id
            }
        }
        return response;
    } catch (error) {
        throw error;
    }
}

export const SaveCheckList = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = await formatPayload(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-scoring`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const SaveRemark = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getRemarkPayload(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-remark`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const SaveTargetDate = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getTargetDatePayload(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-target-date`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const SaveCounterMeasure = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getCounterMeasurePayload(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-counter-measure`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const SaveMom = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getMomPayload(checkList, mvrFormData)
        console.log('updatedPayload: ', updatedPayload);
        const endpoint = `api/evaluation/mvr/checklist-mom`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const UploadFiles = async (file, checkList, mvrFormData, index) => {
    try {
        const updatedPayload = await getUploadPayload(file, checkList, mvrFormData, index)
        console.log('updatedPayload: ', updatedPayload);
        const endpoint = `api/evaluation/mvr/checklist-evidence`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}
export const ChangeReconsiderationStatus = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getChangeReconsiderStatus(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-reconsider`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'patch',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const UpdateReconsiderationRemarks = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getReconsiderRemark(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-reconsidered-remark`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'patch',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const ChangeAcceptReconsider = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getAcceptPayload(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-accept-reconsider`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'patch',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const UpdateAiRemarks = async (checkList, mvrFormData) => {
    try {
        const updatedPayload = getAiRemarks(checkList, mvrFormData)
        const endpoint = `api/evaluation/mvr/checklist-accept-reconsider-remark`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'patch',
            payload: updatedPayload
        })
        return response;
    } catch (error) {
        throw error;
    }
}


const getAiRemarks = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "remark": checkList?.acceptRejectRemark
        }
    }
}
const getAcceptPayload = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "action": checkList?.acceptRejectStatus
        }
    }
}
const getChangeReconsiderStatus = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "reconsiderStatus": checkList?.canReconsider
        }
    }
}
const getReconsiderRemark = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "remark": checkList?.reconsideredRemark
        }
    }
}



const getUploadPayload = async (file, checkList, mvrFormData, index) => {
    const { latitude, longitude } = await getLocation();
    if (checkList && mvrFormData) {
        const payload = {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "index": index,
            "time": getCurrentDateTime(),
            "latitude": latitude?.toString() || "",
            "longitude": longitude?.toString() || ""
        };
        const formData = new FormData();
        for (const key in payload) {
            formData.append(key, payload[key]);
        }
        formData.append("evidence", file);

        return formData;
    }
};


const getMomPayload = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "momStatus": checkList?.momPoint
        }
    }
}
const getCounterMeasurePayload = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "counterMeasure": checkList?.counterMeasure || ''
        }
    }
}

const getRemarkPayload = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "remark": checkList?.remark || ''
        }
    }
}

const getTargetDatePayload = (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "targetDate": checkList?.targetDate.split('-').reverse().join('-') || ''
        }
    }
}

const formatPayload = async (checkList, mvrFormData) => {
    if (checkList && mvrFormData) {
        const { latitude, longitude } = await getLocation();
        return {
            "financialYear": mvrFormData?.financialYear,
            "period": mvrFormData?.period,
            "dealerCode": mvrFormData?.dealerCode,
            "mvrId": mvrFormData?.mvrId,
            "parameterId": checkList?.parameterId,
            "checklistId": checkList?.checklistId,
            "checklistTotal": checkList?.checklistTotal,
            "score": checkList?.scoreReceived,
            "isIndependent": checkList?.isIndependent || false,
            "scorePlaceholder": checkList?.scorePlaceholder,
            "time": getCurrentTime(),
            "latitude": latitude?.toString() || "",
            "longitude": longitude?.toString() || ""
        }
    }
}

export const getCheckListHistory = async (financialYear, period, dealerCode, mvrId, parameterId, checklistId) => {
    try{
        const endpoint =   `api/evaluation/mvr/checklists-history/${financialYear}/${period}/${dealerCode}/mvr-id/${mvrId}/parameter-id/${parameterId}/checklist-id/${checklistId}`

        const response = await apiCall({
            endpoint: endpoint,
            method: 'GET'
        });

        return response;
    }catch(error){
        throw error;
    }
}