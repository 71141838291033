import React, { useEffect, useState, version } from "react";
import { CardContain, TableContain } from "../style/Home";
import "../css/MVRSchedule.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  TextField,
  InputAdornment,
  Typography,
  Input,
  Chip,
  Box,
} from "@mui/material";
import { GetAllVersions, UploadMvrSchedule, GetMvrScheduleFutureData, DeleteMvrSchedule } from "../services/mvrSchedule";
import { formatMonth } from "../utils/helper";
import { generateNextMonths } from "../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../Components/utils/Confirmation";

function MVRSchedule() {
  // const [scheduleData, setScheduleData] = useState([
  //   {
  //     versionName: "123",
  //     procedureNumber: "98",
  //     schedulePeriod: "",
  //     region: "North, South, East, West",
  //     createdBy: "6553",
  //     createdAt: "14:03",
  //   },
  // ]);

  // Modal state
  const [openModal, setOpenModal] = useState(false);
  // const [versionName, setVersionName] = useState("");
  const [selectedVersion, setSelectedVersion] = useState({ id: "", versionName: "" });
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [getVersion, setGetVersion] = useState([]);
  // const [getProcedureNumber, setGetProcedureNumber] = useState([]);
  const [getRegions, setGetRegions] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // store delete id
  

  const getAllVersions = async () => {
    try{
      const res = await GetAllVersions();
      if (res.success){
        setGetVersion(res.versionName);
        setGetRegions(res.regions);
        const procedureNumber = res.versionName.map((row) => ({
          id: row.id,
          procedureNumber: row.procedureNumber,
        }))
        // setGetProcedureNumber(procedureNumber);
      }
    }catch(error){
      console.log('Error on fetching data for version name: ', error)
    }
  }

  const getFutureSchedule = async () => {
    try{
      const res = await GetMvrScheduleFutureData();
      if(res.success){
        setScheduleData(res.data.schedules);
      }
    }catch(error){
      console.log('Error on fetching future schedule data: ', error);
    }
  }

  useEffect(()=> {
    getAllVersions();
    getFutureSchedule();
    setMonthOptions(generateNextMonths());
  }, [])

  // const handleDelete = (index) => {
  //   console.log(`Deleting schedule at index: ${index}`);
  //   setScheduleData(scheduleData.filter((_, i) => i !== index));
  // };

  const handleVersionChange = (e) => {
    const selectedValue = e.target.value; // versionName
    const selectedRow = getVersion.find((row) => row.versionName === selectedValue);

    setSelectedVersion({
      id: selectedRow.id,
      versionName: selectedRow.versionName,
    });
  };

  // const handleMonthChange = (e) => {
  //   const newMonth = e.target.value;
  //   if (newMonth && !selectedMonths.includes(newMonth)) {
  //     setSelectedMonths([...selectedMonths, newMonth]);
  //     setEmptyValue("");
  //   }
  // };

  const handleChange = (event) => {
    setSelectedMonths(event.target.value);
  };


  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // reset modal values
  const resetModalValues = () => {
    setSelectedVersion({ id: '', versionName: '' });
    setSelectedMonths([]);
    setRegions([]);
  };

  // handle open dialog for delete
  const handleOpenDialog = (id) => {
    console.log(id , ' got my i')
    setSelectedId(id);
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedId(null);
  };

  // handle save
  const handleSave = async() => {
    if(selectedVersion.id === "" || selectedVersion.versionName === ""){
      toast.error("Please select a version name");
      return;
    }

    if(selectedMonths.length === 0){
      toast.error("Please select at least one month");
      return;
    }
    
    if(regions.length === 0){
      toast.error("Please select at least one region");
      return;
    }

    try{
      const payload = selectedMonths.map((monthString) => {
        const year = monthString.split("-")[0];
        const monthName = formatMonth(monthString).split(' ')[0];
    
        return {
          year: parseInt(year, 10),
          month: monthName,
          mvrId: parseInt(selectedVersion.id),
          versionName: selectedVersion.versionName,
          regions: regions,
        };
      });
      
      const res = await UploadMvrSchedule({ payload });
      console.log(res)
      if(res.success){
        await getFutureSchedule();
        toast.success("MVR Schedule uploaded successfully");
        setOpenModal(false);
      }
    }catch(error){
      console.log('Error on uploading mvr schedule: ', error);
      toast.error(error?.response?.data?.error ?? "Failed to upload MVR Schedule");
    }finally{
      setOpenModal(false);
      resetModalValues();
    }
  };

  // handle delete
  const handleDelete = async (id) => {
    if(!id){
      console.log("Failed to fetch id")
      toast.error("Invalid deletion request")
    }
    try{
      const res = await DeleteMvrSchedule(id);
      if(res?.success){
        toast.success("Mvr Schedule Deleted Successfully");
        setScheduleData((prevData) => prevData.filter((item) => item.id !== id));
        await getFutureSchedule();
      }else {
        toast.error("Failed to delete mvr schedule");
      }
    }catch(error){
      console.log("Error on deleting data: ", error)
    } finally{
      setDialogOpen(false);
    }
  };

  return (
    <div className="table_contain_box">
      <CardContain mt={2}>
        <TableContain>
          <div className="table-header">
            <h4 className="mvr-title">MVR-Schedule</h4>
            <button className="add-mvr-btn-style" onClick={handleOpenModal}>
              Add MVR
            </button>
          </div>
          <table className="table">
            <thead>
              <tr className="TableTitle">
                <th colSpan={8} className="TableTitle_th">
                  <h4>MVR-Schedule</h4>
                </th>
              </tr>
              <tr>
                <th className="mvr_th_f">MVR Version Name</th>
                {/* <th className="mvr_th_f">MVR Procedure Number</th> */}
                <th className="mvr_th_f">Schedule Period</th>
                <th className="mvr_th_f">Region</th>
                <th className="mvr_th_f">Created By</th>
                <th className="mvr_th_f">Created At</th>
                <th className="mvr_th_f">Action</th>
              </tr>
            </thead>
            <tbody>
              {scheduleData.map((item, index) => (
                <tr key={ index }>
                  <td>{item.versionName}</td>
                  <td>{item.schedulePeriod}</td>
                  <td>
                  {item.regions.map((region, index) => (
                    <>
                      {region}
                      {index < item.regions.length - 1 && ", "}
                    </>
                  ))}
                  </td>
                  {/* <td>{item.region}</td> */}
                  <td>{item.createdBy}</td>
                  <td>{item.createdAt}</td>
                  <td>
                    <div className="btn_action_g">
                      {/* <button
                        className="b_save"
                        onClick={() => handleSave(index)}
                      >
                        Save
                      </button> */}
                      <button
                        className="d_btn"
                        onClick={() => handleOpenDialog(item.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContain>
        <ConfirmationDialog
          open={dialogOpen}
          handleCancel={handleCloseDialog}
          handleConfirm={() => handleDelete(selectedId)}
        />
      </CardContain>

      {/* Modal for Add MVR */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": {
            width: '600px',
            borderRadius: '8px',
            padding: '20px',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div" color="primary">
            Add MVR
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ padding: '20px 0' }}>
          {/* Version Name Dropdown */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="version-name-label">Version Name</InputLabel>
            <Select
              labelId="version-name-label"
              value={selectedVersion.versionName}
              onChange={handleVersionChange}
              label="Version Name"
              name="versionName"
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
              inputProps={{
                autoComplete: 'off',
              }}
            >
              {getVersion.map((row) => (
                <MenuItem key={row.id} value={row.versionName}>{row.versionName}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Procedure Number Dropdown */}
          {/* <FormControl fullWidth margin="normal">
            <InputLabel id="procedure-number-label">Procedure Number</InputLabel>
            <Input
              id="procedure-number"
              value={modalData.procedureNumber ? modalData.procedureNumber : ''}
              disabled={true}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            />
          </FormControl> */}

          {/* Schedule Period (Month Picker) */}
            {/* <TextField
              label="Schedule Period"
              type="month"
              value={emptyValue}
              fullWidth
              onChange={handleMonthChange}
              margin="normal"
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            /> */}

          <FormControl fullWidth margin="normal">
            <InputLabel id="procedure-number-label">Select Period</InputLabel>
            <Select
              label="Schedule Period"
              multiple
              value={selectedMonths}
              onChange={handleChange}
              renderValue={(selected) =>
                selected.map(formatMonth).join(", ")
              }
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              {monthOptions.map((month) => (
                <MenuItem key={month} value={month}>
                  <Checkbox checked={selectedMonths.includes(month)} />
                  <ListItemText primary={formatMonth(month)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* {selectedMonths.length > 0 && (
            <Box sx={{ marginTop: "16px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {selectedMonths.map((month, index) => (
              <Chip
                key={index}
                label={formatMonth(month)}
                onDelete={() => handleDeleteMonth(month)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
          )} */}

          {/* Region Multiselect */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              multiple
              value={regions}
              onChange={(e) => setRegions(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {getRegions.map((region, index) => (
                <MenuItem key={index} value={region.allRegions}>
                  <Checkbox checked={regions.indexOf(region.allRegions) > -1} />
                  <ListItemText primary={region.allRegions} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions sx={{ padding: '10px' }}>
          <Button
            onClick={handleCloseModal}
            color="secondary"
            variant="outlined"
            sx={{
              width: '100px', // Set a fixed width for the Cancel button
              backgroundColor: '#f0f0f0',
              color: '#333',
              borderRadius: '4px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              "&:hover": {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{
              width: '100px',
              backgroundColor: '#4CAF50',
              color: '#fff',
              borderRadius: '4px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              "&:hover": {
                backgroundColor: '#45a049',
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
}

export default MVRSchedule;
