import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardContain } from '../../style/Home'
import Filter from '../../Components/MvrFormFill'
import Grid from '@mui/material/Grid';
import { CustomTab, FormTabs, FormTabsContain, FormTotalScore } from '../../style/HO/MVRFormFill';
import { Tab, Tabs } from '@mui/material';
import DynamicAccordion from '../../Components/accordian';
import { NotAvailable } from '../../utils/constants';
import { GetParameterData, MvrFormFillFieldData, MvrEvaluationData } from '../../services/mvrFormFill';
import { Loader } from "../../utils/loader";
import { TimeLineContain } from '../../style/timeline'
import { toast } from 'react-toastify';
import "../../css/MVRFormFill.css";


const MVRFormFill = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [mvrFormParameter, setMvrFormParameter] = useState([]);
  const [formTab, setFormTab] = useState(""); // Initialize with the first parameter
  const location = useLocation();
  const row = location.state?.row;
  // console.log(row, " got my row developed")
  const [mvrFormData, setMvrFormData] = useState({});
  const [checkListData, setCheckListData] = useState([])
  const [parameterIndex, setParameterIndex] = useState(null)
  const [updateScore, setUpdateScore] = useState(Date.now())
  const [currPIndex, setCurrPIndex] = useState(1)

  const GetMvrFormParameter = async () => {
    try {
      setLoader(true);
      const evalRes = await MvrEvaluationData(row);
      const res = await MvrFormFillFieldData(row);
      if (res.success) {
        setMvrFormData(evalRes.data);
        setMvrFormParameter(res.data.parameters)
        setFormTab(res?.data?.parameters[0].parameter)
        // await loadParameterData(res.data.parameters[0])
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false)
    }
  }


  useEffect(() => {
    const updateScore = async () => {
      try {
        const evalRes = await MvrEvaluationData(row);
        if (evalRes.success) {
          setMvrFormData(evalRes.data);
        }
      } catch (error) {
        console.error('Error updating score:', error);
      }
    };

    updateScore();
  }, []);



  const loadParameterData = async (parameter, index) => {
    try {
      if (parameter) {
        setCurrPIndex(index + 1)
        setLoader(true)
        const res = await GetParameterData(parameter, mvrFormData, row.financialYear, row.period)
        if (res.success) {
          setCheckListData(res.checklists)
          setParameterIndex(index)
        }
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (mvrFormParameter?.[0] && mvrFormData && mvrFormData?.dealerCode && mvrFormData?.mvrId) {
      loadParameterData(mvrFormParameter?.[0], 0)
    }
  }, [mvrFormData, mvrFormParameter])

  useEffect(() => {
    GetMvrFormParameter();
  }, [])

  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };


  // tabs coding start
  const handleTabs = (event, newValue) => {
    setFormTab(newValue);
  };


  const goToMoM = (e) => {
    e.preventDefault()
    navigate("/mvr-mom-form", { state: { mvrData: mvrFormData } })
  }


const handleParameterScored = ({scoredParameterIndex=null, parameterScoredQuestions , parameterObtainedScore}) =>{
  if(scoredParameterIndex===null) return

  setMvrFormParameter(prevAry => {

    prevAry[scoredParameterIndex] = {
      ...prevAry[scoredParameterIndex], 
      scoredQuestions: parameterScoredQuestions, 
      obtainedScore: parameterObtainedScore
    }

    return prevAry;
  })
}



  return (
    <>
      {loader && <Loader fullScreen={true} />}
      {/* <CardContain className='Sticky'> */}
      <CardContain>
        <Card mt={1} className='Filter_bg StickyChild'>
          <Grid container spacing={1}>
            {/* <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter> */}
            <Filter></Filter>
          </Grid>
          <Grid container pt={2}>
            {/* <Timeline  /> */}

            {mvrFormData?.evaluationTimeline && (
              <TimeLineContain>
                <div className="TimeLine">
                  <h4 className='TimeLineTitle'>Evaluation Timeline :</h4>
                  <span className="TimelineStart">{mvrFormData?.evaluationTimeline}</span>
                  {/* <span>to</span>
                  <span className="TimelineEnd">30-11-2024</span> */}
                </div>
              </TimeLineContain>
            )}
          </Grid>
        </Card>
      </CardContain>
      <FormTabsContain mt={1}>
        <FormTabs className='Sticky'>
          {/* Head */}
          <Tabs
            className='tascontain newcantain'
            value={formTab}
            onChange={(event, newValue) => setFormTab(newValue)}
            aria-label="tabs example">
            {
              mvrFormParameter.map((parameter, index) => (
                <Tab
                  onClick={() => loadParameterData(parameter, index)}
                  key={parameter.parameterId}
                  // scoredQuestions, obtainedScore
                  label={(<>
                  <span>
                    {parameter.parameter}
                    {/* <br/>
                    [{parameter.scoredQuestions}/ {parameter.obtainedScore}] */}
                  </span>
                  <p className="para_score">[{
                  parameter.scoredQuestions!==0 ?
                  parameter.obtainedScore : "_"
                  }/{parameter.totalScore}]</p>
                  </>
                )}
                  value={parameter.parameter}
                  className={parameter.totalQuestions <= parameter.scoredQuestions && parameterIndex!==index ? "bg-green" :
                            parameter.scoredQuestions!==0 && parameterIndex!==index ? "bg-yellow" :""}
                />
              ))
            }
          </Tabs>

        </FormTabs>
        <CardContain className='white_bg' sx={{ position: 'relative' }}>
          <Card className='p-0'>
            {checkListData?.length > 0 && (
              <DynamicAccordion
                mvrFormData={mvrFormData}
                checkListData={checkListData}
                setCheckListData={setCheckListData}
                parameterIndex={parameterIndex}
                handleParameterScored={handleParameterScored}
                setUpdateScore={setUpdateScore}
                currPIndex={currPIndex}
              />
            )}

          </Card>
        </CardContain>
      </FormTabsContain>
      <div onClick={goToMoM} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <button style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 20px', fontSize: '14px', borderRadius: '5px', cursor: 'pointer' }}>
          Go to MOM &gt;&gt;
        </button>
      </div>

    </>
  )
}

export default MVRFormFill