import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Container,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import { GetPopUpNotification, tapedUpdate } from "../services/popUpService";
import "../css/notificationStyles.css";

function NotificationModal({ open, handleClose, handleUpdateUnreadCount }) {
  const [notificationData, setNotificationData] = useState([]);
  const [expandedNotification, setExpandedNotification] = useState(null);

  // Function to fetch notifications
  const popUpNotification = async () => {
    try {
      const response = await GetPopUpNotification();
      if (response?.success) {
        setNotificationData(response.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleNotificationTap = async (id) => {
    try {
      const response = await tapedUpdate({ id });
      if (response?.success) {
        setNotificationData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, taped: true } : item
          )
        );
        handleUpdateUnreadCount((prevCount) => prevCount + 1);
      }
    } catch (error) {
      console.log("Error while updating tap status: ", error);
    }
  };

  const handleRedirect = (url) => {
    if (url) {
      window.location.href = url;
    }
  };

  const handleToggleExpand = (id) => {
    setExpandedNotification((prev) => (prev === id ? null : id));
  };

  useEffect(() => {
    popUpNotification();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Container
          maxWidth="sm"
          sx={{
            outline: "none",
            marginTop: { xs: 2, sm: 4 },
            paddingX: { xs: 1, sm: 2 },
          }}
        >
          <Paper elevation={3} className="paper-container">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Notifications
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            {notificationData.length > 0 ? (
              notificationData.map((notification) => (
                <Box
                  key={notification.id}
                  className="notification-container"
                  onClick={() => {
                    if (!notification.taped) {
                      handleNotificationTap(notification.id);
                    }
                    handleToggleExpand(notification.id);
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    className={`notification-box ${
                      notification.taped
                        ? "notification-tapped"
                        : "notification-untapped"
                    }`}
                    sx={{ position: "relative" }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: "100%", zIndex: 1 }}
                    >
                      <IconButton>
                        <NotificationsActiveIcon
                          className={
                            notification.taped ? "icon-tapped" : "icon"
                          }
                        />
                      </IconButton>

                      <Typography
                        variant="body1"
                        component="p"
                        sx={{
                          flexGrow: 1,
                          fontSize: { xs: "0.875rem", sm: "1rem" },
                        }}
                        className={
                          notification.taped ? "notification-text-tapped" : ""
                        }
                      >
                        {notification.heading}
                      </Typography>

                      {notification.url && notification.url !== "" && (
                        <IconButton
                        sx={{
                            marginBottom: "0.5rem",
                        
                           
                          }}
                          onClick={() => handleRedirect(notification.url)}
                        >
                          <LinkIcon />
                        </IconButton>
                      )}
                    </Box>

                    {expandedNotification === notification.id && (
                      <Typography
                        variant="body2"
                        component="p"
                        className={`notification-message ${
                          notification.taped ? "notification-text-tapped" : ""
                        }`}
                        sx={{
                          marginBottom: "0.5rem",
                          paddingBottom: "0.5rem",
                          paddingLeft: "38px",
                          fontSize: { xs: "0.75rem", sm: "0.875rem" },
                        }}
                      >
                        {notification.message}
                      </Typography>
                    )}

                    <Typography
                      variant="body2"
                      component="p"
                      className={`notification-created-at ${
                        notification.taped ? "notification-text-tapped" : ""
                      }`}
                    >
                      {notification.createdAt}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                className="no-notifications-text"
              >
                No new notifications.
              </Typography>
            )}
          </Paper>
        </Container>
      </Fade>
    </Modal>
  );
}

export default NotificationModal;
