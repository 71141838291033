import { update } from "lodash";
import { getCurrentFinancialYear, getCurrentMonth, getCurrentDateTime, getLocation} from "../utils/helper";
import { apiCall } from "./authServices";


export const MvrFinalSubmission = async (financialYear, period, dealerCode, payload) => {
    if (dealerCode && payload) {
        try {
            const endpoint = `api/evaluation/mvr/submission/${financialYear}/${period}/${dealerCode}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'POST',
                payload: payload
            })
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export const VarianceValidation = async (financialYear, period, dealerCode) => {
    if (dealerCode) {
        try {
            const endpoint = `api/evaluation/mvr/check-for-variance/${financialYear}/${period}/${dealerCode}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'GET'
            })
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export const MoMValidation = async (financialYear, period, dealerCode) => {
    if (dealerCode) {
        try {
            const endpoint = `api/mom/mvr/mom-validation/${financialYear}/${period}/${dealerCode}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'GET'
            })
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export const GetValidateData = async (financialYear, period, dealerCode) => {
    try {
        const endpoint = `api/evaluation/mvr/checklist-validation/${financialYear}/${period}/${dealerCode}`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'GET'
        })
        return response;
    } catch (error) {
        throw error;
    }
}


export const GetMoM = async (mvrData, payload) => {
    try {
        if (mvrData.dealerCode) {
            const dealerCode = mvrData.dealerCode
            const currentFinancialYear = mvrData.financialYear || getCurrentFinancialYear()
            const period = mvrData.period || getCurrentMonth()
            const endpoint = `api/mom/mvr/get-mom/${currentFinancialYear}/${period}/${dealerCode}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'POST',
                payload: payload
            })
            if (response.success) {
                for (let index = 0; index < response?.data?.moms?.length; index++) {
                    const element = response?.data?.moms[index];
                    if (element.targetDate) {
                        element['targetDate'] = element.targetDate.split("-").reverse().join('-')
                    }

                }
            }
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export const AddMoM = async (mvrData) => {
    try {
        if (mvrData.dealerCode) {
            const dealerCode = mvrData.dealerCode
            const currentFinancialYear = mvrData.financialYear || getCurrentFinancialYear()
            const period = mvrData.period || getCurrentMonth()
            const endpoint = `api/mom/mvr/add-manual-mom`
            const payload = {
                "financialYear": currentFinancialYear,
                "period": period,
                "dealerCode": dealerCode
            }
            const response = await apiCall({
                endpoint: endpoint,
                method: 'POST',
                payload: payload
            })
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export const DeleteMoM = async (momId) => {
    try {
        if (momId) {
            const endpoint = `api/mom/mvr/delete-manual-mom/${momId}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'DELETE',
            })
            return response;
        }
    } catch (error) {
        throw error;
    }
}
export const UpdateMoMCheckList = async (momId, payload) => {
    try {
        if (momId) {
            const endpoint = `api/mom/mvr/update-manual-checklist/${momId}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'PATCH',
                payload: payload
            })
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export const UpdateMoMCounterMeasure = async (momId, payload) => {
    try {
        if (momId) {
            const endpoint = `api/mom/mvr/update-counter-measure/${momId}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'PATCH',
                payload: payload
            })
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export const UpdateMoMTargetDate = async (momId, payload) => {
    try {
        if (momId) {
            const endpoint = `api/mom/mvr/update-target-date/${momId}`
            const response = await apiCall({
                endpoint: endpoint,
                method: 'PATCH',
                payload: payload
            })
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export const GetMomData = async (dealerCode, financialYear, period, {payload}) => {
    try{
        if(dealerCode){
            const currentFinancialYear = getCurrentFinancialYear()
            const period = getCurrentMonth()
            const endpoint = `api/mom/mvr/mom-data/${currentFinancialYear}/${period}/${dealerCode}`

            const res = await apiCall({
                endpoint: endpoint,
                method: 'POST',
                payload: payload,
            })
            return res;
        }
    }catch(error){
        throw error;
    }
}

export const MomRevisedUpdate = async ({payload})=>{
    try{
        const endpoint = `api/mom/mvr/revised-mom`
        const res = await apiCall({
            endpoint: endpoint,
            method: 'PATCH',
            payload: payload,
        })
        return res; 
    }catch(error){
        throw error;
    }
}

export const MomStatusDataUpdate = async ({payload})=>{
    try{
        const endpoint = `api/mom/mvr/mom-status-update`
        const res = await apiCall({
            endpoint: endpoint,
            method: 'PATCH',
            payload: payload,
        })
        return res;
    }catch(error){
        throw error;
    }
}

// handle upload file
export const UploadMomEvidence = async (file, momId, concernSource) => {
    try{
        const updatedPayload = await getMomPayloadFunc(file, momId, concernSource);
        const endpoint = `api/mom/mvr/evidence-upload-mom`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'PUT',
            payload: updatedPayload
        });
        return response;
    }catch(error){
        throw error;
    }
}

const getMomPayloadFunc = async (file, momId, concernSource) => {
    const { latitude, longitude } = await getLocation();
    if (momId && concernSource) {
        const payload = {
            "time": getCurrentDateTime(),
            "latitude": latitude?.toString() || "",
            "longitude": longitude?.toString() || "",
            "momId": momId,
            "concernSource": concernSource,
        };
        const formData = new FormData();
        for (const key in payload) {
            formData.append(key, payload[key]);
        }
        formData.append("momEvidence", file);

        return formData;
    }
};

export const GetEscalationData = async () => {
    try {
        const endpoint = `api/mom/get-mom-escalation`
        const response = await apiCall({
            endpoint: endpoint,
            method: 'GET'
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const handleMomEscalation = async (id, payload) => {
    try {
        if (id) {
            console.log(id, payload)
            const endpoint = `api/mom/update-escalation/${id}`;
            console.log('API Endpoint:', endpoint);
            console.log('Payload sent to API:', payload);
            const res = await apiCall({
                endpoint: endpoint,
                payload: payload,
                method: "PATCH",
            });
            console.log('API Response:', res);
            return res;
        }
    } catch (error) {
        console.error('Error in handleMomEscalation:', error);
        throw error; // Ensure the error is rethrown for the caller to handle.
    }
};