import React, { useEffect, useState, useCallback } from "react";
import { ButtonContain, Card, CardContain } from "../../style/Home";
import Grid from "@mui/material/Grid";
import Filter from "../../Components/filters";
import { useDispatch, useSelector } from "react-redux";
import { GetDashboardData } from "../../services/dashboardService";
import { resetFilter } from "../../features/filterSlice";
import ExcelIcon from "../../assets/images/icons/excel.png";
import {
  CommanTableContain,
  ContainerCustom,
  PaginationContain,
  SmartSearchContain,
  HoReportRadio,
} from "../../style/table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { GetExcelFile } from "../../services/hoReportService";
import { toast, ToastContainer } from "react-toastify";
import "../../css/hoReport.css"

function HoReport() {
  const [isSticky, setIsSticky] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [loader, setLoader] = useState(false);
  const filtersValue = useSelector((state) => state.filterData);
  const [resetTrigger, setResetTrigger] = useState(Date.now());
  const dispatch = useDispatch();
  const [isDispatched, setIsDispatched] = useState(false);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState("");

  const handleRadioChange = (event) => {
    const selectedReport = event.target.value;
    setSelectedValue(selectedReport);
    if (selectedReport) {
      setFileType(selectedReport);
    }
  };

  const handleFileTypeChange = async (event) => {
    const selectedType = event.target.value;
    setFileType(selectedType);

    if (selectedType) {
      await handleDownload(selectedType);
    }
  };

  useEffect(() => {
    dispatch(resetFilter());
    setIsDispatched(true);
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [filterKey]: value, 
    }));
  };

  const getDashboardData = async () => {
    try {
      setLoader(true);
      const payload = filtersValue;
      const res = await GetDashboardData({ payload: payload });
      if (res.success) {
        setDashboardData(res.data);
      }
    } catch (error) {
      console.log("error: ", error);
      if (error.code === "ERR_NETWORK") {
        toast.error("Network error");
      }
    } finally {
      setLoader(false);
    }
  };

  const handleSearch = async () => {
    await getDashboardData();
  };

  useEffect(() => {
    if (isDispatched) {
      getDashboardData();
    }
  }, [isDispatched, resetTrigger]);

  const handleReset = () => {
    dispatch(resetFilter());
    setResetTrigger(Date.now());
    setTimeout(() => setResetTrigger(false), 0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleDownload = async (mimeType) => {
    try {
      setIsLoading(true);
      const payload = {
        mimeType, 
        financialYear: filtersValue.financialYear || [],
        period: filtersValue.period || [],
        dealerCodes: filtersValue.dealerCodes || [],
        regions: filtersValue.regions || [],
        zones: filtersValue.zones || [],
        areas: filtersValue.areas || [],
      };

      const response = await GetExcelFile({
        reportType: selectedValue,
        payload: payload,
      });

      if (response?.success && response?.url) {
        const link = document.createElement("a");
        link.href = response.url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Download failed:", error);
      toast.error("Failed to download file. Please try again.");
    } finally {
      setIsLoading(false);
      setFileType("");
      setSelectedValue("");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <CardContain>
        <Card mt={1} className="Filter_bg">
          <Grid container spacing={1}>
            <Filter
              onFilterChange={handleFilterChange}
              reset={resetTrigger}
            ></Filter>
            <Grid item xs={12}>
              <ButtonContain>
                <button onClick={handleSearch} className="SearchButton">
                  Search
                </button>
                <button onClick={handleReset} className="ResetButton">
                  Reset
                </button>
                {/* <button className="ExportButton">
                  <img src={ExcelIcon} alt="Excel icon" />
                </button> */}
              </ButtonContain>
            </Grid>
          </Grid>
        </Card>
      </CardContain>

      <CardContain>
        <Card mt={2}>
          <HoReportRadio>
            {[
              "MVR Visit Report",
              "MVR Evaluation Status Report",
              "MVR MoM Status Report",
              "MoM Escalation Report",
            ].map((option, index) => (
              <label key={index}>
                <input
                className="download-option"
                  type="radio"
                  name="options"
                  value={option}
                  checked={selectedValue === option}
                  onChange={handleRadioChange}
                  style={{zIndex:-1}}
                />
                {option}
              </label>
            ))}

            {selectedValue && (
              <FormControl sx={{ minWidth: 70 }} size="small">
                <Select
                  className="download-select"
                  value={fileType}
                  onChange={handleFileTypeChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Download";
                    }
                    return "Download";
                  }}
                  disabled={isLoading || !selectedValue}
                  
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#009000",
                    color: "white",
                    borderRadius: "8px",
                    padding: "2px",
                    border: "none",
                  }}
                >
                  <MenuItem value="csv"sx={{ cursor: "pointer" }}>
                    Download CSV
                  </MenuItem>
                  <MenuItem value="xlsx"sx={{ cursor: "pointer" }}>
                    Download Excel
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </HoReportRadio>

          <ContainerCustom>
            {/* <SmartSearchContain>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginLeft: "auto" }}
              />
              <SearchIcon />
            </SmartSearchContain> */}
          </ContainerCustom>
          <CommanTableContain>
            {/* <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Region</TableCell>
                  <TableCell>Zone</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Dealer Code</TableCell>
                  <TableCell>Dealership Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>AI Code</TableCell>
                  <TableCell>AI Name</TableCell>
                  <TableCell>AI Submitted Date</TableCell>
                  <TableCell>AMD Accepted Date</TableCell>
                  <TableCell>Quality Parameter Score</TableCell>
                  <TableCell>Customer Happiness Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Central</TableCell>
                  <TableCell>MP East</TableCell>
                  <TableCell>MPE1</TableCell>
                  <TableCell>MP030001</TableCell>
                  <TableCell>SAHIL AUTOMOBILES</TableCell>
                  <TableCell>JABALPUR</TableCell>
                  <TableCell>8165</TableCell>
                  <TableCell>ROHIT LOKRE</TableCell>
                  <TableCell>06-01-2025</TableCell>
                  <TableCell>07-01-2025</TableCell>
                  <TableCell>45</TableCell>
                  <TableCell>21</TableCell>
                </TableRow>
              </TableBody>
            </Table> */}
          </CommanTableContain>

          {/* Pagination */}
          {/* <PaginationContain>
            <TablePagination
              component="div"
              count={10} // Total number of items from API
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange} // Option to handle rows per page change
            />
          </PaginationContain> */}
        </Card>
      </CardContain>
    </>
  );
}

export default HoReport;
