import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { Box } from "@mui/material";
import "../../css/MVRApproval.css";
import { SmartSearchContain } from "../../style/table";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { MVRApprovalDataFetch } from "../../services/mvrServices";
import { Link } from "react-router-dom";
import { extractDate } from "../../utils/helper";
import { Card, CardContain } from "../../style/Home";
import { CommanTableContainMVRApproval } from "../../style/table";

function MVRApproval() {
  const [mvrPending, setMvrPending] = useState([]);
  const [mvrHistory, setMvrHistory] = useState([]);

  const fetchMvrApprovalData = async () => {
    try {
      const res = await MVRApprovalDataFetch();
      const historyData = res.mvrApprovalHistory.map((data) => ({
        id: data.mvrId,
        versionName: data.versionName,
        employeeCode: data.reciverId,
        employeeName: data.reciverName,
        role: data.senderRole,
        approvalSequence: data.approvalSequence,
        status: data.approvalStatus,
        remarks: data.actionRemark,
        actionTakenAt: data.actionTakenAt,
      }));
      setMvrHistory(historyData);

      const pendingData = res.mvrApprovalPending.map((data) => ({
        id: data.mvrId,
        versionName: data.versionName,
        employeeCode: data.senderId,
        senderName: data.senderName,
        role: data.senderRole,
        status: data.approvalStatus,
        submittedAt: data.submittedAt,
        remarks: data.actionRemark,
      }));
      setMvrPending(pendingData);
    } catch (error) {
      console.log("Error on loading data: ", error);
    }
  };

  useEffect(() => {
    fetchMvrApprovalData();
  }, []);

  return (
    <TableContainer component={Paper} className="table-container-mvr">
      <Box className="search_box_container">
        <h4 className="ApprovalTitle ApprovalTitle_new">Pending Approvals</h4>
        {/* <SmartSearchContain className="search_box_r search_box_bar">
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginLeft: "auto" }}
          />
          <SearchIcon />
        </SmartSearchContain> */}
      </Box>
      {/* pending data */}
      <CardContain>
        <Card mt={2}>
          <CommanTableContainMVRApproval>
            <Table stickyHeader className="custom-table">
              <TableHead>
                <TableRow className="custom-row">
                  <TableCell className="custom-cell">S.No.</TableCell>
                  <TableCell className="custom-cell">Version Name</TableCell>
                  <TableCell className="custom-cell">Employee Code</TableCell>
                  <TableCell className="custom-cell">Sender Name</TableCell>
                  <TableCell className="custom-cell">Role</TableCell>
                  <TableCell className="custom-cell">Status</TableCell>
                  <TableCell className="custom-cell">Submitted Date</TableCell>
                  <TableCell className="custom-cell">Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mvrPending.map((row, index) => (
                  <TableRow key={index} className="custom-row myCustomRow">
                    <TableCell className="custom-cell">{index + 1}</TableCell>
                    <TableCell className="custom-cell linkColorBlue">
                      <Link to={"/approval"} state={{ data: row }}>
                        {row.versionName}
                      </Link>
                    </TableCell>
                    <TableCell className="custom-cell">
                      {row.employeeCode}
                    </TableCell>
                    <TableCell className="custom-cell">
                      {row.senderName}
                    </TableCell>
                    <TableCell className="custom-cell">{row.role}</TableCell>
                    <TableCell className="custom-cell">{row.status}</TableCell>
                    <TableCell className="custom-cell">
                      {extractDate(row.submittedAt)}
                    </TableCell>
                    <TableCell className="custom-cell">{row.remarks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CommanTableContainMVRApproval>
        </Card>
      </CardContain>

      <h4 style={{ marginTop: "20px" }} className="ApprovalTitle ApprovalTitle_new">
        History
      </h4>

      {/* history data */}

      <CardContain>
        <Card mt={2}>
          <CommanTableContainMVRApproval>
            <Table stickyHeader className="custom-table">
              <TableHead>
                <TableRow className="custom-row">
                  <TableCell className="custom-cell">S.No.</TableCell>
                  <TableCell className="custom-cell">Version Name</TableCell>
                  <TableCell className="custom-cell">Employee Code</TableCell>
                  <TableCell className="custom-cell">Employee Name</TableCell>
                  <TableCell className="custom-cell">Role</TableCell>
                  <TableCell className="custom-cell">Level</TableCell>
                  <TableCell className="custom-cell">Status</TableCell>
                  <TableCell className="custom-cell">Remarks</TableCell>
                  <TableCell className="custom-cell">
                    Approval / Return Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mvrHistory.map((row, index) => (
                  <TableRow key={index} className="custom-row">
                    <TableCell className="custom-cell">{index + 1}</TableCell>
                    <TableCell className="custom-cell linkColorBlue">
                      <Link to={"/approval"} state={{ data: row }}>
                        {row.versionName}
                      </Link>
                    </TableCell>
                    <TableCell className="custom-cell">
                      {row.employeeCode}
                    </TableCell>
                    <TableCell className="custom-cell">
                      {row.employeeName}
                    </TableCell>
                    <TableCell className="custom-cell">{row.role}</TableCell>
                    <TableCell className="custom-cell">
                      L{row.approvalSequence}
                    </TableCell>
                    <TableCell className="custom-cell">{row.status}</TableCell>
                    <TableCell className="custom-cell">{row.remarks}</TableCell>
                    <TableCell className="custom-cell">
                      {extractDate(row.actionTakenAt)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CommanTableContainMVRApproval>
        </Card>
      </CardContain>
    </TableContainer>
  );
}

export default MVRApproval;
