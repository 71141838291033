import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    financialYears: [],
    locationData: [],
    mvrPeriods: [],
    qvrPeriods: [],
    regions: [],
    zones: [],
    areas: [],
    dealerCodeName: [],
    periods: [],
    success: false,
};

const dataSlice = createSlice({
    name: 'dropdownData',
    initialState,
    reducers: {
        setDropdownData(state, action) {
            return { ...state, ...action.payload };
        },
    },
});

export const { setDropdownData } = dataSlice.actions;
export default dataSlice.reducer;
