import { Box, Button } from "@mui/material";
import { color, display, flexbox, fontSize, fontWeight, height, maxHeight, maxWidth, minHeight, styled, textAlign, width } from "@mui/system";
import { Colors } from "../theme";
import { border, borderRadius, margin, padding, position } from "polished";

export const CreationContain = styled(Box)(() => ({
  paddingTop: "20px",
  paddingLeft: "10px",
}));

export const CreationCardMobile = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  // height: "250px",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  // backgroundColor: "#64a0af",
  "& h4": {
    color: "#fff",
    fontSize: "40px",
  },
  "& img": {
    width: "100%",
  },
}));

export const CreationCard = styled(Box)(() => ({
  "& .pColor": {
    color: "#0da2ff",
  },
  "& .spColor": {
    color: "#93c572",
  },
  "& .cpColor": {
    color: "#cc0001",
  },
  "& .irColor": {
    color: "#FFC300",
  },
  "& .scColor": {
    color: "#928766",
  },
  "& .Border_PL": {
    borderLeft: "2px solid #0da2ff",
  },
  "& .Border_PB": {
    borderBottom: "1px solid #0da2ff",
  },
  "& .Border_SPL": {
    borderLeft: "2px solid #93c572",
  },
  "& .Border_SPB": {
    borderBottom: "1px solid #93c572",
  },
  "& .Border_CPL": {
    borderLeft: "2px solid #cc0001",
  },
  "& .Border_CPB": {
    borderBottom: "1px solid #cc0001",
  },
  "& .CreationHeader": {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& div": {
      width: "50%",
    },
    "& h4": {
      fontSize: "20px",
      color: Colors.gray,
      marginBottom: "10px",
    },
    "& p": {
      color: Colors.light_gray,
      fontSize: "14px",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "bolder",
      textAlign: "center",
    },
  },
  "& .CreationButtonCard": {
    position: "sticky",
    top: "60px",
    paddingTop: "20px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    zIndex: "11",
    backgroundColor: "#fff",
    "& h4": {
      fontSize: "15px",
      fontWeight: 400,
      position: "relative",
      "&::before": {
        content: '" "', // Empty string content (quotes need to be escaped properly)
        position: "absolute",
        right: "0",
        top: "7px",
        backgroundColor: Colors.gray, // Assuming Colors is defined somewhere
        height: "1px",
        width: "90%",
      },
    },
    "& .CreationButton": {
      backgroundColor: "#f5f5f5",
      border: "none",
      width: "100%",
      textAlign: "left",
      padding: "12px",
      fontSize: "11px",
      //   marginBottom: "10px",
      display: "flex",
      //   textWrap:'nowrap',
      alignItems: "center",
      gap: "5px",
      width: "200px",
      marginTop: "20px",
      "&.parameter svg": {
        color: "#0da2ff",
      },
      "&.subparameter svg": {
        color: "#93c572",
      },
      "&.checkpoint svg": {
        color: "#cc0001",
      },
      "&.idealrequirment svg": {
        color: "#FFC300",
      },
      "&.scoringcriteria svg": {
        color: "#928766",
      },
    },
  },
  "& .CreationPreview": {
    "& h4": {
      fontSize: "15px",
      fontWeight: 400,
      position: "relative",
      "&::before": {
        content: '" "', // Empty string content (quotes need to be escaped properly)
        position: "absolute",
        right: "0",
        top: "7px",
        backgroundColor: Colors.gray, // Assuming Colors is defined somewhere
        height: "1px",
        width: "70%",
      },
    },
    "& .CheckListPreviewCard": {
      // marginTop:'24px',
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 2px 4px 0px,rgba(60, 64, 67, 0.15) 0px 2px 6px 1px",
      padding: "10px",
      borderRadius: "5px",
      // maxHeight:'530px',
      // overflow:'auto',
      backgroundColor: "#fff",
      "& .ScoreCard": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
        "& .ScoreCount,& .ScoreTotalCount": {
          fontWeight: "bold",
        },
      },
      "& .CheckpointNameCard": {
        display: "flex",
        gap: "10px",
        marginBottom: "15px",
        "& p": {
          backgroundColor: "#f5f5f5",
          padding: "10px",
        },
        "& .CheckpointName": {
          width: "80%",
        },
        "& .ScoreCount": {
          width: "20%",
        },
      },
      "& .ChecklistAddCard": {
        display: "flex",
        gap: "10px",
        marginTop: "15px",
        marginBottom: "25px",
        "&.Sticky": {
          margin: "0",
        },
        "&.Controls": {
          marginLeft: "40px",
        },
        // '&.P':{
        //     '& .FirstName':{
        //         color: "#0da2ff",},
        // },
        // '&.SP':{
        //     // marginLeft:'20px',
        //     '& .FirstName':{
        //         color:'#93c572',},
        // },
        // '&.CP':{
        //     // marginLeft:'40px',
        //     '& .FirstName':{
        //         color:'#cc0001',},},
        // '&.IR':{
        //     marginLeft:'60px',
        //     '& .FirstName':{
        //         color:'#FFC300',},},
        //     '&.SC':{
        //         marginLeft:'60px',
        //         '& .FirstName':{
        //             color:'#928766',},},
        "& .CloseCheckpoint": {
          backgroundColor: "transparent",
          color: "red",
          "& svg": {
            fontSize: "22px",
          },
        },
        "& div": {
          height: "40px",
          paddingLeft: "10px",
          backgroundColor: "#f5f5f5",
          "&.SelectContain": {
            paddingLeft: "0",
            height: "auto",
            minWidth: "120px",
            fontSize: "12px",
            "& div": {
              height: "auto",
              backgroundColor: "#fff",
              "&.MuiFormControl-root": {
                paddingLeft: "0",
              },
            },
          },
          "&.PreviewButtonContain": {
            padding: "0",
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& button": {
              backgroundColor: "transparent",
            },
          },
        },
        "&.CheckpoinChilds": {
          marginLeft: "32px",
        },
        "& .CheckListInputContain": {
          backgroundColor: "#f5f5f5",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          "& .FirstName": {
            // marginRight:'10px',
            fontSize: "12px",
            width: "20px",
            "&.Icon svg": {
              color: "#002D62",
            },
          },
          "& input,& select": {
            height: "100%",
            border: "1px solid #e4e1e1",
            padding: "10px",
            fontSize: "13px",
            minWidth: "200px",
            "&.FileUpload": {
              backgroundColor: "#fff",
            },
          },

          "&.CheckListInputContain_new": {
            position: "relative",
            "& input, & textarea, & select, & div": {
              minWidth: "400px",
            },
            "& textarea": {
              border: "1px solid #e4e1e1",
              fontSize: "13px",
              height: "40px",
              padding: "5px",
              maxWidth: "600px",
              resize: "horizontal",
              overflow: "auto",
            },
            "& p": {
              position: "absolute",
              bottom: "-21px",
              right: "0",
              fontSize: "12px",
            },
            "@media (max-width: 1200px)": {
              "& input, & textarea, & select, & div": {
                minWidth: "200px", // Adjust the value as per your design requirement
              },
            },
          },
        },
        "& .MandatoryInput": {
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          paddingLeft: "0",
          "& span": {
            fontSize: "11px",
          },
        },
        "& .ScoreResievedCount,& .ScorePersentage,& .ParameterNumber": {
          width: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          fontSize: "13px",
          paddingRight: "10px",
          "& input": {
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            "&.ScoreInput": {
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              border: "1px solid #e4e1e1",
              paddingLeft: "10px",
            },
          },
          "&:has(.ScoreInput)": {
            paddingRight: "0",
            paddingLeft: "0",
          },
        },
        "& .AddIcon": {
          padding: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "10%",
        },
        "& .MandatoryInput": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
          backgroundColor: "transparent",
          padding: "0",
          "& input": {
            width: "auto",
          },
        },
      },
      "& .CheckListControls": {},
      "& .CheckListButtonContain": {
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        marginTop: "20px",
        "& button": {
          color: "#fff",
          padding: "7px 15px",
          borderRadius: "5px",
          border: "none",
        },
        "& .saveDraft": {
          backgroundColor: "#d21f3c",
        },
        "& .save": {
          backgroundColor: "#009000",
        },
      },
    },
  },
  "& .ParentSticky": {
    position: "relative",
    maxHeight: "400px",
    overflow: "auto",
    paddingTop: "0",
    "& .Sticky": {
      position: "sticky",
      top: "0px",
      padding: "15px 0",
      margin: "0",
      zIndex: "10",
      backgroundColor: "#fff",
      margin: "0",
    },
  },
  "& .StickyDraft": {
    width: "100%",
    left: "0",
    bottom: "0",
    position: "sticky",
    backgroundColor: "#fff",
    padding: "5px 0",
  },
}));

export const CreationButton = styled(Button)(() => ({
  backgroundColor: "#f5f5f5",
  border: "none",
  width: "100%",
  textAlign: "left",
  padding: "12px",
  fontSize: "11px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "200px",
  color: "#000",
  padding: "0",
  "& svg.parameter": {
    color: "#0da2ff",
  },
  "& svg.subparameter": {
    color: "#93c572",
  },
  "&.checkpoint svg": {
    color: "#cc0001",
  },
  "&.idealrequirment svg": {
    color: "#FFC300",
  },
  "&.scoringcriteria svg": {
    color: "#928766",
  },
}));

export const SelectContain = styled(Box)(() => ({
  minWidth: "120px",
  "& div": {
    backgroundColor: "#fff",
  },
}));

export const ModalContain = styled(Box)(() => ({
  padding: "10px",
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: "0",
  right: "0",
  backgroundColor: "#00000078",
  zIndex: "101",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "225ms",
  "@media (min-width:899px)": {
    "&.DrawerOpen": {
      width: "calc(100% - 260px)",
    },
  },
  "& .CheckpointModal": {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "500px",
    minHeight: "283px",
    maxHeight: "400px",
    overflow: "auto",
    position: "relative",
    "& .ModalHeader": {
      backgroundColor: Colors.primary,
      color: Colors.white,
      padding: "15px 10px",
      position: "sticky",
      left: "0",
      top: "0",
      width: "100%",
      "& h4": {
        fontWeight: "400",
      },
      "& .CloseButton": {
        position: "absolute",
        top: "5px",
        right: "5px",
        backgroundColor: "transparent",
        "& svg": {
          color: Colors.white,
        },
      },
    },
    "& .ModalBody": {
      padding: "10px 20px",
      "& h4": {
        fontWeight: 400,
        marginTop: "10px",
      },
      "& .ModalTabsContain": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "20px 0",
        gap: "10px",
        "& label": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
        },
      },
      "& .ModalFeildContain": {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        margin: "10px 0",
        "& input": {
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          fontSize: "12px",
          outline: "none",
          border: "1px solid",
          borderColor: Colors.bordercolor,
        },
      },
      "& .AddCutomize,& .DeleteField": {
        padding: "0 10px",
      },
    },
    "& .ModalFooter": {
      position: "sticky",
      bottom: "0",
      left: "0",
      width: "100%",
      padding: "20px",
      paddingTop: "10px",
      backgroundColor: "#fff",
      "& button": {
        backgroundColor: Colors.submitted,
        color: Colors.white,
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
      },
    },
  },
}));


export const ModalContainModal = styled(Box)(() => ({
  padding: "10px",
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: "0",
  right: "0",
  backgroundColor: "#00000078",
  zIndex: "101",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "225ms",
  "@media (min-width:899px)": {
    "&.DrawerOpen": {
      width: "calc(100% - 260px)",
    },
  },
  "& .CheckpointModal": {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "500px",
    maxHeight: "400px",
    overflow: "auto",
    position: "relative",
    "& .ModalHeader": {
      backgroundColor: Colors.primary,
      color: Colors.white,
      padding: "15px 10px",
      position: "sticky",
      left: "0",
      top: "0",
      width: "100%",
      "& h4": {
        fontWeight: "400",
      },
      "& .CloseButton": {
        position: "absolute",
        top: "5px",
        right: "5px",
        backgroundColor: "transparent",
        "& svg": {
          color: Colors.white,
        },
      },
    },
    "& .ModalBody": {
      padding: "10px 20px",
      "& h4": {
        fontWeight: 400,
        marginTop: "10px",
      },
      "& .ModalTabsContain": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "20px 0",
        gap: "10px",
        "& label": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
        },
      },
      "& .ModalFeildContain": {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        margin: "10px 0",
        "& input": {
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          fontSize: "12px",
          outline: "none",
          border: "1px solid",
          borderColor: Colors.bordercolor,
        },
      },
      "& .AddCutomize,& .DeleteField": {
        padding: "0 10px",
      },
    },
    "& .ModalFooter": {
      position: "sticky",
      bottom: "0",
      left: "0",
      width: "100%",
      padding: "20px",
      paddingTop: "10px",
      backgroundColor: "#fff",
      "& button": {
        backgroundColor: Colors.submitted,
        color: Colors.white,
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
      },
    },
    "& .input_form_group": {
      display: "flex",
      gap: "7px",
      flexDirection: "column",
      "& textarea": {
        minHeight: "70px",
        marginBottom: "10px",
        outline: "none",
      },
    },
    "& .btn_footer": {
      marginBottom: "5px",
      float: "right",
      "& button": {
        backgroundColor: "#009000",
        color: "#fff",
        padding: "6px",
        borderRadius: "4px",
      },

    },
  },
  '& .ModalFooter_1': {
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '7px',
    backgroundColor: '#595959',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel_btn': {
      backgroundColor: '#ccc',
      float: 'right',
      padding: '7px 12px',
      borderRadius: '4px'
    }
  }



}))

export const FillterContainer = styled(Box)(() => ({
  marginTop: '20px',
  backgroundColor: '#f9e9e2',
  padding: '10px 10px',
  borderRadius: '4px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

  '& .filter_container': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '20px',
    '@media(max-width: 600px)': {
      flexWrap: 'wrap',
    }
  },
  '& .c_dropdown_t': {
    width: '100%',
    '& label': {
      position: 'absolute',
      top: '-7px'
    },
  },
  '& .MuiOutlinedInput-root': {
    height: '35px',
    backgroundColor: '#fff',
  }, '& .MuiAutocomplete-input': {
    padding: '5px 10px',
  },
  '& .btn_group_filter': {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    '& button': {
      height: '30px',
      color: '#fff',
      border: 'none',
      outline: 'none',
      padding: '7px 15px',
      borderRadius: '5px',
      fontSize: '12px',
      marginTop: '30px',
    },
    '& .btn_search': {
      backgroundColor: '#009000',
    },
    '& .btn_reset': {
      backgroundColor: '#ff4500',
    }

  }


}));



export const HierachyMasterModal = styled(Box)(() => ({
  padding: '10px',
  width: '100%',
  height: '100vh',
  position: 'fixed',
  top: '0',
  right: '0',
  backgroundColor: '#00000078',
  zIndex: '101',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '225ms',
  '@media (min-width:899px)': {
    '&.DrawerOpen': {
      width: 'calc(100% - 260px)',
    },
  },
  '& .modal_container_box': {
    backgroundColor: '#fff',
    maxWidth: '600px',

  },
  '& .ModalHeader': {
    backgroundColor: Colors.primary,
    color: Colors.white,
    padding: '15px 10px',
    position: 'sticky',
    left: '0',
    top: '0',
    width: '100%',
    '& h4': {
      fontWeight: '400',
    },
    '& .CloseButton': {
      position: 'absolute',
      top: '5px',
      right: '5px',
      backgroundColor: 'transparent',
      '& svg': {
        color: Colors.white,

      },
    },
  },
  '& .ModalBody': {
    padding: '10px 20px',
    overflowY: "scroll",
    maxHeight: "400px",
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    '& h4': {
      fontWeight: 400,
      marginTop: '10px',
    },
    '& .input_form_group': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      marginBottom: '10px',
      '@media(max-width: 600px)': {
        width: '100%',
      },
      '& label': {
        display: 'block',
        marginBottom: '8px',
        fontWeight: '500',
      },
      '& input': {
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      },
      '& textarea': {
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: '100%',
        minWidth: '177px',
      }
    },

  },
  '& .ModalFooter': {
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '7px',
    backgroundColor: '#595959',
    '& .modal_btn_group': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',

      '& button': {
        padding: '7px 12px',
        borderRadius: '4px',
        fontSize: '16px',
        cursor: 'pointer',
        border: 'none',
      },
      '& .save_btn': {
        backgroundColor: '#ff4500',
        color: '#fff',
      },
      '& .cancel_btn': {
        backgroundColor: '#ccc',
        color: '#333',
      },
    },
  },
  '& .ModalFooter_1': {
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '7px',
    backgroundColor: '#595959',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel_btn': {
      backgroundColor: '#ccc',
      float: 'right',
      padding: '7px 12px',
      borderRadius: '4px'
    }
  }



}))

export const DealerMasterModal = styled(Box)(() => ({
  padding: '10px',
  width: '100%',
  height: '100vh',
  position: 'fixed',
  top: '0',
  right: '0',
  backgroundColor: '#00000078',
  zIndex: '101',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '225ms',
  '@media (min-width:899px)': {
    '&.DrawerOpen': {
      width: 'calc(100% - 260px)',
    },
  },
  '& .modal_container_box': {
    backgroundColor: '#fff',
    maxWidth: '600px',
    margin: '0 20px'

  },
  '& .ModalHeader': {
    backgroundColor: Colors.primary,
    color: Colors.white,
    padding: '15px 10px',
    position: 'sticky',
    left: '0',
    top: '0',
    width: '100%',
    '& h4': {
      fontWeight: '400',
    },
    '& .CloseButton': {
      position: 'absolute',
      top: '5px',
      right: '5px',
      backgroundColor: 'transparent',
      '& svg': {
        color: Colors.white,

      },
    },
  },
  '& .ModalBody': {
    padding: '10px 20px',
    overflowY: "scroll",
    maxHeight: "400px",
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    '& .password_input_form': {
      width: '350px',
      '@media(max-width: 408px)': {
        width: '300px',
      }
    },
    '& h4': {
      fontWeight: 400,
      marginTop: '10px',
    },
    '& .input_form_group': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      marginBottom: '10px',
      '@media(max-width: 600px)': {
        width: '100%',
      },
      '& label': {
        display: 'block',
        marginBottom: '8px',
        fontWeight: '500',
        '& span': {
          color: 'red'
        },
      },
      '& input': {
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      },
      '& textarea': {
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: '100%',
        minWidth: '177px',
      }
    },

  },
  '& .ModalFooter': {
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '7px',
    backgroundColor: '#595959',
    '& .modal_btn_group': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',

      '& button': {
        padding: '7px 12px',
        borderRadius: '4px',
        fontSize: '16px',
        cursor: 'pointer',
        border: 'none',
      },
      '& .save_btn': {
        backgroundColor: '#ff4500',
        color: '#fff',
      },
      '& .cancel_btn': {
        backgroundColor: '#ccc',
        color: '#333',
      },
    },
  },
  '& .ModalFooter_1': {
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '7px',
    backgroundColor: '#595959',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .modal_btn_group': {
      display: 'flex',
      gap: '10px',
    },
    '& .update_btn': {
      backgroundColor: '#ff4500',
      color: '#fff',
      padding: '7px 12px',
      borderRadius: '4px'
    },
    '& .cancel_btn': {
      backgroundColor: '#ccc',
      padding: '7px 12px',
      borderRadius: '4px'
    }
  }



}))



export const ModalContainModalPopUp = styled(Box)(() => ({
  padding: "10px",
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: "0",
  right: "0",
  backgroundColor: "#00000078",
  zIndex: "9999",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "225ms",
  "@media (min-width:899px)": {
    "&.DrawerOpen": {
      width: "calc(100% - 260px)",
    },
  },
  "& .CheckpointModal": {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "500px",
    minHeight: "500px",
    maxHeight: "500px",
    overflow: "auto",
    position: "relative",
    scrollbarWidth: "none",
    "& .ModalHeader": {
      backgroundColor: Colors.primary,
      color: Colors.white,
      padding: "15px 10px",
      position: "sticky",
      left: "0",
      top: "0",
      width: "100%",
      "& h4": {
        fontWeight: "400",
      },
      "& .CloseButton": {
        position: "absolute",
        top: "5px",
        right: "5px",
        backgroundColor: "transparent",
        "& svg": {
          color: Colors.white,
        },
      },
    },
    "& .ModalBody": {
      padding: "10px 10px 0px 10px",
      "& h4": {
        fontWeight: 400,
        marginTop: "10px",
      },
      '& .modal_images_group_scroll': {
        maxHeight: '443px',
        overflowY: 'auto',
        '& .popup_notification_preview': {
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#208dcd',
          padding: '6px 12px',
          borderRadius: '3px',
          color: '#fff',
        }
      },

    },
    "& .ModalFooter": {
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
      padding: "5px",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
      "& button": {
        color: "#000",
        display: 'flex',
        padding: "3px 5px",
        borderRadius: "5px",
        border: "1px solid #000",
      },
      '& .PreviousButton': {
        backgroundColor: 'transparent',
      },
      '& .next_btn_modal': {
        backgroundColor: 'transparent',
      }
    },
    "& .input_form_group": {
      display: "flex",
      gap: "7px",
      flexDirection: "column",
      "& textarea": {
        minHeight: "70px",
        marginBottom: "10px",
        outline: "none",
      },
    },
    "& .btn_footer": {
      marginBottom: "5px",
      float: "right",
      "& button": {
        backgroundColor: "#009000",
        color: "#fff",
        padding: "6px",
        borderRadius: "4px",
      },

    },
  },
  '& .ModalFooter_1': {
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '7px',
    backgroundColor: '#595959',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel_btn': {
      backgroundColor: '#ccc',
      float: 'right',
      padding: '7px 12px',
      borderRadius: '4px'
    }
  }



}))


export const ScoreHistoryModalModal = styled(Box)(() => ({
  padding: "10px",
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: "0",
  right: "0",
  backgroundColor: "#00000078",
  zIndex: "101",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "225ms",
  "@media (min-width:899px)": {
    "&.DrawerOpen": {
      width: "calc(100% - 260px)",
    },
  },
  "& .CheckpointModal": {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "500px",
    maxHeight: "400px",
    overflow: "auto",
    position: "relative",
    scrollbarWidth: "none",
    "& .ModalHeader": {
      backgroundColor: Colors.primary,
      color: Colors.white,
      padding: "15px 10px",
      position: "sticky",
      left: "0",
      top: "0",
      width: "100%",

      "& h4": {
        fontWeight: "400",
      },
      "& .CloseButton": {
        position: "absolute",
        top: "5px",
        right: "5px",
        backgroundColor: "transparent",
        "& svg": {
          color: Colors.white,
        },
      },
    },
    "& .ModalBody": {
      padding: "10px 10px 20px 10px",
      '& .content-container': {
        '& .score-header-Modal': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // paddingRight: '30px',
          '& .score-header-Modal-Right': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            '& span': {
              border: '1px solid #fff',
              padding: '5px 7px',
              borderRadius: '50%',
              textAlign: 'center',
              backgroundColor: "#04bcc2",
              color: '#fff'
            },
            '& .score_p': {
              padding: '6px 2px',
              fontSize: '13px',
              backgroundColor: "#00000042",

            }
          }
        },
        '& .modal_images_group': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '20px',
          marginTop: '15px',
          '& img': {
            // maxWidth: '100px',
            // maxHeight: '80px',
            maxWidth: '100px',
            maxHeight: '80px',
          },

        },
        '& .input_group_m': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '8px',
          '& label': {
            marginBottom: '5px',
          },
          '& textarea': {
            width: '100%',
            padding: '4px 5px'
          },
          '& input': {
            width: '100%',
            height: '40px',
            paddingLeft: '4px'
          }
        }
      },
      '& .clicked_image': {
        maxWidth: '100%',
        maxHeight: '100%',
      }


    },


  },




}))

