import React, { useState } from "react";
import { useMatches } from "../../style/theme";
import {
  CreationButton,
  CreationCard,
  CreationCardMobile,
  CreationContain,
  ModalContain,
  SelectContain,
} from "../../style/HO/MVRManualCreation";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, Modal } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import demoImages1 from "../../assets/images/logo.png";
import IconButton from "@mui/material/IconButton";
import { ImagePreview } from "../../style/HO/QVRManualCreation";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';

const QVRManualCreation = () => {
  const { customMatches } = useMatches();
  const [modalState, setModalState] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const Drawer = useSelector((state) => state.drawer);
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  const setModal = () => {
    setModalState(true);
  };
  const handleModalChange = (event) => {
    const value = event?.target?.value || event;
    setSelectedOption(value);
  };

  // si modal for images preview start

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // si modal for images preview end

  return (
    <CreationContain>
      {customMatches ? (
        <CreationCardMobile>
           <img src={desktopImage} alt="img" />
        </CreationCardMobile>
      ) : (
        <CreationCard>
          <div className="CreationHeader">
            <div>
              <h4>Create Dealer Evaluation Checklist</h4>
              <p>Fill in the request detail to create new checklist</p>
            </div>
          </div>
              <div className="CreationButtonCard">
                <h4>Element</h4>
                <button className="CreationButton">
                  <AddIcon /> Parameter
                </button>
              </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="CreationPreview">
                <div className="CheckListPreviewCard">
                  <div className="ScoreCard">
                    <p>Used / Total Evaluation Scope</p>
                    <p>
                      (<span className="ScoreCount">0</span> /{" "}
                      <span className="ScoreTotalCount">1000</span>)
                    </p>
                  </div>
                  <div className="CheckpointNameCard">
                    <p className="CheckpointName">Nikhili</p>
                    <p className="ScoreCount">100</p>
                  </div>

                  {/* Dynamically render added Parameters */}
                  <Accordion className="Border_PL">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="Border_PB"
                    >
                      <Typography>Parameter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="ChecklistAddCard P">
                        {" "}
                        {/* Delete Parameter button */}
                        <button className="CloseCheckpoint">
                          <CloseIcon />
                        </button>
                        <div className="CheckListInputContain">
                          <span className="FirstName">P</span>
                          <input type="text" placeholder="Enter Parameter" />
                        </div>
                        <div className="ScoreResievedCount">
                          <input
                            className="ScoreInput"
                            type="number"
                            placeholder="100"
                          />
                        </div>
                        <div className="ScorePersentage">0.00%</div>
                        <div className="ParameterNumber">1</div>
                        <CreationButton>
                          <AddIcon className="parameter" /> Sub Parameter
                        </CreationButton>
                      </div>

                      {/* Dynamically render Subparameters */}
                      <Accordion className="Border_SPL">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          className="Border_SPB"
                        >
                          <Typography>Subparameters</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="ChecklistAddCard SP">
                            {/* Delete Subparameter button */}
                            <button className="CloseCheckpoint">
                              <CloseIcon />
                            </button>
                            <div className="CheckListInputContain">
                              <span className="FirstName">SP</span>
                              <input
                                type="text"
                                placeholder="Enter Subparameter"
                              />
                            </div>
                            <div className="ScoreResievedCount">
                              <input
                                className="ScoreInput"
                                type="number"
                                placeholder="100"
                              />
                            </div>
                            <div className="ScorePersentage">0.00%</div>
                            <div className="ParameterNumber">1.1</div>
                            <CreationButton>
                              <AddIcon className="subparameter " /> Check Point
                            </CreationButton>
                          </div>

                          {/* Dynamically render Checkpoints */}
                          <Accordion className="Border_CPL">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              className="Border_CPB"
                            >
                              <Typography>Checkpoints</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="ChecklistAddCard CP">
                                {/* Delete Checkpoint button */}
                                <button className="CloseCheckpoint">
                                  <CloseIcon />
                                </button>
                                <div className="CheckListInputContain CheckListInputContain_new">
                                  <span className="FirstName cpColor">CP</span>
                                  <input
                                    type="text"
                                    placeholder="Enter Checkpoint"
                                  />
                                </div>
                                <div className="ScoreResievedCount">
                                  <input
                                    className="ScoreInput"
                                    type="number"
                                    name="totalScore"
                                    placeholder="2"
                                    min="0"
                                  />
                                </div>
                                {/* <div className="ScorePersentage">0.00%</div> */}
                                <div className="ParameterNumber">1.1.1</div>
                                <div className="SelectContain">
                                  <FormControl fullWidth>
                                    <Select
                                      id="checkbox-select"
                                      multiple
                                      displayEmpty
                                      value={selectedValues}
                                      onChange={handleChange}
                                      renderValue={() => "Select"} // Always display "Select" regardless of selection
                                      sx={{
                                        height: 50,
                                        ".MuiSelect-select": {
                                          height: "40px !important",
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0",
                                        },
                                        '& .MuiSelect-nativeInput':{
                                          zIndex:'-1',
                                        }
                                      }}
                                    >
                                      <MenuItem value="First">
                                        <Checkbox
                                          checked={selectedValues.includes(
                                            "First"
                                          )}
                                        />
                                        <ListItemText primary="First" />
                                      </MenuItem>
                                      <MenuItem value="Second">
                                        <Checkbox
                                          checked={selectedValues.includes(
                                            "Second"
                                          )}
                                        />
                                        <ListItemText primary="Second" />
                                      </MenuItem>
                                      <MenuItem value="Third">
                                        <Checkbox
                                          checked={selectedValues.includes(
                                            "Third"
                                          )}
                                        />
                                        <ListItemText primary="Third" />
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              {/* Render selected items as cards */}
                              <Box mt={2}>
                                <div className="ChecklistAddCard P CheckpoinChilds">
                                  <div className="CheckListInputContain CheckListInputContain_new">
                                    <span className="FirstName ">IR</span>
                                    <input
                                      type="text"
                                      placeholder="Enter Ideal Requirment"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box mt={2}>
                                <div className="ChecklistAddCard P CheckpoinChilds">
                                  <div className="CheckListInputContain CheckListInputContain_new">
                                    <span className="FirstName ">SR</span>
                                    <input
                                      type="text"
                                      placeholder="Enter Scoring criteria"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box mt={2}>
                                <div className="ChecklistAddCard P CheckpoinChilds">
                                  <div className="CheckListInputContain CheckListInputContain_new">
                                    <span className="FirstName ">DD</span>
                                    <select name="dd" id="dd">
                                      <option value="">Select</option>
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                  </div>
                                  <button
                                    className="AddIcon"
                                    onClick={() => setModalState(true)}
                                  >
                                    <AddIcon />
                                  </button>
                                </div>
                              </Box>
                              <Box mt={2}>
                                <div className="ChecklistAddCard P CheckpoinChilds">
                                  <div className="CheckListInputContain CheckListInputContain_new">
                                    <span className="FirstName ">SI</span>
                                    <input type="file" />
                                  </div>

                                  <div className="PreviewButtonContain">
                                  <Tooltip title="Image Preview"> 
                                    <button onClick={() => setImagePreview(true)}><RemoveRedEyeIcon/></button>
                                  </Tooltip>
                                
                                  </div>

                                </div>
                              </Box>
                              <Box mt={2}>
                                <div className="ChecklistAddCard P CheckpoinChilds">
                                  <div className="CheckListInputContain CheckListInputContain_new">
                                    <span className="FirstName">
                                      <PhotoCameraIcon />
                                    </span>
                                    <input type="text" />
                                  </div>
                                  <div className="MandatoryInput">
                                    <input type="checkbox" />
                                    Mandatory Image
                                  </div>
                                </div>
                              </Box>
                              <Box mt={2}>
                                <div className="ChecklistAddCard P CheckpoinChilds">
                                  <div className="CheckListInputContain CheckListInputContain_new">
                                    <span className="FirstName rColor">R</span>
                                    <input
                                      type="text"
                                      placeholder="Enter Remarks"
                                    />
                                  </div>
                                </div>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>

                  <div className="CheckListButtonContain">
                    <button className="saveDraft">Save as Draft</button>
                    <button className="save">Save</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* modal start */}

          {modalState && (
            <ModalContain className={Drawer ? " " : "DrawerOpen"}>
              <div className="CheckpointModal">
                <div className="ModalHeader">
                  <h4>Add Dropdown Optaions</h4>
                  <button
                    className="CloseButton"
                    onClick={() => setModalState(false)}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="ModalBody">
                  <h4>Select Option Type:</h4>
                  <div className="ModalTabsContain">
                    <label>
                      <input
                        type="radio"
                        name="DropDown"
                        value="YesNo"
                        onChange={handleModalChange}
                      />{" "}
                      Yes / No
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="DropDown"
                        value="Number"
                        onChange={handleModalChange}
                      />{" "}
                      Number
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="DropDown"
                        value="Customize"
                        onChange={handleModalChange}
                      />{" "}
                      Customize
                    </label>
                  </div>
                  <div>
                    {selectedOption === "YesNo" && (
                      <div className="ModalFeildContain">
                        <input type="text" placeholder="Yes" />
                        <input type="text" placeholder="No" />
                      </div>
                    )}
                    {selectedOption === "Number" && (
                      <div className="ModalFeildContain">
                        <input type="text" placeholder="Lower No." />
                        <input type="text" placeholder="Upper No." />
                      </div>
                    )}
                    {selectedOption === "Customize" && (
                      <>
                        {/* Default Field (Always at the Top) */}
                        <div className="ModalFeildContain">
                          <input type="text" placeholder="Text" />
                          <input type="text" placeholder="Value" />
                          <button className="AddCutomize">
                            <AddIcon />
                          </button>
                        </div>

                        {/* Dynamically Added Fields */}
                        <div className="ModalFeildContain">
                          <input type="text" placeholder="Text" />
                          <input type="text" placeholder="Value" />
                          <button className="DeleteField">
                            <DeleteIcon />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="ModalFooter">
                  <button>Save</button>
                </div>
              </div>
            </ModalContain>
          )}

          {/* modal end */}
          
                                  {/* image preview modal start */}
                                  {imagePreview && (
                                    <ModalContain className={Drawer ? " " : "DrawerOpen"} >
                                       <ImagePreview>
                                        <div className="ModalHeader">
                                          <h4>Image Preview </h4>
                                          <button
                                            className="CloseButton"
                                            onClick={() =>
                                              setImagePreview(false)
                                            }
                                          >
                                            <CloseIcon />
                                          </button>
                                        </div>
                                        <div className="ModalBody">
                                          <img
                                            src={demoImages1}
                                            alt="Uploaded Image"
                                          />
                                        </div>
                                        </ImagePreview>
                                    </ModalContain>
                                  )}
                                  {/* image preview modal end */}
        </CreationCard>
      )}
    </CreationContain>
  );
};

export default QVRManualCreation;
