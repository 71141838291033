import moment from "moment";
import momenttz from "moment-timezone";


export function toTitleCase(str) {
    return str
        ?.toLowerCase()
        .split(/(\s|-|_)/)
        .map(word => {
            return /^[a-zA-Z0-9]/.test(word)
                ? word.charAt(0).toUpperCase() + word.slice(1)
                : word;
        })
        .join('');
}

export function formatDatePickerDate(utcDate) {
    const utc = new Date(utcDate); // Parse the UTC date
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const istDate = new Date(utc.getTime() + istOffset); // Add IST offset
    return istDate.toISOString().split('T')[0]; // Extract YYYY-M
}
export function extractDate(dateTime) {
    if (dateTime) {
        const date = dateTime.split(" / ")[0];
        return date
    }

}


export const getCurrentFinancialYear = () => {
    const today = moment();
    const currentYear = today.year();
    const currentMonth = today.month();

    if (currentMonth >= 3) {
        return `${currentYear}-${currentYear + 1}`;
    } else {
        return `${currentYear - 1}-${currentYear}`;
    }
};

export const getCurrentMonth = () => {
    return moment().tz("Asia/Kolkata").format("MMMM");
}


export const getLocation = () => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    console.error("Geolocation Error: ", error.message); 
                    resolve({ latitude: null, longitude: null }); // Return null if geolocation fails
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            resolve({ latitude: null, longitude: null }); // Return null if geolocation is not supported
        }
    });
}

export const getCurrentTime = ()=>{
    const now = new Date(); 
    const day = String(now.getDate()).padStart(2, "0"); 
    const month = String(now.getMonth() + 1).padStart(2, "0"); 
    const year = now.getFullYear(); 
    let hour = now.getHours(); 
    const minute = String(now.getMinutes()).padStart(2, "0"); 
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour === 0 ? 12 : hour; 
    return `${day}-${month}-${year} ${String(hour).padStart(2, "0")}:${minute} ${period}`;
}

export const getCurrentDateTime = () => {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');  
    const month = String(now.getMonth() + 1).padStart(2, '0'); 
    const year = now.getFullYear();
    
    const hours = String(now.getHours()).padStart(2, '0'); 
    const minutes = String(now.getMinutes()).padStart(2, '0'); 
    
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  
export const formatMonth = (month) => {
    const date = new Date(month);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
};

export const generateNextMonths = () => {
    const months = [];
    const currentDate = new Date();
    for (let i = 2; i <= 8; i++) {
      const nextMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      );
      months.push(nextMonth.toISOString().slice(0, 7)); // Format as "YYYY-MM"
    }
    return months;
  };
  
  
