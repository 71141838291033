import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ModalContainModal
} from "../../style/HO/MVRManualCreation";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MvrFinalSubmission } from '../../services/momService';

function SubmissionModal({ action, mvrData, varianceData, onClose }) {
    const Drawer = useSelector((state) => state.drawer);
    const navigate = useNavigate()

    const [remark, setRemark] = useState('');
    const [variance, setVariance] = useState('');

    const handleSubmit = async () => {
        try {
            if (mvrData.dealerCode) {
                if (!remark.trim()) {
                    toast.warning('Please enter remark!.')
                    return
                }
                const payload = {
                    remark: remark,
                    action: action.value
                }
                if (variance) {
                    payload['reasonForVariance'] = variance
                }

                const res = await MvrFinalSubmission(mvrData?.financialYear, mvrData?.period, mvrData.dealerCode, payload)
                if (res.success) {
                    setVariance('')
                    setRemark('')
                    toast.success('Form Saved Successfully.')
                    setTimeout(() => {
                        navigate('/home')
                    }, 1500);
                }

            }
        } catch (error) {
            toast.error(error?.response?.data?.error ?? error?.message);
        }
    };

    return (
        <div>
            <ToastContainer />
            <ModalContainModal className={Drawer ? " " : "DrawerOpen"}>
                <div className="CheckpointModal">
                    <div className="ModalHeader">
                        <h4>{`${mvrData?.versionName} ${action?.action}`}</h4>
                        <button
                            className="CloseButton"
                            onClick={() => onClose && onClose()}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="ModalBody">
                        <div className="input_form_group">
                            <label>Remark</label>
                            <textarea
                                className="m_textarea"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </div>
                        {varianceData?.validationMessage && (
                            <div className="input_form_group">
                                <label>Reason for variance</label>
                                <textarea
                                    className="m_textarea"
                                    value={variance}
                                    onChange={(e) => setVariance(e.target.value)}
                                />
                            </div>
                        )}
                        <div className="btn_footer">
                            <button onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </ModalContainModal>
        </div>
    );
}

export default SubmissionModal;
