import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Error, LoginContain, LoginFooter, RequieredStar } from "../../style/login/Login";
import Logo from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiCall } from "../../services/authServices";
import { Loader } from "../../utils/loader";

function Getotp() {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const responseData = location.state?.data;
  const navigate = useNavigate();
  // Validate the form
  const validateForm = () => {
    let formErrors = {};
    if (!responseData.userType) formErrors.userType = "User Type is required";
    if (!responseData.userId.trim()) formErrors.userId = "User ID is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmitOtp = async (e) => {
    try {
      e.preventDefault();
      if (validateForm()) {
        setLoading(true)
        const payload = {
          userType: responseData.userType,
          userId: responseData.userId,
        };
        const res = await apiCall({
          endpoint: "api/auth/send-otp",
          method: "POST",
          payload: payload,
        });
        if (res && res.success) {
          navigate("/send-otp", { state: { data: payload } });
        }
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };

  return (
    <>
      <ToastContainer />
      <LoginContain>
        <div className="LoginLogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="LoginCard">
          <div className="LoginTitle">
            <h4>Get OTP</h4>
            <p>EasyGo Audit support team is just a tap away!</p>
          </div>
          <form onSubmit={handleSubmitOtp}>
            <div className="LoginGroup">
              <label htmlFor="user-id" className="forgot-label">
                User Email
              </label>
              <input
                type="text"
                id="user-id"
                className="forgot-input"
                placeholder={responseData?.emailId}
                readOnly
              />
              {errors.userType && <Error>{errors.userType}</Error>}
            </div>
            <div className="LoginGroup">
              <label htmlFor="user-id" className="forgot-label">
                User Mobile
              </label>
              <input
                type="text"
                id="user-id"
                className="forgot-input"
                placeholder={responseData?.phoneNumber}
                readOnly
              />
              {errors.userId && <Error>{errors.userId}</Error>}
            </div>
            <div className="LoginGroup LoginBtn">
              <button type="submit" disabled={loading}> {loading ? <Loader /> : "Submit"}</button>
            </div>
          </form>
        </div>
      </LoginContain>
            <LoginFooter>
            <p>  POWERED BY: <span>Manthan IT Solutions</span></p>
            </LoginFooter>
    </>
  );
}

export default Getotp;

// import React from 'react'
// import { Link } from 'react-router-dom'

// function Getotp() {
//     return (
//         <div className="forgot-container">
//             <div className='forgot_box'>
//                 <h1 className="forgot-title">Reset Your Password</h1>
//                 <div className="input_field">
//                     <label htmlFor="user-id" className="forgot-label">User Email</label>
//                     <input type="text" id="user-id" className="forgot-input" placeholder="g******tyd@gmail.com" />
//                 </div>
//                 <div className="input_field">
//                     <label htmlFor="user-id" className="forgot-label">User Mobile</label>
//                     <input type="text" id="user-id" className="forgot-input" placeholder="******5647" />
//                 </div>
//                 <Link className="Link" to={"/send-otp"}>
//                     <button className="forgot-button">Send OTP</button>
//                 </Link>

//             </div>
//         </div>
//     )
// }

// export default Getotp
