import { useState } from "react";
import { SwitchContainer } from "../../style/Switch/index";

const Switch = () => {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
  };

  return (
    <SwitchContainer>
      <div className={`switch ${checked ? "active" : ""}`} onClick={handleClick}>
        <div className="switch-handle" />
      </div>
    </SwitchContainer>
  );
};

export default Switch;
