import { apiCall } from "./authServices";

export const GetMvrMoM = async ({payload}) => {
    try {
        const endpoint = `api/dashboard/mvr-mom-data`
        const res = await apiCall({endpoint: endpoint, method : 'post', payload: payload});
        return res.data;
    } catch (error) {
        throw error;
    }
};


export const GetEscalationData = async ({payload}) => {
    try {
        const endpoint = `api/dashboard/get-mom-escalation-form-data`
        const res = await apiCall({endpoint: endpoint, method : 'post', payload: payload});
        return res.data;
    } catch (error) {
        throw error;
    }
}

export const GetEscalationDays = async () => {
    try{
        const endpoint = "api/dashboard/get-mom-escalation-days"
        const res = await apiCall({
            endpoint: endpoint,
            method: "GET"
        })
        return res;
    }catch(error){
        throw error;
    }
}