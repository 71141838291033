import { apiCall } from "./authServices";


export const FetchPreviewData = async (id) => {
    console.log(id)
    try{
        const endpoint = `api/manual/mvr/get-manual/${id}`;
        const res = await apiCall({
            endpoint: endpoint,
            method: "get",
        });
        
        console.log("first",res);
        return res;
    } catch (error) {
        throw error;
    }
}
