import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: null,
    userName: '',
    userType: '',
    oemId: null,
    roleType: '',
    emailId: '',
    passwordExpDate: '',
    userRights: {
        editUserRights: false,
        createManual: false,
        viewManual: false,
        approveManual: false,
        notifications: false,
        escalation: false,
        notice: false,
        analytics: false,
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { userId, userName, userType, oemId, roleType, emailId, passwordExpDate, ...userRights } = action.payload;
            state.userId = userId;
            state.userName = userName;
            state.userType = userType;
            state.oemId = oemId;
            state.roleType = roleType;
            state.emailId = emailId;
            state.passwordExpDate = passwordExpDate;
            state.userRights = { ...userRights };
        },
        clearUser: (state) => {
            Object.assign(state, initialState);
        },
        logout: (state) => {
            // This can be empty since the root reducer will handle the state reset
        },
    },
});

export const { setUser, clearUser, logout } = userSlice.actions;
export default userSlice.reducer;
