import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import {
  ButtonContain,
  Card,
  CardContain,
  FieldContain,
  TabsContain,
} from "../../style/Home";
import Grid from "@mui/material/Grid";
import Filter from "../../Components/filters";
// import Table from '../../Components/table/Table';
import ExcelIcon from "../../assets/images/icons/excel.png";
import {
  CommanTableContain,
  ContainerCustom,
  PaginationContain,
  SmartSearchContain,
} from "../../style/table";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { GetMvrMoM } from "../../services/mvrMomServices";

const MoMStatus = () => {
  const [value, setValue] = useState("momstatus");
  const [isSticky, setIsSticky] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValues, setFilterValues] = useState({});
  const [isSearchTriggered, setIsSearchTriggered] = useState(Date.now());
  const [selectedRow, setSelectedRow] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const filtersValue = useSelector((state) => state.filterData);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleSearchClick = () => {
    setIsSearchTriggered(Date.now());
    setPage(0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // fetch mom status data
  const fetchData = async () => {
    try {
      let payload = filtersValue;
      payload = {...payload, 
        search: debouncedSearchTerm, 
        page: page, 
        offset: rowsPerPage
      } 

      const response = await GetMvrMoM({ payload: payload });
      if (response && response.momData) {
        setTableData(response.momData);
        setTotalCount(response.totalCount);
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching MoM data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, debouncedSearchTerm, isSearchTriggered, selectedRow]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 2000)
    return () => clearTimeout(handler);
  }, [search]);


  const filtersData = [
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "year",
      label: "Financial Year",
      type: "select",
      options: [
        { value: "2024-2025", label: "2024-2025" },
        { value: "2023-2024", label: "2023-2024" },
        { value: "2022-2023", label: "2022-2023" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "month",
      label: "Month",
      type: "select",
      options: [
        { value: "november", label: "November " },
        { value: "december", label: "December" },
      ],
    },
  ];

  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  useEffect(() => {
    if (selectedRow) {
      console.log("Selected Row Data:", selectedRow);
    }
  }, [selectedRow]);


  return (
    <>
      <TabsContain className="TabsContain">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs example"
          className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
        >
          <Tab label="MoM Status" value="momstatus" />
          <Tab label="QVR" value="QVRTabs" />
        </Tabs>
      </TabsContain>
      {value === "momstatus" && (
        <>
          <CardContain>
            <Card mt={1} className="Filter_bg">
              <Grid container spacing={1}>
                <Filter
                  filters={filtersData}
                  onFilterChange={handleFilterChange}
                ></Filter>
                <Grid item xs={12} sm={12} lg={12}>
                  <ButtonContain
                    sx={{ height: "100%", alignItems: "end", gap: "5px" }}
                  >
                    <button
                      className="SearchButton"
                      onClick={handleSearchClick}
                    >
                      Search
                    </button>
                    <button className="ResetButton">Reset</button>
                    {/* <button className="ExportButton">
                      <img src={ExcelIcon} alt="Excel icon" />
                    </button> */}
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
          <CardContain>
            <Card mt={2}>
            <ContainerCustom>
                <SmartSearchContain>
                  <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    fullWidth
                    marginleft="auto"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <SearchIcon />
                </SmartSearchContain>
              </ContainerCustom>
              <CommanTableContain>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dealer Code</TableCell>
                      <TableCell>Dealership Name</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Total MoM Points</TableCell>
                      <TableCell>Open MoM Points</TableCell>
                      <TableCell>MoM Points Completed By Dealers</TableCell>
                      <TableCell>MoM Points Closed By AM</TableCell>
                      <TableCell>Escalated To AM</TableCell>
                      <TableCell>Escalated To ZM</TableCell>
                      <TableCell>Escalated To RM</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link to="/momstatusupdate" state={{ row }}>
                            {row.dealerCode}
                          </Link>
                        </TableCell>
                        <TableCell>{row.dealerName}</TableCell>
                        <TableCell>{row.city || ""}</TableCell>{" "}
                        {/* If city is null, show 'N/A' */}
                        <TableCell>{row.totalMom}</TableCell>
                        <TableCell>{row.openMom}</TableCell>
                        <TableCell>{row.completedMom}</TableCell>
                        <TableCell>{row.closedMom}</TableCell>
                        <TableCell>{row.amEsc}</TableCell>
                        <TableCell>{row.zmEsc}</TableCell>
                        <TableCell>{row.rmEsc}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CommanTableContain>

              {/* Pagination */}
              <PaginationContain>
                <TablePagination
                  component="div"
                  count={totalCount} // Total number of items from API
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange} // Option to handle rows per page change
                />
              </PaginationContain>
            </Card>
          </CardContain>
        </>
      )}
      {value === "QVRTabs" && (
        <>
          <CardContain>
            <Card mt={1}>
              <Grid container spacing={1}>
                <Filter
                  filters={filtersData}
                  onFilterChange={handleFilterChange}
                ></Filter>
                <Grid item xs={12}>
                  <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    {/* <button className="ExportButton">Export</button> */}
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
        </>
      )}
    </>
  );
};

export default MoMStatus;
