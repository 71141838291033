import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Typography,

} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import {
  TabsContainLogicMaster,
} from '../../style/Home'
import {
  ButtonContain,
  Card,
  CardContain,
} from '../../style/Home'
import {
  CommanTableContain,
  ContainerCustom,
  PaginationContain,
  SmartSearchContain,
  HoReportRadio,
} from "../../style/table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import { Tab, Tabs } from '@mui/material';
import { LogicMasterContainer } from "../../style/HO/LogicMaster";
import { GetMvr, GetSelectedMvrChecklist, AddLogic, GetLogic, DeleteLogics } from "../../services/logicMasterService";
import { toast, ToastContainer } from "react-toastify";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import ConfirmationDialog from "../../Components/utils/Confirmation";
import DeleteIcon from '@mui/icons-material/Delete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function LogicMaster() {
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [mvrPreview, setMvrPreview] = useState(false);
  const [logicMasterName, setLogicMasterName] = useState("");
  const [logicMvrData, setLogicMvrData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mvrData, setMvrData] = useState([]);
  const [mvrId, setMvrId] = useState("");
  const [selectedMvr, setSelectedMvr] = useState("");
  const [checklistQuestion, setChecklistQuestion] = useState([])
  const [selectedParentQuestion, setSelectedParentQuestion] = useState("");
  const [selectedChildQuestion, setSelectedChildQuestion] = useState("");
  // const [selectedItems, setSelectedItems] = useState([]);
  const [value, setValue] = useState('PreviewLogic');
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [selectedId, setSelectedId] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)

  // handle delete
  const handleDelete = (id) => {
    setSelectedId(id)
    setShowConfirmation(true)
  }
  const handleCancel = () => {
      setSelectedId(null)
      setShowConfirmation(false)
    }
    const handleConfirm = async () => {
      try {
        const res = await DeleteLogics(selectedId)
        if (res) {
          toast.success(res?.message ?? 'Logic deleted successfully.');
          setLogicMvrData(logicMvrData.filter((item) => item.logicId !== selectedId));
          setTotalDataCount(totalDataCount - 1);
        }
        setShowConfirmation(false)
      } catch (error) {
        toast.error(error?.response?.data?.error ?? "Something went wrong");
      } finally {
        setSelectedId(null)
      }
    }
  
  const getMvr = async () => {
    try {
      let res = await GetMvr();
      console.log(res, "got my response")
      if (res.success) {
        const mappedData = res.mvr.map((item) => ({
          id: item.id,
          name: `${item.versionName}`,
        }));
        setMvrData(mappedData);
      }
    } catch (error) {
      console.log("Error on loading data: ", error)
    }
  }

  // get all logic added
  const getLogics = async () => {
    try{
      let payload = {
        search: "",
        page: page,
        offset: rowsPerPage,
      }
      let res = await GetLogic({payload});
      console.log(res, " got my response")
      if (res.success) {
      setLogicMvrData(res?.data?.logics);
      setTotalDataCount(res?.data?.totalCount ?? 0);
    }
    }catch(error){
      console.log("Error on loading logics data: ", error)
    }
  }

  useEffect(() => {
    getLogics();
  }, [page, rowsPerPage]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  }
  
  const handleMvr = async (event, value) => {
    let mvrId = value?.id;
    setMvrId(mvrId)
    let res = await GetSelectedMvrChecklist(mvrId);

    if (res.success) {
      const checklistQuestion = res.checklists.map((item) => ({
        id: item.checklistId,
        name: item.checklist,
        mvrId: item.mvrId,
      }))

      setChecklistQuestion(checklistQuestion);
    }

    setSelectedMvr(mvrId || "");
    // setSelectedItems([]);
  };

  const handleParentSelect = (event, value) => {
    let checkListId = value?.id;
    if (checkListId === selectedChildQuestion) {
      toast.warning("Parent and child checklists cannot be the same.");
      return;
    }
    setSelectedParentQuestion(checkListId || "");
    // setSelectedItems([]);
  };

  const handleChildSelect = (event, value) => {
    let checkListId = value?.id;
    if (checkListId === selectedParentQuestion) {
      toast.warning("Child and parent checklists cannot be the same.");
      return;
    }
    setSelectedChildQuestion(checkListId || "");
    // setSelectedItems([]);
  };

  // handle add logic
  const handleAddLogic = async () => {
    try {
      const payload = {
        mvrId: mvrId,
        logicName: logicMasterName,
        logicDescription: "",
        independentChecklistId: selectedParentQuestion,
        DependentChecklistId: selectedChildQuestion,
      }

      let res = await AddLogic({ payload });
      if(res.success){
        toast.success(res?.message ?? "Logic added successfully");
        setValue("PreviewLogic");
        setSelectedMvr("");
        setMvrData([]);
        setLogicMasterName("");
        setSelectedParentQuestion("");
        setSelectedChildQuestion("");
        await getLogics();
      }
    } catch (error) {
      console.log("Error on uploading data: ", error);
      toast.error(error?.response?.data?.error ?? error?.message);
    }
  }

  const handleChange = async (event, newValue) => {
    try{
      setValue(newValue);
      if(newValue === "PreviewLogic"){
        await getLogics();
      }else{
        await getMvr();
      }
    }catch(error){
      console.log("Error on loading logic data: ", error);
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={showConfirmation}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
      />
      <TabsContainLogicMaster>
        <Tabs value={value} onChange={handleChange} aria-label="tabs" >
          <Tab label="Add Logic" value="AddLogic" className="logic_mater_tabs" />
          <Tab label="Preview Logic" value="PreviewLogic" className="logic_mater_tabs" />
        </Tabs>
      </TabsContainLogicMaster>

      {value === "AddLogic" && (
        <>
          <LogicMasterContainer>
            <Typography variant="h4" className="head_t">
              Logic Master
            </Typography>

            <Box className="logic_btn" mt={2}>
              {/* <button onClick={toggleDropdown} className="add_logic_btn">
          Add Logic
        </button> */}
              <div className="dropdown_container">
                {/* {mvrPreview && ( */}
                <Box className="custom_dropdown">
                  <Autocomplete
                    options={mvrData}
                    getOptionLabel={(option) => option.name}
                    onChange={handleMvr}
                    value={mvrData.find((q) => q.id === selectedMvr) || null}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Mvr" variant="outlined" />
                    )}
                  />
                </Box>
              </div>
            </Box>

            <Box className="logic_btn" mt={2}>
              {selectedMvr && (
                <Box>
                  <TextField
                    label="Logic Master Name"
                    variant="outlined"
                    value={logicMasterName}
                    onChange={(e) => setLogicMasterName(e.target.value)}
                    placeholder="Enter Logic Master Name"
                    className="logic_master_name"
                  />
                </Box>
              )}
            </Box>

            <Box className="logic_btn" mt={2}>
              {/* Parent Questions */}
              <div className="dropdown_container">
                {selectedMvr && (
                  <Box className="custom_dropdown">
                    <Autocomplete
                      options={checklistQuestion || []}
                      getOptionLabel={(option) => option.name}
                      onChange={handleParentSelect}
                      value={
                        checklistQuestion.find(
                          (q) => q.id === selectedParentQuestion
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Parent Questions"
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                )}

                {/* Child Questions */}
                {selectedMvr && (
                  <Box className="custom_dropdown">
                    <Autocomplete
                      options={checklistQuestion || []}
                      getOptionLabel={(option) => option.name}
                      onChange={handleChildSelect}
                      value={
                        checklistQuestion.find(
                          (q) => q.id === selectedChildQuestion
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Child Questions"
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                )}
              </div>
            </Box>

            <Box className="logic_btn" mt={2}>
              <button className="add_logic_btn" onClick={handleAddLogic}>
                Add Logic
              </button>
            </Box>
          </LogicMasterContainer>
          <ToastContainer />
        </>
      )}
      {value === "PreviewLogic" && (
        <>
          <CardContain>
            <Card mt={2}>
              {/* <ContainerCustom>
                <SmartSearchContain>
                  <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ marginLeft: "auto" }}
                  />
                  <SearchIcon />
                </SmartSearchContain>
              </ContainerCustom> */}
              <CommanTableContain>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Logic Name</TableCell>
                      {/* <TableCell>Logic Description</TableCell> */}
                      <TableCell>Version Name</TableCell>
                      <TableCell>Independent Checklist</TableCell>
                      <TableCell>Dependent Checklist</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Created By Name</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logicMvrData.map((logicsData, index) => (
                      <TableRow key={index}>
                          <TableCell>{logicsData.logicName}</TableCell>
                          {/* <TableCell>{logicsData.logicDescription}</TableCell> */}
                          <TableCell>{logicsData.versionName}</TableCell>
                          <TableCell>{logicsData.independentChecklist}</TableCell>
                          <TableCell>{logicsData.dependentChecklist}</TableCell>
                          <TableCell>{logicsData.createdBy}</TableCell>
                          <TableCell>{logicsData.createdByName}</TableCell>
                          <TableCell>{logicsData.createdAt}</TableCell>
                          <TableCell><DeleteIcon onClick={()=>handleDelete(logicsData.logicId)} className="buttonRed"/></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CommanTableContain>

              {/* Pagination */}
              <PaginationContain>
                <TablePagination
                  component="div"
                  count={totalDataCount} // Total number of items from API
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage} // Option to handle rows per page change
                />
              </PaginationContain>
            </Card>
          </CardContain>
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default LogicMaster;
