import React, { useState } from "react";
import { ScoreHistoryModalModal } from "../../style/HO/MVRManualCreation";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalContain } from "../../style/HO/MVRManualCreation";
import { FormModal } from "../../style/formAccordian";
import {
    Typography
} from "@mui/material";

function ScoreHistoryModal({ onClose, checklistHistoryData, checklistName }) {
    const Drawer = useSelector((state) => state.drawer);
    const [clickedImage, setClickedImage] = useState(null);
    const [selectedImageTime, setSelectedImageTime] = useState(null);
    const closeImageModal = () => setClickedImage(null);
    
    return (
        <div>
            <ToastContainer />
            <ScoreHistoryModalModal className={Drawer ? " " : "DrawerOpen"}>
            {checklistHistoryData.length > 0 ? (
                checklistHistoryData.map((historyData, index) => (
                    <div className="CheckpointModal" key={index}>
                        <div className="ModalHeader">
                            <div className="score-header-Modal">
                                <h4>{historyData.checklist}</h4>
                            </div>
                            <button className="CloseButton" onClick={() => onClose && onClose()}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="ModalBody">
                            <div className="content-container" style={{ scrollBehavior: "smooth" }}>
                                <div className="score-header-Modal">
                                    <p>Received Score: {historyData.scoreReceived}</p>
                                    <div className="score-header-Modal-Right">
                                        <span>M</span>
                                        <span className="score_p">N/A</span>
                                    </div>
                                </div>
                                <div className="modal_images_group">
                                    {historyData.evidences
                                        .filter((image) => image !== null)
                                        .map((image, imgIndex) => (
                                            <img
                                                key={imgIndex}
                                                src={image.url}
                                                alt={`Image ${imgIndex + 1}`}
                                                onClick={() => {
                                                    setClickedImage(image.url);
                                                    setSelectedImageTime(image.time);
                                                }}
                                                style={{ cursor: "pointer" }}
                                            />
                                        ))}
                                </div>
                                <div className="input_group_m">
                                    <label>Remarks :</label>
                                    <textarea type="text" value={historyData.remark} disabled />
                                </div>
                                <div className="input_group_m">
                                    <label>Target Date :</label>
                                    <input type="text" value={historyData.targetDate} disabled />
                                </div>
                                <div className="input_group_m">
                                    <label>Countermeasure :</label>
                                    <textarea type="text" value={historyData.counterMeasure} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="CheckpointModal">
                    <div className="ModalHeader">
                        <div className="score-header-Modal">
                            <h4>{checklistName}</h4>
                        </div>
                        <button className="CloseButton" onClick={() => onClose && onClose()}>
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="ModalBody">
                        <p>No Checklist History Found!</p>
                    </div>
                </div>
            )}
            </ScoreHistoryModalModal>

            {/* Image Modal */}
            {clickedImage && (
                <ModalContain className={Drawer ? " " : "DrawerOpen"}
                open={clickedImage}>
                <FormModal>
                  <div className="ModalHeader">
                    <h4>Preview Image</h4>
                    <button
                      className="CloseButton"
                      onClick={closeImageModal}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="ModalBody">
                    <img
                      src={clickedImage}
                      alt="Expanded View"
                    />
                  </div>
                  <div className="box_btn_edit">
                    {selectedImageTime && (
                      <div className="ModalFooter">
                        <Typography variant="body2">{selectedImageTime}</Typography>
                      </div>
                    )}
                  </div>
                </FormModal>
      
      
              </ModalContain>
            )}
        </div>
    );
}

export default ScoreHistoryModal;
