
import React, { useState, useRef, useEffect } from "react";
import { Card, CardContain } from "../../style/Home";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button
} from "@mui/material";
import { CommanTableContainDealerMaster } from "../../style/table";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { DealerMasterModal, HierachyMasterModal } from "../../style/HO/MVRManualCreation";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { ContainerCustomDealerMaster, PaginationContain, SmartSearchContain } from '../../style/table';
import SearchIcon from "@mui/icons-material/Search";
import KeyIcon from '@mui/icons-material/Key';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { GetDealerData, UpdateDealer, ImportCsvFile } from '../../services/dealerMaster'
import "react-toastify/dist/ReactToastify.css";

// import { setFilterData } from "../../features/filterSlice";


const Dealer_Master = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [aiModalOpen, setAiModalOpen] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const Drawer = useSelector((state) => state.drawer);
    const [search, setSearch] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [loader, setLoader] = useState(false)
    const [dealerData, setDealerData] = useState([])
    const [totalCount, setTotalCount] = useState(0);
    const [modalChange, setModalChange] = useState(false)
    const [csvFile, setCsvFile] = useState(null);
    const csvInputRef = useRef(null);

    const mvrTableCols = [
        "Region",
        "Zone",
        "Area",
        "State",
        // "Network Type",
        "Status",
        "Main Dealer",
        "Main Dealer Name",
        // "Network Code",
        // "Network Name",
        "City",
        "Contact No.",
        "Email",
        "LOI Holder Name",
        "LOI Profile Photo",
        "LOI Issued Date",
        "Edit",
    ];
    const getDealerData = async () => {
        try {
            setLoader(true)

            const payload = {
                page: page,
                offset: rowsPerPage,
            }
            const res = await GetDealerData({ payload })
            if (res.success) {
                setDealerData(res?.data?.dealersData);
                console.log(res?.data?.dealersData);
                setTotalCount(res?.data?.totalDataCount);
            }
        } catch (error) {
            console.log("error", error)
            if (error.code === "ERR_NETWORK") {
                toast.error("Network error")
            }
        } finally {
            setLoader(false)
        }
    }



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    }

    const handleEditClick = (row) => {
        console.log(row);
        setSelectedRow(row);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedRow(null);
    };
    const handleAiModalOpen = () => setAiModalOpen(true);
    const handleAiModalClose = () => setAiModalOpen(false);
    const fileInputRef = useRef();
    const handleUploadModalOpen = () => setUploadModalOpen(true);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result); // Set the image for the modal preview
                setUploadModalOpen(true); // Open the modal
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    const handleUpdatePreview = () => {
        setPreviewImage(selectedImage); // Update the table preview with the selected image
        setUploadModalOpen(false); // Close the modal
    };

    const triggerFileInput = () => {
        fileInputRef.current.click(); // Trigger the file input click
    };
    const triggerCsvInput = () => {
        fileInputRef.current.click(); // Trigger the file input click
    };



    useEffect(() => {
        getDealerData();
    }, [rowsPerPage, page])


    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                id: selectedRow?.id,
                phoneNumber: selectedRow?.phoneNumber,
                emailId: selectedRow?.emailId,
                state: selectedRow?.state,
                pincode: selectedRow?.pincode,
                region: selectedRow?.region,
                zone: selectedRow?.zone,
                area: selectedRow?.area,
                status: selectedRow?.status,
                loiHolderName: selectedRow?.loiHolderName,
                address: selectedRow?.address,
            }

            const res = await UpdateDealer({ payload: payload })
            if (res.success) {
                console.log(res);

                setDealerData((prevData) =>
                    prevData.map((row) => {
                        return row.id === selectedRow.id
                            ? { ...row, ...payload }
                            : row
                    })
                );
                console.log(payload);
                setModalOpen(false);
                toast.success("Updated Successfully");
            }
        }
        catch (error) {
            console.log(error);
        }
    }



    const handleCsvChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            toast.error("Please selecte a file");
            return;
        }

        setLoader(true);

        const formData = new FormData();
        formData.append("dealers", file);

        try {
            setLoader(true)
            const res = await ImportCsvFile({ payload: formData })
            if (res && res.success) {
                toast.success("Upload Successfully");
            }
        }
        catch (error) {
            console.log("error: ", error);
            if (error.code === "ERR_BAD_RESPONSE") {
                toast.error(error?.response?.data?.error);
            }
            else {
                toast.error(error?.response?.data?.error)
            }
        } finally {
            setTimeout(() => {
                setLoader(false);
            }, 1000)
        }
    };


    return (
        <>
            <CardContain>
                <Card mt={2}>
                    <ContainerCustomDealerMaster>
                        <Button
                            variant="contained"
                            disabled={loader}
                            onClick={() => {
                                // Reset the file input before triggering the file selection dialog
                                csvInputRef.current.value = ""; // Reset input value
                                csvInputRef.current.click();
                            }}
                        >
                            {loader ? "Importing..." : "Import"}
                        </Button>
                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={csvInputRef}
                            accept=".csv, .xls, .xlsx"
                            onChange={handleCsvChange}
                        />
                    </ContainerCustomDealerMaster>

                    <CommanTableContainDealerMaster>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {mvrTableCols.map((text, index) => (
                                        <TableCell key={index}>{text}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dealerData?.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.region}</TableCell>
                                        <TableCell>{row.zone}</TableCell>
                                        <TableCell>{row.area}</TableCell>
                                        <TableCell>{row.state}</TableCell>
                                        {/* <TableCell></TableCell> */}
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.dealerCode}</TableCell>
                                        <TableCell>{row.dealerName}</TableCell>
                                        {/* <TableCell></TableCell>
                                        <TableCell></TableCell> */}
                                        <TableCell>{row.city}</TableCell>
                                        <TableCell>{row.phoneNumber}</TableCell>
                                        <TableCell style={{ textTransform: "lowercase" }}>{row.emailId}</TableCell>
                                        <TableCell>{row.loiHolderName}</TableCell>
                                        <TableCell style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "center", minHeight: "68px" }}>
                                            {previewImage && (
                                                <img
                                                    src={previewImage}
                                                    alt="Preview"
                                                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                                />
                                            )}
                                            <CloudUploadIcon
                                                onClick={triggerFileInput}
                                                style={{ cursor: "pointer" }}
                                            />
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: "none" }}
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />

                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <EditIcon onClick={() => handleEditClick(row)} style={{ cursor: "pointer" }} />
                                            <KeyIcon onClick={handleAiModalOpen} style={{ cursor: "pointer" }} />
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </CommanTableContainDealerMaster>
                </Card>
            </CardContain>
            <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {modalOpen && (
                <HierachyMasterModal className={Drawer ? "" : "DrawerOpen"}>
                    <div className="modal_container_box">
                        <div className="ModalHeader">
                            <h4>Update Data</h4>
                            <button className="CloseButton" onClick={handleModalClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="ModalBody">
                            <div className="input_form_group">
                                <label htmlFor="">Main Dealer Code</label>
                                <input value={selectedRow?.dealerCode || ""} disabled />
                                {/* //onChange={(e) => setSelectedRow({ ...selectedRow, dealer_code: e.target.value })} */}
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Main Dealer Name</label>
                                <input value={selectedRow?.dealerName || ""} disabled />
                            </div>
                            {/* onChange={(e) => setSelectedRow({ ...selectedRow, dealer_name: e.target.value })}  */}
                            {/* <div className="input_form_group">
                                <label htmlFor="">Network Type</label>
                                <input value={selectedRow?.networkType || ""} onChange={(e) => setSelectedRow({ ...selectedRow, networkType: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Network Code</label>
                                <input value={selectedRow?.networkCode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, networkCode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Network Name</label>
                                <input value={selectedRow?.networkName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, networkName: e.target.value })} />
                            </div> */}
                            <div className="input_form_group">
                                <label htmlFor="">Contact No.</label>
                                <input value={selectedRow?.phoneNumber || ""} onChange={(e) => setSelectedRow({ ...selectedRow, phoneNumber: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Email ID</label>
                                <input value={selectedRow?.emailId || ""} onChange={(e) => setSelectedRow({ ...selectedRow, emailId: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">State</label>
                                <input value={selectedRow?.state || ""} onChange={(e) => setSelectedRow({ ...selectedRow, state: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Pin Code</label>
                                <input value={selectedRow?.pincode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, pincode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Region</label>
                                <input value={selectedRow?.region || ""} onChange={(e) => setSelectedRow({ ...selectedRow, region: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Zone</label>
                                <input value={selectedRow?.zone || ""} onChange={(e) => setSelectedRow({ ...selectedRow, zone: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Area</label>
                                <input value={selectedRow?.area || ""} onChange={(e) => setSelectedRow({ ...selectedRow, area: e.target.value })} />
                            </div>
                            {/* <div className="input_form_group">
                                <label htmlFor="">Location</label>
                                <input value={selectedRow?.location || ""} onChange={(e) => setSelectedRow({ ...selectedRow, operation: e.target.value })} />
                            </div> */}
                            <div className="input_form_group">
                                <label htmlFor="">Status</label>
                                <input value={selectedRow?.status || ""} onChange={(e) => setSelectedRow({ ...selectedRow, operation: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">LOI Holder Name</label>
                                <input value={selectedRow?.loiHolderName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, loiHolderName: e.target.value })} />
                            </div>
                            {/* <div className="input_form_group">
                                <label htmlFor="">LOI Issue Date</label>
                                <input value={selectedRow?.loiIssueDate || ""} onChange={(e) => setSelectedRow({ ...selectedRow, loiIssueDate: e.target.value })} />
                            </div> */}
                            <div className="input_form_group">
                                <label htmlFor="">Address</label>
                                <textarea value={selectedRow?.address || ""} onChange={(e) => setSelectedRow({ ...selectedRow, address: e.target.value })} />
                            </div>
                            {/* <div className="input_form_group">
                                <label htmlFor="">Created Date</label>
                                <input placeholder="02-01-2025" type="text" />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Created By</label>
                                <input type="text" />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Update Date</label>
                                <input placeholder="No Update" type="text" />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Update By</label>
                                <input placeholder="No Update" type="text" />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Remark</label>
                                <input placeholder="Remark" type="text" />
                            </div> */}
                        </div>
                        <div className="ModalFooter">
                            <div className="input_form_group modal_btn_group">
                                <button onClick={(e) => handleSave(e)} className="save_btn">Save</button>
                                <button className="cancel_btn" onClick={handleModalClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </HierachyMasterModal>
            )}

            {aiModalOpen && (
                <DealerMasterModal className={Drawer ? "" : "DrawerOpen"}>
                    <div className="modal_container_box">
                        <div className="ModalHeader">
                            <h4>Password Update</h4>
                            <button className="CloseButton" onClick={handleAiModalClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="ModalBody">
                            <div className="password_input_form">
                                <div className="input_form_group">
                                    <label htmlFor="">Dealer Code <span>*</span></label>
                                    <input value={selectedRow?.dealerCode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, dealerCode: e.target.value })} />
                                </div>
                                <div className="input_form_group">
                                    <label htmlFor="">Dealer Name <span>*</span></label>
                                    <input value={selectedRow?.dealerName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, dealerName: e.target.value })} />
                                </div>
                                <div className="input_form_group">
                                    <label htmlFor="">Location <span>*</span></label>
                                    <input value={selectedRow?.location || ""} onChange={(e) => setSelectedRow({ ...selectedRow, location: e.target.value })} />
                                </div>
                                <div className="input_form_group">
                                    <label htmlFor="">Password <span>*</span></label>
                                    <input value={selectedRow?.password || ""} onChange={(e) => setSelectedRow({ ...selectedRow, password: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="ModalFooter_1">
                            <div className="input_form_group modal_btn_group">
                                <button className="cancel_btn" onClick={handleAiModalClose}>Cancel</button>
                                <button className="update_btn">Update</button>
                            </div>
                        </div>
                    </div>
                </DealerMasterModal>
            )}

            {uploadModalOpen && (
                <DealerMasterModal className={Drawer ? "" : "DrawerOpen"}>
                    <div className="modal_container_box">
                        <div className="ModalHeader">
                            <h4>Preview Image</h4>
                            <button className="CloseButton" onClick={handleUploadModalClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="ModalBody">
                            {selectedImage && (
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                        marginTop: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                    }}
                                />
                            )}
                        </div>
                        <div className="ModalFooter_1">
                            <div className="input_form_group modal_btn_group">
                                <button className="cancel_btn" onClick={handleUploadModalClose}>
                                    Cancel
                                </button>
                                <button
                                    className="update_btn"
                                    onClick={handleUpdatePreview}
                                    disabled={!selectedImage} // Disable if no image selected
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </DealerMasterModal>
            )}

            <ToastContainer />
        </>
    );
};

export default Dealer_Master;
