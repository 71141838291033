import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardContain, TableContain } from "../style/Home";
import "../css/MVR_ScoreSheet.css";
import { GetMvrScoreCard } from "../services/mvrScoreCard";
import PrintIcon from "@mui/icons-material/Print";
import html2pdf from 'html2pdf.js';
import {GetMoM} from '../services/momService';


function MVR_ScoreCard() {
  const location = useLocation();
  const row = location.state?.row;
  const [data, setData] = useState([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [grandParameterTotal, setGrandParameterTotal] = useState(0);
  const [grandReceivedTotalScore, setGrandReceivedTotalScore] = useState(0);
  const [momPoints, setMoMPoints] = useState([]);
  let mvrData = {
    dealerCode: row.dealer_code,
    financialYear: row.financialYear,
    period: row.period,
  }

  const getScoreCardData = async () => {
    try {
      const response = await GetMvrScoreCard(row);
      setGrandParameterTotal(response?.grandParameterTotal ?? 0);
      setGrandReceivedTotalScore(response?.grandReceivedTotalScore ?? 0);
      if (response?.groupedArray) {
        setData(response.groupedArray);
      }
    } catch (error) {
      console.log("Error on fetching data: ", error);
    }
  };

  const getMoM = async () => {
    try{
      if (mvrData && mvrData.dealerCode) {
        const payload = { page: 0, offset: 250 }
        const res = await GetMoM(mvrData, payload)
        if (res.success) {
          setMoMPoints(res?.data?.moms || [])
        }
      }
    }catch(error){
      console.log('Error on loading data: ', error);
    }
  }

  useEffect(() => {
    getScoreCardData();
    getMoM();
  }, []);

  const downloadPDF = () => {
    const content = document.querySelector(".MVR_ScoreCard");
    if (!content) {
         console.error("Content container not found");
         return;
       }
       setIsGeneratingPDF(true); 
   
       html2pdf()
         .from(content)
         .set({
           margin: 10,
           filename: "parameter-table.pdf",
           html2canvas: {
             scale: 2,
             logging: false,
             useCORS: true,
           },
           jsPDF: {
             unit: "mm",
             format: "a4",
             orientation: "portrait",
           },
         })
         .save()
         .finally(() => {
           setIsGeneratingPDF(false); 
         });
     };

  return (
    <>
      <div className="MVR_ScoreCard">
        <div className="table_contain_box">
          <CardContain mt={2}>
            <TableContain>
              <table className="table">
                <thead>
                  <tr className="TableTitle">
                    <th colSpan={8} className="TableTitle_th">
                      <h4>Scorecard</h4>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>Dealership Name:</th>
                    <th colSpan={4}>{row.dealership_name}</th>
                  </tr>
                  <tr>
                    <th colSpan={2}>Dealership Code:</th>
                    <th colSpan={4}>{row.dealer_code}</th>
                  </tr>
                  <tr>
                    <th colSpan={2}>Evaluator Name:</th>
                    <th colSpan={4}>{row.evaluatorName}</th>
                  </tr>
                </thead>
              </table>
            </TableContain>
          </CardContain>
        </div>
        <div className="table_contain_box mb_table">
          <CardContain mt={3}>
            <TableContain>
              <table className="table">
                <thead>
                  <tr className="TableTitle">
                    <th colSpan={8} className="TableTitle_th TableTitle_th_mom">
                      <h4>Parameter Score</h4>
                    </th>
                  </tr>
                  <tr>
                    <th className="mvr_th_card" colSpan={1}>
                      Parameter
                    </th>
                    <th className="mvr_th_card" colSpan={3}>
                      Sub Parameter
                    </th>
                    <th className="mvr_th_card">Max Score</th>
                    <th className="mvr_th_card">Actual Score</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((group, index) => (
                    <React.Fragment key={index}>
                      {group.checklists.map((item, index) => (
                        <tr key={index}>
                          {!index && (
                            <td rowSpan={group.checklists.length}>
                              {group.parameter}
                            </td>
                          )}
                          <td colSpan={3}>{item.checklist}</td>
                          <td>{item.checklistTotal}</td>
                          <td>{item.na ? "N/A" : item.receivedScore === null ? "_" : item.receivedScore}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} className="tb_header_bg"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tb_header_bg"
                          style={{
                            fontWeight: "500",
                          }}
                        >{group.parameterTotal}</td>
                        <td className="tb_header_bg"
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {group.totalReceivedScore}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr
                    style={{
                      backgroundColor: "#929292"
                    }}
                  >
                        <td colSpan={4}
                        style={{
                          fontWeight: "bold",
                          color: "#fff"
                        }}
                        >
                          Grand Total
                        </td>
                        <td
                          style={{
                            fontWeight: "500",
                            color: "#fff"
                          }}
                        >{grandParameterTotal}</td>
                        <td
                          style={{
                            fontWeight: "500",
                            color: "#fff"
                          }}
                        >{grandReceivedTotalScore}</td>
                      </tr>
                </tbody>
              </table>
            </TableContain>
            
          </CardContain>

          {/* MoM Table data */}
          <CardContain mt={3}>
            <TableContain>
              <table className="table">
                  <thead>
                    <tr className="TableTitle">
                      <th colSpan={5} className="TableTitle_th TableTitle_th_mom">
                        <h4>Mom Points</h4>
                      </th>
                    </tr>

                    <tr>
                      <th className="mvr_th_card">S.NO</th>
                      <th className="mvr_th_card">Parameter</th>
                      <th className="mvr_th_card">Discussion/Concern Points</th>
                      <th className="mvr_th_card">Countermeasure Plan	</th>
                      <th className="mvr_th_card">Target Date</th>
                    </tr>
                  </thead>

                  <tbody>
                  {momPoints?.length > 0 ? (
                      momPoints.map((momPointsData, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{momPointsData.parameter}</td>
                        <td>{momPointsData.checklist}</td>
                        <td>{momPointsData.counterMeasure}</td>
                        <td>{momPointsData.targetDate}</td>
                      </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} align="center">
                          No MoM points available
                        </td>
                      </tr>
                    )}
                  </tbody>
              </table>
            </TableContain>
          </CardContain>
        </div>
      </div>
      <div className="Mom_table_evaluation_btn">
              <button
                className="print"
                onClick={downloadPDF}
                disabled={isGeneratingPDF}
              >
                <PrintIcon />
              </button>
            </div>
    </>
  );
}

export default MVR_ScoreCard;
