import React, { useEffect, useState } from "react";
import { ScheduleVisitContain } from "../style/AM/ScheduleVisit";
import { Card, CardContain } from "../style/Home";
import { useLocation, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CommanTableContainMomPoint } from "../style/table";
import TextField from "@mui/material/TextField";
import { Loader } from "../utils/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/MVRMom_Form.css";
import { AddMoM, DeleteMoM, GetMoM, GetValidateData, MoMValidation, UpdateMoMCheckList, UpdateMoMCounterMeasure, UpdateMoMTargetDate, VarianceValidation } from "../services/momService";
import arrow from "../assets/images/icons/right-arrow.png"
import { MvrEvaluationData } from "../services/mvrFormFill";
import Modal from "../Components/Modal/Modal";
import SubmissionModal from "../Components/Modal/Modal";
import ConfirmationDialog from "../Components/utils/Confirmation";


const MVRMom_Form = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const mvrData = location.state?.mvrData
  console.log(mvrData, " got my mvr data");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentAction, setCurrentAction] = useState({});
  const [momValidation, setMoMValidation] = useState(false)
  const [checklistValidate, setChecklistValidate] = useState(false)
  const [varianceValidationData, setVarianceValidation] = useState(null)
  const [modalOpen, setModal] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedId, setSelectedId] = useState(null)



  // Default row data
  const [momPoints, setMoMPoints] = useState([]);

  const getMoM = async () => {
    try {
      setLoader(true)
      if (mvrData && mvrData.dealerCode) {
        const payload = { "page": page, "offset": rowsPerPage }
        const res = await GetMoM(mvrData, payload)
        if (res.success) {
          setMoMPoints(res?.data?.moms || [])
          setTotalCount(res.data.totalCount)
        }

      }
    } catch (error) {
      console.log('error: ', error);

    } finally {
      setLoader(false)
    }
  }

  const getMvrEvolutions = async () => {
    try {
      if (mvrData) {
        // const res = MvrEvaluationData(mvrData)
      }

    } catch (error) {
      console.log('error: ', error);

    }
  }

  useEffect(() => {
    getMvrEvolutions()
  }, [])


  useEffect(() => {
    getMoM()
  }, [mvrData, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddRow = async () => {
    if (mvrData && mvrData.dealerCode) {
      setLoader(true)
      try {
        const res = await AddMoM(mvrData)
        if (res.success) {
          setMoMPoints([
            ...momPoints,
            {
              id: res.id,
              parameter: "Through Discussion",
              discussion: "",
              plan: "",
              src: "manual",
              editable: true,
              // targetDate: dayjs()
            }
          ]);
          setTotalCount(totalCount + 1)
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? 'Error while adding MoM.');
      } finally {
        setLoader(false)
      }
    } else {
      toast.error('Error while adding MoM.')
    }
  };




  const handleDeleteRow = async (id) => {
    if (id) {
      setSelectedId(id)
      setShowConfirmation(true)
    }
  };

  const handleRowChange = (field, e, index) => {
    const value = e.target.value
    const updatedMoMPoints = momPoints.map((row, idx) =>
      idx === index ? { ...row, [field]: value } : row
    );
    setMoMPoints(updatedMoMPoints);
  };

  const mvrTableCols = [
    "S.no.",
    "Parameter",
    "Discussion/Concern Points",
    "Countermeasure Plan",
    "Target date",
    "Action"
  ];


  const validateForm = async (action, value) => {
    setCurrentAction({ action, value })
    try {
      if (mvrData?.dealerCode) {
        const res = await GetValidateData(mvrData?.financialYear, mvrData?.period, mvrData?.dealerCode)
        if (res.success) {
          if (res.validationMessage) {
            toast.warning(res.validationMessage)
          } else {
            setChecklistValidate(true)
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    }
  }

  const validateMoM = async () => {
    try {
      if (mvrData.dealerCode) {
        const momValidationData = await MoMValidation(mvrData?.financialYear, mvrData?.period, mvrData.dealerCode)
        if (momValidationData.success) {
          if (momValidationData.validationsPending) {
            const momValidData = momValidationData.validationsPending
            if (!momValidData.emptyChecklist && !momValidData.emptyCounterMeasure && !momValidData.emptyTargetDate) {
              setMoMValidation(true)
              return
            } else {
              const errorParts = [];
              if (momValidData.emptyChecklist > 0) {
                errorParts.push(`${momValidData.emptyChecklist} checklist`);
              }
              if (momValidData.emptyCounterMeasure > 0) {
                errorParts.push(`${momValidData.emptyCounterMeasure} countermeasure`);
              }
              if (momValidData.emptyTargetDate > 0) {
                errorParts.push(`${momValidData.emptyTargetDate} target date`);
              }

              let errorMsg =
                errorParts.length > 1
                  ? errorParts.slice(0, -1).join(", ") + " and " + errorParts.slice(-1)
                  : errorParts[0];

              if (errorMsg) {
                setMoMValidation(false)
                setChecklistValidate(false)
                errorMsg = `${errorMsg} is/are pending.`
                toast.error(errorMsg)
              }
            }

          } else {
            setMoMValidation(true)
          }
        }
      }
    } catch (error) {
      console.log('error: ', error);

    }
  }

  const varianceValidate = async () => {
    try {
      if (mvrData?.dealerCode) {
        const varianceValidationData = await VarianceValidation(mvrData?.financialYear, mvrData?.period, mvrData?.dealerCode)
        if (varianceValidationData.success) {
          setVarianceValidation(varianceValidationData)
          setModal(true)
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    }
  }

  useEffect(() => {
    if (checklistValidate) {
      validateMoM()
    }
  }, [checklistValidate])

  useEffect(() => {
    if (momValidation) {
      varianceValidate()
    }
  }, [momValidation])

  const saveCheckList = async (index) => {
    setLoader(true)
    try {
      const currentCheckList = momPoints?.[index] || null
      if (currentCheckList) {
        const payload = {
          "src": currentCheckList?.src,
          "checklist": currentCheckList?.checklist
        }
        await UpdateMoMCheckList(currentCheckList?.id, payload)
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setLoader(false)
    }
  }


  const saveCounterMeasure = async (index) => {
    setLoader(true)
    try {
      const currentCheckList = momPoints?.[index] || null
      if (currentCheckList) {
        const payload = {
          "src": currentCheckList?.src,
          "counterMeasure": currentCheckList?.counterMeasure
        }
        await UpdateMoMCounterMeasure(currentCheckList?.id, payload)
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? error?.message);
    } finally {
      setLoader(false)
    }
  }

  const saveTargetDate = async (index) => {
    setLoader(true)

  }

  const handleDateChanges = async (value, index) => {
    if (index >= 0 && index < momPoints.length) {
      const updatedMomPoints = [...momPoints];
      updatedMomPoints[index].targetDate = value;
      setMoMPoints(updatedMomPoints);
      try {
        const currentCheckList = momPoints?.[index] || null
        if (currentCheckList) {
          const payload = {
            "src": currentCheckList?.src,
            "targetDate": currentCheckList?.targetDate
          }
          await UpdateMoMTargetDate(currentCheckList?.id, payload)
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? error?.message);
      } finally {
        setLoader(false)
      }
    }
  };

  const closeModal = () => {
    setMoMValidation(false)
    setChecklistValidate(false)
    setModal(false)
  }

  const handleBack = () => {
    navigate(-1);
  }

  const handleCancel = () => {
    setSelectedId(null)
    setShowConfirmation(false)
  }

  const handleConfirm = async () => {
    if (selectedId) {
      setLoader(true)
      try {
        const res = await DeleteMoM(selectedId)
        if (res.success) {
          toast.success('MoM deleted successfully.')
          setMoMPoints(momPoints.filter((row) => row.id !== selectedId));
          setTotalCount(totalCount - 1)
        }
      } catch (error) {
        toast.error(error?.response?.data?.error ?? error?.message);
      } finally {
        setSelectedId(null)
        setLoader(false)
        setShowConfirmation(false)
      }
    }

  }



  return (
    <>
      {loader && <Loader fullScreen={true} />}
      <ScheduleVisitContain>
        <CardContain>
          <Card mt={2}>

            {Boolean(mvrData?.amCanSubmit || false) && (
              <button className="Pointer add_mom_btn" onClick={handleAddRow}>
                Add MoM
              </button>
            )}

            <CommanTableContainMomPoint className=" ">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} className="mom_points_h">
                      MoM Points
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    {mvrTableCols.map((text, index) => (
                      <TableCell key={index}>
                          {text}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {momPoints?.length > 0 ? (
                    momPoints.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            className="mom_input_u"
                            disabled={true}
                            value={row.parameter}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            type="text"
                            disabled={!row.editable || row.src !== 'manual'}
                            className="mom_input_u"
                            value={row.checklist || ""}
                            onChange={(e) => handleRowChange("checklist", e, index)}
                            onBlur={() => saveCheckList(index)}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            disabled={!row.editable && row.src !== 'manual'}
                            value={row.counterMeasure || ""}
                            onChange={(e) => handleRowChange("counterMeasure", e, index)}
                            onBlur={() => saveCounterMeasure(index)}
                            className="text_area_mom"
                          />
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                disabled={!row.editable && row.src !== 'manual'}
                                value={row.targetDate ? dayjs(row.targetDate, "YYYY-MM-DD") : null}
                                minDate={dayjs()}
                                format="DD-MM-YYYY"
                                onBlur={() => saveTargetDate(index)}
                                onChange={(e) =>
                                  handleDateChanges(e.format("YYYY-MM-DD"), index)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select a date"
                                    InputProps={{
                                      readOnly: false,
                                    }}
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        {row.src === "manual" && row.editable && (
                          <TableCell>
                            <button
                              className="Pointer delete_row_btn"
                              onClick={() => handleDeleteRow(row.id)}
                            >
                              Delete
                            </button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No MoM points available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </CommanTableContainMomPoint>
          </Card>
        </CardContain>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ScheduleVisitContain>
      {(varianceValidationData && modalOpen) && (
        <SubmissionModal
          action={currentAction}
          mvrData={mvrData}
          varianceData={varianceValidationData}
          onClose={closeModal}
        />
      )}
      <ToastContainer />
      <div className="Mom_table_evaluation_btn">
        <button onClick={handleBack}> &lt;&lt; Back to Evaluation</button>

        {Boolean(mvrData?.amCanSubmit || false) && (
          <button onClick={() => validateForm('Submission', "submitted by am")}>Submit</button>
        )}

        {Boolean(mvrData?.dealerCanSubmit || false) && (
          <button onClick={() => validateForm('Submission', 'submitted by amd')}>Submit</button>
        )}

        {Boolean(mvrData?.dealerCanAccept || false) && (
          <button onClick={() => validateForm('Acceptance', 'accepted')}>Accept</button>
        )}

        {Boolean(mvrData?.dealerCanReconsider || false) && (
          <button onClick={() => validateForm('Reconsideration', 'reconsidered')}>Reconsider</button>
        )}

        {/* <button onClick={validateForm}>Validate</button> */}
      </div>
      <ConfirmationDialog
        open={showConfirmation}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default MVRMom_Form;
