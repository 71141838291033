import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { FilterField } from "../../style/filter";
import { GetDropdownData } from "../../services/scheduleVisit";
import { useDispatch, useSelector } from 'react-redux';
import { setDropdownData } from '../../features/filterParameterSlice';
import { setFilteredDropdownData, updateDropDown } from "../../features/filteredDropdownSlice";
import { updateFilterData } from "../../features/filterSlice";
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import { useLocation } from "react-router-dom";
import { keyBy } from "lodash";


const Filter = ({ onFilterChange, reset, showFinancialYear=true, showPeriod=true }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dropdownData = useSelector((state) => state.filterParameter);
  const filteredDropdown = useSelector((state) => state.filteredDropdown);
  const filters = useSelector((state) => state.filterData);
  const [searchValue, setSearchValue] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const dropdownRefs = useRef({});

  useEffect(() => {
    if (selectedValues) {
      const key = Object.keys(selectedValues);
      const updatedData = {
        regions: selectedValues?.regions?.map(region => region.value) || [],
        zones: selectedValues?.zones?.map(zone => zone.value) || [],
        dealerCodes: selectedValues?.dealerCodes?.map(dealerCodes => dealerCodes.value) || [],
        areas: selectedValues?.areas?.map(area => area.value) || [],
      };

      if (key.includes('financialYear')) {
        updatedData["financialYear"] = selectedValues.financialYear || getCurrentFinancialYear()
      }
      if (key.includes('period')) {
        updatedData["period"] = selectedValues.period || getCurrentMonth()
      }
      dispatch(updateFilterData(updatedData));
    }
  }, [selectedValues, dispatch]);


  const getDropdownData = async () => {
    try {
      const res = await GetDropdownData()
      if (res.success) {
        dispatch(setDropdownData(res));
        dispatch(setFilteredDropdownData(res))
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  useEffect(() => {
    if (!dropdownData || !dropdownData.success) {
      getDropdownData();
    }
  }, [])

  useEffect(() => {
    if (filters?.regions?.length) {
      const zones = dropdownData.zones
      const filteredZones = zones.filter(zone => filters?.regions.includes(zone.region))
      dispatch(updateDropDown({ key: "zones", value: filteredZones }));
    } else {
      dispatch(updateDropDown({ key: "zones", value: dropdownData.zones }));
    }

  }, [JSON.stringify(filters?.regions), JSON.stringify(filteredDropdown.regions)])

  useEffect(() => {
    if (filters?.zones?.length) {
      const areas = dropdownData.areas
      const filterData = [...filters?.zones]
      const filteredAreas = areas.filter(area => filterData.includes(area.zone));
      dispatch(updateDropDown({ key: "areas", value: filteredAreas }));

    } else if (filters?.regions?.length) {
      const areas = dropdownData.areas
      const filterData = [...filters.regions]
      const filteredAreas = areas.filter(area => filterData.includes(area.region));
      dispatch(updateDropDown({ key: "areas", value: filteredAreas }));
    } else {
      dispatch(updateDropDown({ key: "areas", value: dropdownData.areas }));
    }

  }, [JSON.stringify(filters?.zones), JSON.stringify(filteredDropdown.zones)])


  useEffect(() => {
    if (filters?.areas?.length) {
      const dealers = dropdownData.dealerCodeName || []
      const filterData = [...filters.areas]
      const filteredDealer = dealers.filter(dealer => filterData.includes(dealer.area));
      dispatch(updateDropDown({ key: "dealerCodeName", value: filteredDealer }));
    } else if (filters?.zones?.length) {
      const dealers = dropdownData.dealerCodeName || []
      const filterData = [...filters?.zones]
      const filteredDealer = dealers.filter(dealer => filterData.includes(dealer.zone));
      dispatch(updateDropDown({ key: "dealerCodeName", value: filteredDealer }));

    } else if (filters?.regions?.length) {
      const dealers = dropdownData.dealerCodeName || []
      const filterData = [...filters?.regions]
      const filteredDealer = dealers.filter(dealer => filterData.includes(dealer.region));
      dispatch(updateDropDown({ key: "dealerCodeName", value: filteredDealer }));
    } else {
      dispatch(updateDropDown({ key: "dealerCodeName", value: dropdownData.dealerCodeName }));
    }

  }, [JSON.stringify(filters?.areas), JSON.stringify(filteredDropdown.areas)])



  useEffect(() => {
    if (reset) {
      resetFilter()
    }
  }, [reset])

  const filtersData = [
    {
      xs: 6, sm: 4, lg: 2,
      key: 'regions',
      label: 'Region',
      type: 'select',
      multipleSelect: true,
      options: filteredDropdown.regions || [],
      show: true, // Display this filter
      disable: filteredDropdown?.regions?.length === 1,
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'zones',
      label: 'Zone',
      type: 'select',
      multipleSelect: true,
      options: filteredDropdown.zones || [],
      show: true,
      disable: filteredDropdown?.zones?.length === 1,
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'areas',
      label: 'Area',
      type: 'select',
      multipleSelect: true,
      options: filteredDropdown.areas || [],
      show: true,
      disable: filteredDropdown.areas?.length === 1,
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'financialYear',
      label: 'Financial Year',
      type: 'select',
      options: filteredDropdown.financialYears || [],
      searchable: false,
      show: showFinancialYear,
      // show: excludeFilterRoutes.includes(location.pathname) ? false : true,

    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'period',
      label: 'Period',
      type: 'select',
      options: filteredDropdown.periods || [],
      searchable: false,
      show: showPeriod,
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'dealerCodes',
      label: 'Dealer Code Name',
      type: 'select',
      multipleSelect: true,
      options: filteredDropdown.dealerCodeName || [],
      show: true,
      disable: filteredDropdown.dealerCodeName?.length === 1,
    },
  ];

  const resetFilter = () => {
    setSearchValue({})
    setDropdownOpen({})
    setSelectedValues({})
    filtersData.forEach((filter) => onFilterChange(filter.key, filter.multipleSelect ? [] : "null"))
  }

  const handleSearchChange = (e, filterKey) => {
    setSearchValue((prev) => ({
      ...prev,
      [filterKey]: e.target.value,
    }));
    setDropdownOpen((prev) => ({ ...prev, [filterKey]: true }));
  };

  const handleOptionSelect = (filterKey, value, label) => {
    setDropdownOpen((prev) => ({ ...prev, [filterKey]: false }));
    setSelectedValues((prev) => {
      setTimeout(() => {
        onFilterChange(filterKey, value);
      }, 0);
      return { ...prev, [filterKey]: value };
    });


  };

  const handleCheckboxChange = (filterKey, value, label) => {
    setSelectedValues((prev) => {
      const existingValues = prev[filterKey] || [];
      const isAlreadySelected = existingValues.some((item) => item.value === value);

      const updatedValues = isAlreadySelected
        ? existingValues.filter((item) => item.value !== value)
        : [...existingValues, { value, label }];

      setTimeout(() => {
        onFilterChange(filterKey, updatedValues.map((item) => item.value));
      }, 0);

      return { ...prev, [filterKey]: updatedValues };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRefs.current).forEach((key) => {
        if (
          dropdownRefs.current[key] &&
          !dropdownRefs.current[key].contains(event.target)
        ) {
          setDropdownOpen((prev) => ({ ...prev, [key]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const getPlaceHolder = (key) => {
    if (key) {
      const data = selectedValues[key];
      if (data?.length) {
        const values = data.map(item => item.label);
        const length = values.length;

        if (length > 1) {
          const firstValue = values[0];
          const remaining = length - 1;
          return `${firstValue} + ${remaining}`;
        } else {
          return values[0];
        }
      }
    }
    return "Search";
  }


  return (
    <>
      {filtersData
        .filter(filter => filter.show)
        .map((filter, index) => (
          <Grid
            item
            xs={filter.xs || 6}
            sm={filter.sm || 4}
            lg={filter.lg || 2}
            key={index}
          >
            <FilterField>
              <label>{filter.label}</label>
              {filter.disable ? (
                <input
                  type="text"
                  value={filter?.options[0]?.label || ""}
                  disabled={filter.disable}
                  readOnly
                />
              ) : (
                <div
                  style={{ position: "relative" }}
                  ref={(el) => (dropdownRefs.current[filter.key] = el)}
                >
                  {/* Render dropdown without search for financialYear */}
                  {filter.searchable === false ? (
                    <select
                      value={selectedValues[filter.key] || ""}
                      onChange={(e) =>
                        handleOptionSelect(filter.key, e.target.value, e.target.selectedOptions[0].label)
                      }
                    >
                      <option value="">{filter.label}</option>
                      {filter.options.map((option, optIndex) => (
                        <option key={optIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="SearchInput"
                      type="text"
                      placeholder={getPlaceHolder(filter.key)}
                      value={
                        filter.multipleSelect
                          ? searchValue[filter.key] || ""
                          : searchValue[filter.key] || ""
                      }
                      onChange={(e) => handleSearchChange(e, filter.key)}
                      onFocus={() =>
                        setDropdownOpen((prev) => ({ ...prev, [filter.key]: true }))
                      }
                    />
                  )}
                  {isDropdownOpen[filter.key] && filter.searchable !== false && (
                    <ul className="List">
                      {filter.options
                        .filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes((searchValue[filter.key] || "").toLowerCase())
                        )
                        .map((option, optIndex) => {
                          if (filter.multipleSelect) {
                            const isSelected =
                              selectedValues[filter.key]?.some(
                                (item) => item.value === option.value
                              ) || false;
                            return (
                              <li className="TrueList" key={optIndex}>
                                <input
                                  className="CheckBox"
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      filter.key,
                                      option.value,
                                      option.label
                                    )
                                  }
                                />
                                {option.label}
                              </li>
                            );
                          } else {
                            return (
                              <li
                                className="FalseList"
                                key={optIndex}
                                onClick={() =>
                                  handleOptionSelect(
                                    filter.key,
                                    option.value,
                                    option.label
                                  )
                                }
                              >
                                {option.label}
                              </li>
                            );
                          }
                        })}
                    </ul>
                  )}
                </div>
              )}
            </FilterField>
          </Grid>
        ))}
    </>
  );

};

export default Filter;
