import React from "react";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { FilterField } from "../../style/filter";

const Filter = () => {
  const location = useLocation();
  const { row } = location.state || {}; 

  return (
    <>
      <Grid item xs={6} sm={4} lg={2}>
        <FilterField>
          <label>Dealer Code</label>
          <input type="text" value={row?.dealer_code || "Not Available"} disabled readOnly />
        </FilterField>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FilterField>
          <label>Dealership Name</label>
          <input type="text" value={row?.dealership_name || "Not Available"} disabled readOnly />
        </FilterField>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FilterField>
          <label>City</label>
          <input type="text" value={row?.city || "Not Available"} disabled readOnly />
        </FilterField>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FilterField>
          <label>Financial Year</label>
          <input type="text" value={row?.financialYear || "Not Available"} disabled readOnly />
        </FilterField>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FilterField>
          <label>Period</label>
          <input type="text" value={row?.period || "Not Available"} disabled readOnly />
        </FilterField>
      </Grid>
    </>
  );
};

export default Filter;
