// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import roleReducer from '../features/RoleSlice';
import drawerReducer from '../features/DrawerSlice';
import userReducer from '../features/userSlice';
import filterParameterReducer from '../features/filterParameterSlice';
import filteredDropdownReducers from '../features/filteredDropdownSlice';
import filterDataReducers from '../features/filterSlice';

const appReducer = combineReducers({
  role: roleReducer,
  drawer: drawerReducer,
  user: userReducer,
  filterParameter: filterParameterReducer,
  filteredDropdown: filteredDropdownReducers,
  filterData: filterDataReducers
});

const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer
});

export default store;