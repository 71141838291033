import { apiCall } from "./authServices";
import { getCurrentDateTime, getCurrentFinancialYear, getCurrentMonth, getCurrentTime, getLocation } from "../utils/helper";

export const GetHierarchyMaster = async ({ payload = {} }) => {
    try {
        const response = await apiCall({
            endpoint: `api/hierarchy-master/get-hierarchy`,
            method: 'POST',
            payload: payload
        })
        return response;
    } catch (error) {
        throw error;
    }
}
export const GetAiList = async () => {
    try {
        const response = await apiCall({
            endpoint: `api/hierarchy-master/get-ai`,
            method: 'POST',
            payload: {}
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const ReassignAi = async ({ payload = null }) => {
    try {
        if (payload) {
            const response = await apiCall({
                endpoint: `api/hierarchy-master/reassign-ai`,
                method: 'PUT',
                payload: payload
            })
            return response;
        }

    } catch (error) {
        throw error;
    }
}

export const ImportExcelFile = async ({ payload }) => {
    try {
        const endpoint = `api/hierarchy-master/import-dealer-excel`
        const res = await apiCall({
            endpoint: endpoint,
            method: "POST",
            payload: payload,
        });
        return res;
    } catch (error) {
        throw error
    }
}