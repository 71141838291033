import React, { useState } from 'react';
import Modal from '../Components/Modal/Modal';
import PopUpModal from '../Components/Modal/PopUpModal';
import ScoreHistoryModal from '../Components/Modal/ScoreHistoryModal';

function ModalPage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
    const [isScoreHistoryModalOpen, setIsScoreHistoryModalOpen] = useState(false);


    // Open modal --1
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    // OpenPopup modal --2
    const openPopupModal = () => {
        setIsPopupModalOpen(true);
    }
    const closePopupModal = () => {
        setIsPopupModalOpen(false);
    }

    // Score History modal --- 3
    const openScoreHistoryModal = () => {
        setIsScoreHistoryModalOpen(true);
    }
    const closeScoreHistoryModal = () => {
        setIsScoreHistoryModalOpen(false);
    }

    return (
        <>
            <button onClick={openModal} className='modal_open'>Open Modal</button>

            <button onClick={openPopupModal} className='modal_open' style={{ marginLeft: "20px" }}>Pop-up Modal</button>

            <button onClick={openScoreHistoryModal} className='modal_open' style={{ marginLeft: "20px" }}>Score History Modal</button>



            {isModalOpen && <Modal onClose={closeModal} />}

            {isPopupModalOpen && <PopUpModal onClose={closePopupModal} />}


            {isScoreHistoryModalOpen && <ScoreHistoryModal onClose={closeScoreHistoryModal} />}
        </>
    );
}

export default ModalPage;
