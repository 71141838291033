import React, { useEffect, useState } from 'react';
import { CredentialForm } from '../../style/HO/Credential';
import { Grid } from '@mui/material';
import { GetCredential, UpdateCredential, TestCredentialEmail } from '../../services/credentials';
import { toast, ToastContainer } from "react-toastify";
import seePasswordIcon from "../../assets/images/icons/eye.png";
import hidePasswordIcon from "../../assets/images/icons/eye1.png";
function Credential() {
  const [credentialKey, setCredentialKey] = useState("");
  const [credentialPassword, setCredentialPassword] = useState("");
  const [testEmail, setTestEmail] = useState(false);
  const [useTestEmail, setUserTestEmail] = useState("");
  const [isUpdateCredential, setIsUpdateCredential] = useState(false);
  const [open, setOpen] = useState(false);

  const handleShowTestEmail = (e) => {
    e.preventDefault();
    setTestEmail(!testEmail);
  }

  const fetchCredential = async () => {
    try {
      const res = await GetCredential();
      if (res?.success) {
        setCredentialKey(res?.data?.credentialKey);
        setCredentialPassword(res?.data?.credentialPassword);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        credentialKey,
        credentialPassword
      };
      const res = await UpdateCredential({ payload });
      if (res?.success) {
        toast.success("Credential updated successfully");
        setIsUpdateCredential(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSendTestEmail = async (e) => {
    e.preventDefault();
    if (useTestEmail === ""){
      toast.warning("Please enter email");
      return;
    }
    try {
      const payload = {
        testEmailId: useTestEmail
      };
      const res = await TestCredentialEmail({ payload });
      if (res?.success) {
        setTestEmail(false);
        toast.success(res?.message ?? "Test email sent successfully");
        setUserTestEmail("");
      }
    } catch (error) {
      console.error("Failed to send email: ", error);
      toast.error("Failed to send email");
      setTestEmail(false);
      setUserTestEmail("");
    }
  }

  useEffect(() => {
    fetchCredential();
  }, [])
  return (
    <>
      <CredentialForm>
        <div className='Credentail_box'>
          <h1>Credential</h1>
          <form className='form_group_box'>
            <Grid container spacing={2} alignItems="end">
              <Grid item xs={12} md={5}>
                <div className='input_form_group'>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    value={credentialKey}
                    onChange={(e) => setCredentialKey(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <div className='input_form_group'>
                  <label htmlFor="password">Password:</label>
                  <input
                    type={open ? "text" : "password"}
                    id="password"
                    name="password"
                    value={credentialPassword}
                    placeholder="Enter your password"
                    onChange={(e) => setCredentialPassword(e.target.value)}
                  />
                  <button type="button" className='visible-button' onClick={() => setOpen(!open)}>
                    <img
                      src={open ? hidePasswordIcon : seePasswordIcon}
                      alt="Toggle visibility"
                    />
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} md={2}>
                <button onClick={handleSubmit} disabled={isUpdateCredential}>Submit</button>
              </Grid>
              <Grid item xs={12} md={12}>
                <button onClick={handleShowTestEmail} className='text-email-btn'>Test Email</button>
              </Grid>

              {testEmail && (
                <>
                  <Grid item xs={12} md={10}>
                    <div className='input_form_group'>
                      <label htmlFor="password">Email:</label>
                      <input
                        type="text"
                        id="testEmail"
                        name="testEmail"
                        placeholder="Enter your dummy email"
                        onChange={(e) => setUserTestEmail(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <button onClick={handleSendTestEmail}>Submit</button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </div>
      </CredentialForm>
      <ToastContainer />
    </>
  );
}

export default Credential;
