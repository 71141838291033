exports.MVRCheckListMenu = [
  { value: "", display: "Select" },
  { value: "idealRequirement", display: "Ideal Requirement" },
  { value: "scoringCriteria", display: "Scoring Criteria" },
  { value: "addDropdown", display: "Add Dropdown for Scoring (DD)" },
  { value: "sampleImage", display: "Add Sample Image (SI)" },
  { value: "evidenceUpload", display: "Evidence Upload (EU)" }
  // { value: "remarks", display: "Add Remarks" },
  // { value: "textField", display: "Add Text Field (TF)" }
];

exports.Roles = {
  'ho': 'Head Office',
}

exports.NotAvailable = ' '
