import { apiCall } from "./authServices";

export const GetAllVersions = async () => {
    try{
        const endpoint = 'api/mvr-schedule/version-name'
        const res = await apiCall({
            endpoint: endpoint,
            method: 'GET'
        })
        return res;
    }catch(error){
        console.log('Error on fetching version name: ', error)
        throw error;
    }
}

// upload mvr visit schedule 
export const UploadMvrSchedule = async ({ payload }) => {
    try{
        const endpoint = 'api/mvr-schedule/add-schedule';
        const res = await apiCall({
            endpoint: endpoint,
            method: 'POST',
            payload: payload
        })
        return res;
    }catch(error){
        console.log('Error on uploading mvr schedule: ', error)
        throw error;
    }
}

// get mvr schedule future data
export const GetMvrScheduleFutureData = async () => {
    try{
        const endpoint = 'api/mvr-schedule/get-future-schedule';
        const res = await apiCall({
            endpoint: endpoint,
            method: 'GET'
        })
        return res;
    }catch(error){
        console.log("Error on fetching mvr schedule future data: ", error)
        throw error;
    }
}

// delete request
export const DeleteMvrSchedule = async (id) => {
    try{
        const endpoint = `api/mvr-schedule/delete-schedule/${id}`;
        const res = await apiCall({
            endpoint: endpoint,
            method: 'DELETE',
        })
        return res;
    }catch(error){
        console.log('Error on deleting mvr Schedule: ', error);
        throw error;
    }
}