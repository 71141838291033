import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { height, width } from "@mui/system";
import { position } from "polished";

export const CredentialForm = styled(Box)(() => ({
  '& .Credentail_box': {
    '& h1': {
      fontSize: '1.5rem',
      textAlign: 'center',
      paddingTop: '20px',
      textTransform: 'uppercase',
      color: '#ff4500',
    },
    '& .form_group_box': {
      marginTop: '30px',
      '& .input_form_group': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        position: 'relative',
        '& label': {
          fontSize: '16px',
          fontWeight: 'bold',
        },
        '& input': {
          height: '35px',
          border: '1px solid #ddd',
          borderRadius: '4px',
          outline: 'none',
          padding: '0 5px',
          width: '100%',

        },
        '& .visible-button': {
          backgroundColor: 'transparent',
          position: 'absolute',
          right: '0',
          top: '18px',
          '& img': {
            height: '25px',
            width: '25px',
            cursor: 'pointer'
          }
        }
      },
      '& button': {
        padding: '10px 20px',
        backgroundColor: '#057b05',
        color: '#fff',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: '#034b03',
        },
        '&:disabled': {
          backgroundColor: 'gray',
          cursor: 'not-allowed'
        }
      },
      '& .text-email-btn': {
        backgroundColor: '#ff4500',
        '&:hover': {
          backgroundColor: '#a53208'
        }
      }
    },
  },
}));
