import React, { useState, useEffect } from 'react';
import { Card, CardContain } from '../../style/Home';
import { CommanTableContainMVRApproval } from '../../style/table';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handleMomEscalation, GetEscalationData } from '../../services/momService';

function MoMEscalation() {
    const [inputValue, setInputData] = useState([]); // State to hold input data
    const [loader, setLoader] = useState(false);

    // Fetch Escalation Data
    const getEscalationData = async () => {
        try {
            setLoader(true);
            const res = await GetEscalationData();
            if (res.success) {
                setInputData(res.data);
            }
        } catch (error) {
            console.log('Error fetching escalation data:', error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        getEscalationData();
    }, []);

    // Handle input change
    const handleInputChange = (e, index) => {
        const updatedInputValue = [...inputValue];
        updatedInputValue[index].escalationDays = e.target.value; // Update the specific field
        setInputData(updatedInputValue);
    };

    // Handle form submission
    const handleSubmit = async (e, index) => {
        e.preventDefault();
        try {
            const id = inputValue[index]?.id;
            const escalationDays = parseInt(inputValue[index]?.escalationDays);

            if (id && escalationDays) {
                const payload = { escalationDays };
                console.log("Payload being sent:", payload); // Debugging log

                const res = await handleMomEscalation(id, payload);
                if (res.success) {
                    toast.success("Successfully Submitted");
                } else {
                    toast.error("Failed to save data.");
                }
            } else {
                toast.error("Invalid data or ID");
            }
        } catch (error) {
            toast.error("Error submitting data.");
        }
    };

    return (
        <>
            <ToastContainer></ToastContainer>
            <div>
                <CardContain>
                    <Card mt={2}>
                        <CommanTableContainMVRApproval>
                            <Table stickyHeader className="custom-table">
                                <TableHead>
                                    <TableRow className="custom-row">
                                        <TableCell className="custom-cell">S.No.</TableCell>
                                        <TableCell className="custom-cell">Zone</TableCell>
                                        <TableCell className="custom-cell">Entry (Target Date +)</TableCell>
                                        <TableCell className="custom-cell">Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {inputValue.map((row, index) => (
                                        <TableRow key={index} className="custom-row myCustomRow">
                                            <TableCell className="custom-cell">{index + 1}</TableCell>
                                            <TableCell className="custom-cell">{row.escalationLevel}</TableCell>
                                            <TableCell className="custom-cell">
                                                <input
                                                    type="number"
                                                    className="es_input"
                                                    value={row.escalationDays || ""}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    min="0" 
                                                    step="1"
                                                />
                                            </TableCell>
                                            <TableCell className="custom-cell">
                                                <button
                                                    className="btn_s"
                                                    onClick={(e) => handleSubmit(e, index)}>
                                                    Save
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CommanTableContainMVRApproval>
                    </Card>
                </CardContain>
            </div>
        </>
    );
}

export default MoMEscalation;
