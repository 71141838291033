import { apiCall } from "./authServices";

export const GetDealerData = async ({ payload }) => {
    try {
        const endpoint = `api/dealer-master/get-dealers`
        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "post",
        });
        return res
    } catch (error) {
        throw error;
    }
}

export const UpdateDealer = async ({ payload }) => {

    try {
        const { id } = payload;
        const endpoint = `api/dealer-master/update-dealer/${id}`
        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "put",
        });
        return res
    } catch (error) {
        throw error;
    }
}


export const ImportCsvFile = async ({ payload }) => {

    try {
        const endPoint = `api/dealer-master/import-dealer`
        const res = await apiCall({
            endpoint: endPoint,
            payload: payload,
            method: "post",
        });
        return res;
    } catch (error) {
        throw error;
    }
}
