import { apiCall } from "./authServices";

export const PostPopUpData = async ({ payload = null }) => {
  try {
    const endpoint = `api/pop-up/add-pop-up-notification`;
    const res = await apiCall({
      endpoint: endpoint,
      payload: payload,
      method: "post",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const GetPopUpData = async () => {
  try {
    const endpoint = `api/pop-up/get-pop-up-notification`;
    const res = await apiCall({
      endpoint: endpoint,
      method: "get",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const DeletePopUpData = async ({ id }) => {
  try {
    const endpoint = `api/pop-up/delete-pop-up-notification/${id}`;
    const res = await apiCall({
      endpoint: endpoint,
      method: "DELETE",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdatePopUpData = async ({ id, payload }) => {
  try {
    const endpoint = `api/pop-up/update-pop-up-notification/${id}`;
    const res = await apiCall({
      endpoint: endpoint,
      payload: payload,
      method: "PUT",
    });
    return res;
  } catch (error) {
    throw error;
  }
}




// Notification Modal 
export const GetPopUpCount = async () => {
  try {
    const endpoint = `api/pop-up/get-notification-count`;
    const res = await apiCall({
      endpoint: endpoint,
      method: "get",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const GetPopUpNotification = async () => {
  try {
    const endpoint = `api/pop-up/get-notification`;
    const res = await apiCall({
      endpoint: endpoint,
      method: "get",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const tapedUpdate = async ({ id }) => {
  try {

    const endpoint = `api/pop-up/update-notification-tap/${id}`;
    const res = await apiCall({
      endpoint: endpoint,
      method: "patch",
    });

    return res;
  } catch (error) {
    console.error("Error in tapedUpdate: ", error);
    throw error;
  }
};

export const UserPopUpNotification = async() => {
  try {
    const endpoint = `api/pop-up/get-user-pop-up-notifications`;
    const res = await apiCall({
      endpoint: endpoint,
      method: "get",
    });
    return res;
  } catch (error) {
    throw error;
  }
}

