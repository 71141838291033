import React, { useState, useRef, useEffect } from "react";
import "../../css/sendotp.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Error, LoginContain, LoginFooter } from "../../style/login/Login";
import Logo from "../../assets/images/logo.png";
import { Loader } from "../../utils/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiCall } from "../../services/authServices";

function Sendotp() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const otpInputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()
  const responseData = location.state?.data;
  const [errors, setErrors] = useState({});
  // Validate the form
  //   const validateForm = () => {
  //     let formErrors = {};
  //     if (!responseData.userType) formErrors.userType = "User Type is required";
  //     if (!responseData.userId.trim()) formErrors.userId = "User ID is required";
  //     setErrors(formErrors);
  //     return Object.keys(formErrors).length === 0;
  //   };

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < otpInputRefs.current.length - 1) {
      otpInputRefs.current[index + 1].focus();
    }
    if (!value && index > 0) {
      otpInputRefs.current[index - 1].focus();
  }
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleResend = () => {
    setTimer(60);
    setOtp(["", "", "", "", "", ""]);
    otpInputRefs.current[0].focus();
  };

  const handleCheckOtp = async (e) => {
    e.preventDefault();

    try {
      if (responseData && responseData.userId) {
        const enteredApi = otp.join("");
        responseData["OTP"] = enteredApi;

        setLoading(true);
        const res = await apiCall({
          endpoint: "api/auth/check-otp",
          method: "POST",
          payload: responseData,
        });
        console.log("res: ", res);
        if (res && res.success) {
          navigate("/new-password", { state: { data: responseData } });
        }
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <LoginContain>
        <div className="LoginLogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="LoginCard">
          <div className="LoginTitle">
            <h4>Send OTP</h4>
            <p>EasyGo Audit support team is just a tap away!</p>
          </div>
          <form onSubmit={handleCheckOtp}>
            <div className="LoginGroup text-center">
              <label htmlFor="user-id" className="forgot-label ">
                Enter your OTP
              </label>
            </div>

            <div className="otp-input-container">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target.value, index)}
                  ref={(el) => (otpInputRefs.current[index] = el)}
                  className="otp-input"
                />
              ))}
            </div>
            <div className="timer-resend-container text-center">
              {timer > 0 ? (
                <span className="timer">Time Remaining: {timer} seconds</span>
              ) : (
                <button onClick={handleResend} className="resend-button">
                  Resend OTP
                </button>
              )}
            </div>
            {timer > 0 && (
              <div className="LoginGroup LoginBtn">
                <button type="submit" className="submit-button">
                  {loading ? <Loader /> : "Submit"}
                </button>
              </div>
            )}
          </form>
        </div>
      </LoginContain>
      
                  <LoginFooter>
                  <p>  POWERED BY: <span>Manthan IT Solutions</span></p>
                  </LoginFooter>
    </>
  );
}

export default Sendotp;
