import React, { useEffect, useState, useRef } from "react";
import {
    ButtonContain,
    Card,
    CardContain
} from '../../style/Home'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField
} from "@mui/material";
import { CommanTableContainHierachyMaster } from "../../style/table";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { HierachyMasterModal, FillterContainer } from "../../style/HO/MVRManualCreation";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { CommanTableContain, ContainerCustom, PaginationContain, SmartSearchContain } from '../../style/table';
import SearchIcon from "@mui/icons-material/Search";
import { GetAiList, GetHierarchyMaster, ReassignAi, ImportExcelFile } from "../../services/hierarchyMasterService";
import { ContainerCustomDealerMaster } from '../../style/table';
import _ from 'lodash';
import Filter from "../../Components/filters";
import { resetFilter } from "../../features/filterSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HierachyMaster = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterValues, setFilterValues] = useState({});
    const [aiModalOpen, setAiModalOpen] = useState(false);
    const Drawer = useSelector((state) => state.drawer);
    const [isSelected, setIsSelected] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(Date.now());
    const filtersValue = useSelector((state) => state.filterData);
    const [hierarchyData, setHierarchyData] = useState([]);
    const [search, setSearch] = useState("");
    const [aiData, setAiData] = useState([])
    const [searchAiByCode, setSearchAiByCode] = useState('')
    const [searchAiByName, setSearchAiByName] = useState('')
    // const [debouncedSearch, setDebouncedSearch] = useState('');
    const [totalData, setTotalData] = useState(0);
    const [isDispatched, setIsDispatched] = useState(false)
    const [isreset, setReset] = useState(false)
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const totalAi = useRef([]);
    const excelFile = useRef(null);

    const getDealerList = async () => {
        try {
            const res = await GetAiList()
            if (res.success) {
                setAiData(res.data)
                totalAi.current = res.data
            }
        } catch (error) {
            console.log('error: ', error);
            console.log('Error on fetching mvr table data');
        }
    }

    useEffect(() => {
        getDealerList()
    }, [])


    useEffect(() => {
        dispatch(resetFilter());
        setIsDispatched(true)
    }, [dispatch]);

    const getHierarchyMaster = async () => {
        try {
            let payload = {
                ...filtersValue,
                "page": page,
                "offset": rowsPerPage
            }
            const res = await GetHierarchyMaster({ payload });
            if (res.success) {
                console.log(res);
                setHierarchyData(res.data)
                setTotalData(res.total)
            }
        } catch (error) {
            console.log('Error on fetching mvr table data');
        }
    }




    useEffect(() => {
        getHierarchyMaster()

    }, [rowsPerPage, page]);

    const mvrTableCols = [
        "Main Dealer Code",
        "Main Dealer Name",
        // "Network Type",
        // "Network Code",
        // "Network Name",
        "AI Code",
        "AI Name",
        "ZM Code",
        "ZM Name",
        "RM Code",
        "RM Name",
        // "Operation",
        "AI Code Assigned by ZM",
        "AI Name Assigned by ZM",
        "Edit",
    ];


    const handleEditClick = (row) => {
        setSelectedRow(row);
        setModalOpen(true);

    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedRow(null);
    };
    const handleAiModalOpen = () => setAiModalOpen(true);
    const handleAiModalClose = () => setAiModalOpen(false);
    const handleButtonClick = (newValue, index) => {
        setSelectedRow(prevState => ({
            ...prevState,
            evaluatorName: newValue.aiName,
            evaluatorCode: newValue.aiCode
        }));
        const currIndex = hierarchyData.findIndex(el => el.dealerCode === selectedRow.dealerCode)
        if (currIndex >= 0) {
            setHierarchyData(prevData =>
                prevData.map((item, i) =>
                    i === currIndex
                        ? { ...item, evaluatorCode: newValue.aiCode, field2: newValue.aiName }
                        : item
                )
            );
        }
        setAiModalOpen(false);
    };

    const handleFilterChange = (filterKey, value) => {
        setFilterValues((prev) => ({
            ...prev, // Keep previous values
            [filterKey]: value, // Update the specific field
        }));
    };


    const handleSearch = async () => {
        await getHierarchyMaster()
    }

    const handleReset = () => {
        dispatch(resetFilter())
        setResetTrigger(Date.now())
        setTimeout(() => setResetTrigger(false), 0)
    }

    useEffect(() => {
        getHierarchyMaster()
    }, [modalOpen])

    useEffect(() => {
        getHierarchyMaster();
    }, [resetTrigger])


    useEffect(() => {
        const data = totalAi?.current ? totalAi.current : []
        if (searchAiByCode) {
            const filteredData = data?.filter(item => item.aiCode.includes(searchAiByCode))
            setAiData(filteredData)
        } else {
            setAiData(data)
        }

    }, [searchAiByCode])

    useEffect(() => {
        const data = totalAi?.current ? totalAi.current : [];
        if (searchAiByName) {
            const filteredData = data?.filter(item =>
                item.aiName.toLowerCase().includes(searchAiByName.toLowerCase())
            );
            setAiData(filteredData);
        } else {
            setAiData(data);
        }
    }, [searchAiByName]);

    const handleSave = async () => {
        try {
            const payload = {
                dealerCode: selectedRow?.dealerCode,
                aiCode: selectedRow.evaluatorCode
            }
            const res = await ReassignAi({ payload: payload })
            if (res.success) {
                console.log(res.message)
                toast.success("Updated Sucessfully");
                setModalOpen(false)
            }
            else {
                toast.error("Not Update")
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const handleExcelfile = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        if (!file) {
            toast.error("Please upload a file");
            return;
        }
        const formData = new FormData()
        formData.append("hierarchyMaster", file)

        try {
            setLoader(true)
            const res = await ImportExcelFile({ payload: formData })
            if (res.success) {
                toast.success("File Upload Successfully")
            }
        } catch (error) {
            console.log("error:", error);
            if (error.code === "ERR_BAD_REQUEST") {
                toast.error(error?.response?.data?.error);
            }
            else {
                toast.error(error?.response?.data?.error)
            }
        } finally {
            setTimeout(() => {
                setLoader(false);
            }, 1000)
        }
    }
    return (
        <>
            <CardContain>
                <Card mt={1} className="Filter_bg">
                    <Grid container spacing={1}>
                        <Filter onFilterChange={handleFilterChange} reset={resetTrigger} showFinancialYear={false} showPeriod={false} ></Filter>
                        <Grid item xs={12}>
                            <ButtonContain>
                                <button onClick={handleSearch} className="SearchButton">Search</button>
                                <button onClick={handleReset} className="ResetButton">Reset</button>
                            </ButtonContain>
                        </Grid>
                    </Grid>
                </Card>
            </CardContain>

            <CardContain>
                <Card mt={2}>
                    <ContainerCustomDealerMaster>
                        <Button
                            variant="contained"
                            disable={loader}
                            onClick={() => {
                                excelFile.current.value = ""
                                excelFile.current.click();
                            }}
                        >Import
                        </Button>
                        <input
                            type="file"
                            ref={excelFile}
                            style={{ display: "none" }}
                            accept=".csv, .xls, .xlsx"
                            onChange={(e) => { handleExcelfile(e) }}
                        />
                    </ContainerCustomDealerMaster>
                    <CommanTableContainHierachyMaster>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {mvrTableCols.map((text, index) => (
                                        <TableCell key={index}>{text}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hierarchyData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.dealerCode}</TableCell>
                                        <TableCell>{row.dealerName}</TableCell>
                                        {/* <TableCell>{row.networkType}</TableCell>
                                        <TableCell>{row.networkCode}</TableCell>
                                        <TableCell>{row.networkName}</TableCell> */}
                                        <TableCell>{row.evaluatorCode}</TableCell>
                                        <TableCell>{row.evaluatorName}</TableCell>
                                        <TableCell>{row.zmCode}</TableCell>
                                        <TableCell>{row.zmName}</TableCell>
                                        <TableCell>{row.rmCode}</TableCell>
                                        <TableCell>{row.rmName}</TableCell>
                                        {/* <TableCell>{row.operation}</TableCell> */}
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <EditIcon onClick={() => handleEditClick(row)} style={{ cursor: "pointer" }} />
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </CommanTableContainHierachyMaster>
                </Card>
            </CardContain>
            <TablePagination
                component="div"
                count={totalData}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            />
            {modalOpen && (
                <HierachyMasterModal className={Drawer ? "" : "DrawerOpen"}>
                    <div className="modal_container_box">
                        <div className="ModalHeader">
                            <h4>Update Data</h4>
                            <button className="CloseButton" onClick={handleModalClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="ModalBody">
                            <div className="input_form_group">
                                <label htmlFor="">Dealer Code</label>
                                <input readOnly value={selectedRow?.dealerCode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, dealerCode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Dealer Name</label>
                                <input readOnly value={selectedRow?.dealerName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, dealerName: e.target.value })} />
                            </div>
                            {/* <div className="input_form_group">
                                <label htmlFor="">Network Type</label>
                                <input readOnly value={selectedRow?.networkType || ""} onChange={(e) => setSelectedRow({ ...selectedRow, networkType: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Network Code</label>
                                <input readOnly value={selectedRow?.networkCode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, networkCode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Network Name</label>
                                <input readOnly value={selectedRow?.networkName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, networkName: e.target.value })} />
                            </div> */}
                            <div className="input_form_group">
                                <label htmlFor="">AI Code</label>
                                <input value={selectedRow?.evaluatorCode || ""} onClick={handleAiModalOpen} onChange={(e) => setSelectedRow({ ...selectedRow, aiCode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">AI Name</label>
                                <input readOnly value={selectedRow?.evaluatorName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, aiName: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">ZM Code</label>
                                <input readOnly value={selectedRow?.zmCode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, zmCode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">ZM Name</label>
                                <input readOnly value={selectedRow?.zmName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, zmName: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">RM Code</label>
                                <input readOnly value={selectedRow?.rmCode || ""} onChange={(e) => setSelectedRow({ ...selectedRow, rmCode: e.target.value })} />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">RM Name</label>
                                <input readOnly value={selectedRow?.rmName || ""} onChange={(e) => setSelectedRow({ ...selectedRow, rmName: e.target.value })} />
                            </div>
                            {/* <div className="input_form_group">
                                <label htmlFor="">Operation</label>
                                <input readOnly value={selectedRow?.operation || ""} onChange={(e) => setSelectedRow({ ...selectedRow, operation: e.target.value })} />
                            </div> */}
                            <div className="input_form_group">
                                <label htmlFor="">Created Date</label>
                                <input readOnly placeholder="02-01-2025" type="text" />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Created By</label>
                                <input readOnly type="text" />
                            </div>
                            <div className="input_form_group">
                                <label htmlFor="">Update Date</label>
                                <input readOnly placeholder="No Update" type="text" />
                            </div>
                        </div>
                        <div className="ModalFooter">
                            <div className="input_form_group modal_btn_group">
                                <button onClick={handleSave} className="save_btn">Save</button>
                                <button className="cancel_btn" onClick={handleModalClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </HierachyMasterModal>
            )}

            {aiModalOpen && (
                <HierachyMasterModal className={Drawer ? "" : "DrawerOpen"}>
                    <div className="modal_container_box">
                        <div className="ModalHeader">
                            <h4>Select Area Incharge</h4>
                            <button className="CloseButton" onClick={handleAiModalClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="ModalBody">
                            <CardContain>
                                <Card mt={2}>
                                    <CommanTableContainHierachyMaster>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Search AI</TableCell>
                                                    <TableCell>Area Incharge Name</TableCell>
                                                    <TableCell>Select AI</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell> <input value={searchAiByCode || ''} onChange={(e) => setSearchAiByCode(e.target.value)} type="text" /></TableCell>
                                                    <TableCell> <input value={searchAiByName || ''} onChange={(e) => setSearchAiByName(e.target.value)} type="text" /></TableCell>
                                                    <TableCell> </TableCell>
                                                </TableRow>
                                                {aiData && aiData.length > 0 ? (
                                                    aiData.map((ai, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{ai?.aiCode}</TableCell>
                                                            <TableCell>{ai?.aiName}</TableCell>
                                                            <TableCell>
                                                                <button
                                                                    className={`select_btn`}
                                                                    onClick={() => handleButtonClick(ai, index)}
                                                                >
                                                                    Select
                                                                </button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                                            No Dealer Found
                                                        </TableCell>
                                                    </TableRow>
                                                )}


                                            </TableBody>
                                        </Table>
                                    </CommanTableContainHierachyMaster>
                                </Card>
                            </CardContain>
                        </div>
                        <div className="ModalFooter_1">
                            <button className="cancel_btn" onClick={handleAiModalClose}>
                                Close
                            </button>
                        </div>
                    </div>
                </HierachyMasterModal>
            )}


            <ToastContainer />
        </>
    );
};

export default HierachyMaster;
