import React, { useState, useEffect } from "react";
import { Card, CardContain } from "../../style/Home";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CommanTableContain,
  ContainerCustom,
  SmartSearchContain,
} from "../../style/table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Autocomplete,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  GetArchiveData,
  PostGalleryData,
} from "../../services/galleryServices";
import {
  GetDealerData,
  GetCurrentData,
  UpdateData,
  DeleteGalleryData,
} from "../../services/galleryServices";
import { ModalContain } from "../../style/HO/MVRManualCreation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../Components/utils/Confirmation";
import "../../css/gallery.css";
import { Loader } from "../../utils/loader";
import { useSelector } from "react-redux";
import { ImagePreview } from "../../style/HO/QVRManualCreation";

const TableModal = ({ children, onClickClose }) => {
  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose?.();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleClickOutside}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

const Gallery = ({ user }) => {
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [acceptedFileType, setAcceptedFileType] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [remark, setRemark] = useState("");
  const Drawer = useSelector((state) => state.drawer);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [editMode, setEditMode] = useState(null);
  const [updatedValidTo, setUpdatedValidTo] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [role, setRole] = useState("");
  const [loader, setLoader] = useState(false);

  const [newRowData, setNewRowData] = useState({
    documentType: "image",
    documentName: "",
    validFrom: "",
    validTo: "",
    viewAt: "",
    remarks: "",
  });

  const [isNewRow, setIsNewRow] = useState(false);

  const handleNewItemModalOpen = () => {
    setIsNewRow(true);
  };

  const handleSaveNewRow = async () => {
    if (!uploadedFile || !uploadedFile.file || !uploadedFile.fileName) {
      toast.error("Please upload a valid file.");
      return;
    }

    const sanitizedFileName = uploadedFile.fileName
      .trim()
      .replace(/[^a-zA-Z0-9_.-]/g, "_");
    if (!sanitizedFileName) {
      toast.error("Invalid file name. Please rename the file and try again.");
      return;
    }

    if (!newRowData.documentType) {
      toast.error("Please select a file type.");
      return;
    }

    if (!selectedDealer || selectedDealer === "") {
      toast.error("Please select a dealer.");
      return;
    }

    if (!fromDate || !dayjs(fromDate).isValid()) {
      toast.error("Please select a valid 'From Date'.");
      return;
    }

    if (!toDate || !dayjs(toDate).isValid()) {
      toast.error("Please select a valid 'To Date'.");
      return;
    }

    if (dayjs(toDate).isBefore(dayjs(fromDate))) {
      toast.error("'To Date' cannot be earlier than 'From Date'.");
      return;
    }

    const formattedFromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedToDate = dayjs(toDate).format("YYYY-MM-DD");

    const formData = new FormData();
    formData.append("validFrom", formattedFromDate);
    formData.append("validTo", formattedToDate);
    formData.append("viewAt", selectedDealer);
    formData.append("remarks", remark || "");
    formData.append("documentType", newRowData.documentType);
    formData.append("documentName", sanitizedFileName);
    formData.append("document", uploadedFile.file);

    try {
      setLoader(true);
      const response = await PostGalleryData({ payload: formData });

      if (response?.success) {
        toast.success("File uploaded successfully!");
        // Reset all form states
        setNewRowData({
          documentType: "",
          documentName: "",
          validFrom: "",
          validTo: "",
          viewAt: "",
          remarks: "",
        });
        setFromDate(null);
        setToDate(null);
        setRemark("");
        setDropdownValue("");
        setSelectedDealer("all");
        setUploadedFile(null);

        // Hide the new row
        setIsNewRow(false);

        // Refresh the table data
        await fetchCurrentData();
      } else {
        toast.error(
          `Upload failed: ${response?.error || "Unknown error occurred."}`
        );
      }
    } catch (error) {
      toast.error(
        `Failed to upload file: ${
          error?.response?.data?.error || "Please try again later."
        }`
      );
    } finally {
      setLoader(false);
    }
  };

  const headers = [
    { id: "SNo", label: "S.No." },
    { id: "documentType", label: "Document Type" },
    { id: "createdAt", label: "Created Date" },
    { id: "dealerCode", label: "All/Dealer Code" },
    { id: "document", label: "Document" },
    { id: "documentName", label: "Document Name" },
    { id: "fromDate", label: "From Date" },
    { id: "toDate", label: "To Date" },
    { id: "remark", label: "Remarks" },
    { id: "actions", label: "Action" },
  ];
  const filteredHeaders = headers.filter((header) => {
    return header.id !== "actions" || role === "ho";
  });

  const archiveHeaders = [
    { id: "s_no", label: "S.No." },
    { id: "documentType", label: "Document Type" },
    { id: "created_data", label: "Created Date" },
    { id: "dealerCode", label: "All/Dealer Code" },
    { id: "document", label: "Document" },
    { id: "documentName", label: "Document Name" },
    { id: "fromDate", label: "From Date" },
    { id: "toDate", label: "To Date" },
    { id: "remark", label: "Remarks" },
  ];

  const getRoleFromLocalStorage = () => {
    const userCred = JSON.parse(localStorage.getItem("user_cred"));
    return userCred ? userCred.roleType : "";
  };

  useEffect(() => {
    const roleFromStorage = getRoleFromLocalStorage();
    setRole(roleFromStorage);
  }, []);

  // Fetch Current Gallery Data
  const fetchCurrentData = async () => {
    try {
      const response = await GetCurrentData();
      if (response?.currentGalleryData) {
        const transformedData = response.currentGalleryData.map((item) => ({
          id: item.id,
          documentType: item.documentType,
          createdAt: item.createdAt,
          viewAt: item.viewAt,
          document: item.fileUrl,
          documentName: item.documentName,
          validFrom: item.validFrom,
          validTo: item.validTo,
          remarks: item.remarks,
        }));

        setTableData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch current gallery data. Please try again.");
    }
  };
  useEffect(() => {
    fetchCurrentData();
  }, []);

  // Fetch Archive Data
  const fetchArchiveData = async () => {
    setLoading(true);
    try {
      const payload = {
        page: currentPage,
        offset: rowsPerPage,
      };
      const response = await GetArchiveData(payload);

      if (response?.data?.archivedData) {
        const transformedData = response.data.archivedData.map((item) => ({
          documentType: item.documentType,
          createdAt: item.createdAt,
          dealerCode: item.viewAt,
          document: item.fileUrl,
          documentName: item.documentName,
          fromDate: item.validFrom,
          toDate: item.validTo,
          remark: item.remarks,
        }));

        setArchiveData(transformedData);
        setTotalCount(response.data.totalDataCount);
      }
    } catch (error) {
      console.error("Error fetching archive data:", error.message);
      toast.error("Failed to fetch archive data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Dealers Data in Dropdown
  const fetchDealers = async () => {
    setLoading(true);
    try {
      const response = await GetDealerData();
      const dealers = response?.dealerCodes || [];
      const options = dealers.map((dealer) => ({
        value: dealer.dealerCode,
        label: dealer.dealerCodeName + "-" + dealer.dealerCode,
      }));
      setDealerOptions(options);
    } catch (error) {
      console.error("Error fetching dealer data:", error);
      toast.error(`Failed to fetch dealer data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (role === "ho") {
      fetchArchiveData();
    }
  }, [role, currentPage, rowsPerPage]);
  useEffect(() => {
    if (role === "ho") {
      fetchDealers();
    }
  }, [role]);

  const handleModalOpen = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const handleDropdownChange = (selectedType) => {
    setDropdownValue(selectedType);

    if (selectedType === "Document") {
      setAcceptedFileType("application/pdf"); // Allow only PDFs
    } else if (selectedType === "Photo") {
      setAcceptedFileType("image/jpeg, image/png, image/webp"); // Allow images (JPEG, PNG, WebP)
    } else {
      setAcceptedFileType(""); // Reset if no valid selection
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile({
        file,
        fileName: file.name,
      });
    }
  };

  const handleSaveEdit = async (row) => {
    try {
      if (!updatedValidTo || !dayjs.isDayjs(updatedValidTo)) {
        toast.error("Please select a valid 'To Date'.");
        return;
      }
      const formattedValidTo = updatedValidTo.format("YYYY-MM-DD");
      const response = await UpdateData(row.id, formattedValidTo);

      if (response?.success) {
        const updatedData = tableData.map((item) =>
          item.id === row.id ? { ...item, validTo: formattedValidTo } : item
        );
        setTableData(updatedData);
        setEditMode(null);
        setUpdatedValidTo(null);
        toast.success("Data updated successfully!");
      } else {
        toast.error("Failed to update the data.");
      }
      fetchCurrentData();
    } catch (error) {
      toast.error("An error occurred while updating the data.");
    }
  };

  const handleDateChange = (newValue) => {
    setUpdatedValidTo(newValue);
  };

  const handleOpenDialog = (id) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedId(null);
  };

  const handleDelete = async (id) => {
    if (!id) {
      toast.error("Invalid ID for deletion.");
      return;
    }
    try {
      const result = await DeleteGalleryData(id);
      if (result?.success) {
        toast.success("Gallery data deleted successfully!");
        setTableData((prevData) => prevData.filter((row) => row.id !== id));
        await fetchCurrentData();
      } else {
        toast.error("Failed to delete gallery data.");
      }
      handleCloseDialog();
    } catch (error) {
      toast.error(
        `Error deleting gallery data: ${
          error.message || "Please try again later."
        }`
      );
    }
  };

  return (
    <>
      <CardContain>
        <Card mt={2}>
          {/* Search Field */}
          <ContainerCustom
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#ff4500",
              marginLeft: "5px",
            }}
          >
            <h3 className="gallery-h2">Gallery</h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* New Item Button */}
              {role === "ho" && (
                <Button
                  onClick={handleNewItemModalOpen}
                  className="new-item-button"
                >
                  New Item
                </Button>
              )}
            </div>
          </ContainerCustom>

          {/* Gallery Table */}
          <CommanTableContain>
            <Table stickyHeader className="table">
              <TableHead>
                <TableRow>
                  {filteredHeaders.map((header) => (
                    <TableCell key={header.id}>{header.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {isNewRow && (
                  <TableRow className="new-row">
                    <TableCell className="table-cell">S. No.</TableCell>
                    <TableCell className="table-cell" style={{ zIndex: 0 }}>
                      <select
                        value={newRowData.documentType || "image"}
                        onChange={(e) => {
                          setNewRowData({
                            ...newRowData,
                            documentType: e.target.value,
                          });
                          setDropdownValue(e.target.value);
                          handleDropdownChange(e.target.value);
                        }}
                        className="select-input small-height"
                      >
                        <option value="image">Image</option>
                        <option value="document">Document</option>
                      </select>
                    </TableCell>

                    <TableCell className="table-cell "></TableCell>
                    <TableCell className="table-cell dealer-cell">
                      <Autocomplete
                        value={
                          dealerOptions.find(
                            (option) => option.value === selectedDealer
                          ) || { value: "all", label: "All" }
                        }
                        onChange={(event, newValue) => {
                          setSelectedDealer(newValue ? newValue.value : "all");
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ...dealerOptions,
                        ]}
                        getOptionLabel={(option) => option.label || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a Dealer"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        filterOptions={(options, { inputValue }) => {
                          const searchText = inputValue.toLowerCase();
                          return options.filter(
                            (option) =>
                              option.label.toLowerCase().includes(searchText) ||
                              option.value.toLowerCase().includes(searchText)
                          );
                        }}
                        renderOption={(props, option) => (
                          <li {...props} className="autocomplete-option">
                            {option.label}
                          </li>
                        )}
                        ListboxProps={{
                          className: "autocomplete-list",
                        }}
                        PaperComponent={({ children }) => (
                          <Paper className="autocomplete-paper">
                            {children}
                          </Paper>
                        )}
                      />
                    </TableCell>

                    <TableCell className="table-cell">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept={
                          newRowData.documentType === "document"
                            ? "application/pdf"
                            : "image/*"
                        }
                        className="file-input"
                      />
                    </TableCell>
                    <TableCell className="table-cell disabled-fields"></TableCell>
                    <TableCell className="table-cell">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label=""
                          value={fromDate}
                          onChange={(newValue) => setFromDate(newValue)}
                          format="DD-MM-YYYY"
                          minDate={dayjs()}
                          renderInput={(params) => (
                            <TextField {...params} className="date-picker" />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell className="table-cell">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label=""
                          value={toDate}
                          onChange={(newValue) => setToDate(newValue)}
                          format="DD-MM-YYYY"
                          minDate={fromDate || dayjs()}
                          renderInput={(params) => (
                            <TextField {...params} className="date-picker" />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell className="table-cell">
                      <input
                        type="text"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        placeholder="Add remarks"
                        className="remark-input"
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <div className="button-group">
                        <button
                          onClick={handleSaveNewRow}
                          className="button-save"
                        >
                          <DoneIcon />
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() => setIsNewRow(false)}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {tableData.map((row, id) => (
                  <TableRow key={row.id}>
                    <TableCell>{id + 1}</TableCell>
                    <TableCell>{row.documentType}</TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                    <TableCell>{row.viewAt}</TableCell>
                    <TableCell>
                      <div className="modal-image">
                        {row.documentType === "image" ? (
                          <img
                            src={row.document}
                            alt="Doc"
                            className="modal-image-img"
                            onClick={() => handleModalOpen(row.document)}
                          />
                        ) : (
                          <a
                            href={row.document}
                            target="_blank"
                            className="document-link"
                          >
                            <SummarizeIcon />
                          </a>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{row.documentName}</TableCell>
                    <TableCell>{row.validFrom}</TableCell>
                    <TableCell>
                      {editMode === row.id ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="To Date"
                            value={updatedValidTo}
                            onChange={handleDateChange}
                            format="DD-MM-YYYY"
                            minDate={dayjs(row.validFrom || new Date())}
                            slotProps={{
                              textField: {
                                error: updatedValidTo === null,
                                helperText:
                                  updatedValidTo === null
                                    ? "Please select a valid date"
                                    : "",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        row.validTo
                      )}
                    </TableCell>
                    <TableCell>{row.remarks}</TableCell>
                    {role === "ho" && (
                      <TableCell>
                        <Box className="button-group">
                          {editMode === row.id ? (
                            <>
                              <button
                                className="button-done"
                                onClick={() => handleSaveEdit(row)}
                              >
                                <DoneIcon />
                              </button>
                              <button
                                className="button-cancel"
                                onClick={() => {
                                  setEditMode(null);
                                  setUpdatedValidTo(dayjs(row.validTo));
                                }}
                              >
                                <CloseIcon />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="button-edit"
                                onClick={() => {
                                  setEditMode(row.id);
                                  setUpdatedValidTo(dayjs(row.validTo));
                                }}
                              >
                                <EditNoteIcon />
                              </button>
                              <button
                                className="button-delete"
                                onClick={() => handleOpenDialog(row.id)}
                              >
                                <DeleteIcon />
                              </button>
                              <ConfirmationDialog
                                open={dialogOpen}
                                handleCancel={handleCloseDialog}
                                handleConfirm={() => handleDelete(selectedId)}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CommanTableContain>

          <ToastContainer position="top-right" autoClose={5000} />

          {/* Archive Table */}
          {role === "ho" && (
            <>
              <h2 className="modal-header">Archived Documents</h2>
              <CommanTableContain className="common-table-contain">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {archiveHeaders.map((header) => (
                        <TableCell key={header.id}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell
                          colSpan={archiveHeaders.length}
                          align="center"
                        >
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : archiveData.length > 0 ? (
                      archiveData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {index + 1 + currentPage * rowsPerPage}
                          </TableCell>
                          <TableCell>{row.documentType}</TableCell>
                          <TableCell>{row.createdAt}</TableCell>
                          <TableCell>{row.dealerCode}</TableCell>
                          <TableCell>
                            <div className="modal-image">
                              {row.documentType === "image" ? (
                                <img
                                  src={row.document}
                                  alt="Doc"
                                  className="modal-image-img"
                                  onClick={() => handleModalOpen(row.document)}
                                />
                              ) : (
                                <a
                                  href={row.document}
                                  target="_blank"
                                  className="document-link"
                                >
                                  <SummarizeIcon />
                                </a>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>{row.documentName}</TableCell>
                          <TableCell>{row.fromDate}</TableCell>
                          <TableCell>{row.toDate}</TableCell>
                          <TableCell>{row.remark}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={archiveHeaders.length}
                          align="center"
                        >
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CommanTableContain>
              {/* Archive Table pagination */}
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={(event, newPage) => setCurrentPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setCurrentPage(0);
                }}
              />
            </>
          )}

          {modalOpen && (
            <ModalContain className={Drawer ? " " : "DrawerOpen"}>
              <ImagePreview>
                <div className="ModalHeader">
                  <h4>Image Preview </h4>
                  <button className="CloseButton" onClick={handleModalClose}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="ModalBody">
                  {selectedImage && selectedImage.endsWith(".pdf") ? (
                    <iframe
                      src={selectedImage}
                      className="document-preview-iframe"
                      title="Document Preview"
                    />
                  ) : (
                    <img
                      src={selectedImage}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              </ImagePreview>
            </ModalContain>
          )}
        </Card>
      </CardContain>
    </>
  );
};

export default Gallery;
