import { apiCall } from "./authServices";

// Function to get data for various reports
export const GetExcelFile = async ({ reportType, payload }) => {
  try {
    let endpoint = "";

    // Set the endpoint based on the reportType
    switch (reportType) {
      case "MVR Visit Report":
        endpoint = "api/reports/mvr-visit-report";
        break;
      case "MVR Evaluation Status Report":
        endpoint = "api/reports/mvr-evaluation-status-report";
        break;
      case "MVR MoM Status Report":
        endpoint = "api/reports/mvr-mom-status-report";
        break;
      case "MoM Escalation Report":
        endpoint = "api/reports/mvr-mom-escalation-report";
        break;
      default:
        throw new Error("Invalid report type");
    }

    // Make the API call
    const res = await apiCall({
      endpoint: endpoint,
      method: "post",
      payload: payload,
    });

    return res;
  } catch (error) {
    throw error;
  }
};
