import { apiCall } from "./authServices";

const groupedScoreCardData = (response) => {
    let obj = {};
    let newArray = [];

    for (const {parameter, parameterTotal, checklist, checklistTotal, receivedScore, na} of response) {
        if (parameter in obj){
            obj[parameter].checklists.push({checklist, checklistTotal, receivedScore, na});
            obj[parameter].totalReceivedScore += receivedScore;
        }else{
            obj[parameter] = {
                parameter,
                parameterTotal,
                totalReceivedScore: receivedScore,
                checklists: [{checklist, checklistTotal, receivedScore, na}],
            };
            newArray.push(parameter);
        }
    }

    let groupedArray = newArray.map((param) => obj[param]);
    let grandParameterTotal = groupedArray.reduce((sum, group) => sum + group.parameterTotal, 0);
    let grandReceivedTotalScore = groupedArray.reduce((sum, group) => sum + group.totalReceivedScore, 0);
    return {groupedArray, grandParameterTotal, grandReceivedTotalScore};
}

export const GetMvrScoreCard = async (row) => {
    try{
        const endpoint = `api/evaluation/mvr/get-scorecard-checklist/${row.financialYear}/${row.period}/${row.dealer_code}`

        const res = await apiCall({
            endpoint: endpoint,
            method: "GET",
        })
        if(res.success){
            return groupedScoreCardData(res.scoreCard);
        }else {
            console.log("failed to load data.");
        }
        // return res;
    }catch(error){
        throw error;
    }
}