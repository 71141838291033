import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_URL;

export const LoginUser = async ({ payload }) => {
  try {
    const response = await apiCall({
      endpoint: "api/auth/login",
      method: "POST",
      payload,
    });
    if (response?.success) {
      const obj = {
        user: response?.data,
        token: response?.token,
      };
      localStorage.setItem("user_cred", JSON.stringify(obj));
      return response;
    }
    return response;
  } catch (error) {
    localStorage.removeItem("user_cred");
    throw error;
  }
};

export const apiCall = async ({ endpoint, method = "GET", payload = null, navigate }) => {
  try {
    const user = JSON.parse(localStorage.getItem("user_cred"));
    const defaultHeaders = {
      Authorization: `Bearer ${user?.token}`,
    };

    // Adjust content-type based on payload type
    if (!(payload instanceof FormData)) {
      defaultHeaders["Content-Type"] = "application/json";
    }

    const config = {
      url: `${baseURL}/${endpoint}`,
      method: method,
      headers: defaultHeaders,
    };

    // Check if payload is FormData, in which case Axios handles headers automatically
    if (
      payload &&
      (method === "POST" ||
        method === "post" || method.toLowerCase() === "get" ||
        method.toLocaleUpperCase() === "PUT" ||
        method.toLocaleUpperCase() === "PATCH")
    ) {
      config["data"] = payload;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user_cred");
      setTimeout(() => {
        window.location.href = '/'
      }, 3000);
    }
    throw error;
  }
};

export const Me = async () => {
  const user = JSON.parse(localStorage.getItem("user_cred"));
  if (user && user?.token) {
    return await apiCall({ endpoint: "auth/me", method: "GET" });
  }
};