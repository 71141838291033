import { createSlice } from '@reduxjs/toolkit';
import { getCurrentFinancialYear, getCurrentMonth } from '../utils/helper';

const initialState = {
    financialYear: getCurrentFinancialYear(),
    period: getCurrentMonth(),
    dealerCodes: [],
    regions: [],
    zones: [],
    areas: [],
};

const filterDataSlice = createSlice({
    name: 'filterData',
    initialState,
    reducers: {
        setFilterData(state, action) {
            return { ...state, ...action.payload };
        },
        updateFilterData(state, action) {
            const payload = action.payload;
            for (const [key, value] of Object.entries(payload)) {
                if (state.hasOwnProperty(key)) {
                    state[key] = value;
                } else {
                    console.log(`${key} is not a valid key`);
                }
            }
        },
        resetFilter(state) {
            return initialState;  // Reset to the initial state
        },
    },
});

export const { setFilterData, updateFilterData, resetFilter } = filterDataSlice.actions;
export default filterDataSlice.reducer;
