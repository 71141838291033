import { Box } from "@mui/material";
import { styled } from "@mui/system";


export const ActionModal = styled(Box)(()=>({
    width:'300px',
    height:'250px',
    backgroundColor:'#fff',
    borderRadius:'10px',
    padding:'10px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    gap:'25px',
    '& .IconContain':{
        textAlign:'center',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
        width:'75px',
        height:'75px',
        backgroundColor:'#fff',
        borderRadius:'50%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginTop:'-45px',  
        '& svg':{
            fontSize:'45px',
            color:'red',
        },
    },
    '& .Title':{
        fontSize:'20px',
        textAlign:'center',
    },
    '& p':{
        fontSize:'12px',
        color:'gray',
    },
    '& .ButtonContain':{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:'10px',
        letterSpacing:'1px',
        '& button':{
            border:'none',
            width:'100px',
            height:'35px',
            textAlign:'center',
            fontSize:'13px',
            '&.Delete':{
                backgroundColor:'red',
                color:'#fff',
                // 0000001c
            },
            '&.Cancel':{
                backgroundColor:'#fff',
                color:'#000'
            }
        }
    },
}))