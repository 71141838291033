import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import {
  ButtonContain,
  Card,
  CardContain,
  FieldContain,
  TabsContain,
} from "../../style/Home";
import Grid from "@mui/material/Grid";
import Filter from "../../Components/filters";
// import Table from '../../Components/table/Table';
import ExcelIcon from "../../assets/images/icons/excel.png";
import { GuidelineContain } from "../../style/AM/MoMStatusUpdate";
import {
  CommanTableContain,
  ContainerCustom,
  PaginationContain,
  SmartSearchContain,
} from "../../style/table";
import { GetEscalationData, GetEscalationDays } from "../../services/mvrMomServices";
import SearchIcon from "@mui/icons-material/Search";

const MoMEscalatedPoints = () => {
  const [value, setValue] = useState("momstatus");
  const [isSticky, setIsSticky] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSearchTriggered, setIsSearchTriggered] = useState(Date.now());
  const [tableData, setTableData] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [amValue, setAmValue] = useState("");
  const [zmValue, setZmValue] = useState("");
  const [rmValue, setRmValue] = useState("");
  const filtersValue = useSelector((state) => state.filterData);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleSearchClick = () => {
    setIsSearchTriggered(Date.now());
    setPage(0);
  };

  // position sticky start
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // fetch mom escalation data
  const fetchEscalationData = async () => {
    try {
      let payload = filtersValue;
      payload = {
        ...payload,
        search: debouncedSearchTerm,
        page: page,
        offset: rowsPerPage,
      }
      const response = await GetEscalationData({
        payload: payload,
      });
      if (response && response.moms) {
        console.log("Fetched Data:", response.moms);
        setTableData(response.moms);
        setTotalCount(response.totalCount);
      } else {
        console.error("No moms data found in the response:", response);
      }
    } catch (error) {
      console.error("Error fetching MoM data:", error);
    }
  };

  // fetch mom escalation days
  const fetchMomEscalationDays = async () => {
    try{
      const res = await GetEscalationDays();
      if (res.success){
        setAmValue(res.data.AM)
        setZmValue(res.data.ZM)
        setRmValue(res.data.RM)
      }
    }catch(error){
      console.log('Error on fetching data: ', error)
    }
  }
  useEffect(() => {
    fetchEscalationData();
  }, [page, rowsPerPage, debouncedSearchTerm, isSearchTriggered]);

  useEffect(()=>{
    fetchMomEscalationDays();
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 2000);

    return () => clearTimeout(handler);
  }, [search]);

  const filtersData = [
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "region",
      label: "Region",
      type: "input",
      value: "Delhi",
      disable: true,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "zone",
      label: "Zone",
      type: "input",
      value: "Delhi South",
      disable: true,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "area",
      label: "Area",
      type: "input",
      value: "Lajpat Nagar",
      disable: true,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "dealer_code_name",
      label: "Dealer Code Name",
      type: "select",
      options: [
        { value: "nikhil", label: "1010 - Nikhil Kumar" },
        { value: "Girija", label: "1011 - Girija Kumar" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "year",
      label: "Financial Year",
      type: "select",
      options: [
        { value: "2024-2025", label: "2024-2025" },
        { value: "2023-2024", label: "2023-2024" },
        { value: "2022-2023", label: "2022-2023" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "month",
      label: "Month",
      type: "select",
      options: [
        { value: "january", label: "January" },
        { value: "february", label: "February" },
        { value: "march", label: "March" },
      ],
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "escalated_days",
      label: "Escalated Days",
      type: "select",
      options: [
        { value: "1to4", label: "1 To 4" },
        { value: "5to6", label: "5 To 6" },
        { value: "7to9", label: "7 To 9" },
      ],
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilterValues((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  return (
    <>
      <CardContain>
        <Card mt={1} className="Filter_bg">
          <Grid container spacing={1}>
            <Filter
              filters={filtersData}
              onFilterChange={handleFilterChange}
            ></Filter>
            <Grid item xs={12} sm={8} lg={10}>
              <ButtonContain
                sx={{ height: "100%", alignItems: "end", gap: "5px" }}
              >
                <button className="SearchButton" onClick={handleSearchClick}>
                  Search
                </button>
                <button className="ResetButton">Reset</button>
                {/* <button className="ExportButton">
                  <img src={ExcelIcon} alt="Excel icon" />
                </button> */}
              </ButtonContain>
            </Grid>
          </Grid>
        </Card>
      </CardContain>
      <CardContain>
      <Card mt={2}>
        <ContainerCustom>
          <SmartSearchContain>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              marginleft="auto"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon />
          </SmartSearchContain>
        </ContainerCustom>
          <GuidelineContain>
            <div className="Guidelines">
              <p>
                <span>Escalation Guidelines N = Target Date : </span>{" "}
              </p>
            </div>
            <div className="Guidelines">
              <p> N + {amValue} Day at AM | N + {zmValue} Days at ZM | N + {rmValue} Days at RM</p>
            </div>
          </GuidelineContain>
          {/* <Table headers={headers} data={data} defaultRowsPerPage={10} colSpan={2}/> */}

          {/* Search Field */}
          {/* <ContainerCustom>
            <SmartSearchContain>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                marginleft="auto"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon />
            </SmartSearchContain>
          </ContainerCustom> */}
          {/* Table */}
          <CommanTableContain>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Dealer Code</TableCell>
                  <TableCell>Dealership Name</TableCell>
                  <TableCell>MVR Created Date</TableCell>
                  <TableCell>Discussion/Concern Points</TableCell>
                  <TableCell>Countermeasure Plan</TableCell>
                  <TableCell>Escalated Days</TableCell>
                  <TableCell>Target Date</TableCell>
                  <TableCell>Revised Target Date</TableCell>
                  <TableCell>Escalation Level</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.dealerCode}</TableCell>{" "}
                    {/* Ensure this matches API field */}
                    <TableCell>{item.dealerName}</TableCell>
                    <TableCell>{item.createdAt}</TableCell>
                    <TableCell>{item.checklist}</TableCell>
                    <TableCell>{item.counterMeasure}</TableCell>
                    <TableCell>{item.escDays}</TableCell>
                    <TableCell>{item.targetDate}</TableCell>
                    <TableCell>{item.revisedTargetDate}</TableCell>
                    <TableCell>{item.esc}</TableCell>
                    <TableCell>{item.momStatus}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CommanTableContain>
          {/* Pagination */}
          <PaginationContain>
            <TablePagination
              component="div"
              count={totalCount} // Use the totalCount from API
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </PaginationContain>
        </Card>
      </CardContain>
    </>
  );
};

export default MoMEscalatedPoints;
