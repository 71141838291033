import { apiCall } from "./authServices";

export const GetDropdownData = async () => {
    try {
        const endpoint = `api/dashboard/filtration-parameters`
        const res = await apiCall({
            endpoint: endpoint,
            method: "GET",
        });
        if (res.success) {
            if (res.financialYears && res.financialYears.length) {
                const formattedData = FinancialDisplayValue(res.financialYears)
                res.financialYears = formattedData || []
            }
            if (res.locationData && res.locationData.length) {
                const { regions, zones, areas, dealerCodeName } = formattedRegion(res.locationData)
                res.regions = regions
                res.zones = zones
                res.areas = areas
                res.dealerCodeName = dealerCodeName
            }

            if (res.mvrPeriods && res.mvrPeriods.length) {
                const arr = []
                res.mvrPeriods.forEach(el => {
                    arr.push({ value: el, label: el })
                })
                res.periods = arr
            }
        }
        return res
    } catch (error) {
        throw error;
    }
}

export const GetMvrVisits = async ({ payload }) => {
    try {
        const endpoint = `api/visits/get-mvr-visits`
        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "POST",
        });
        return res
    } catch (error) {
        throw error;
    }
}

export const handleVisitPlan = async ({ payload }) => {
    try {
        const endpoint = `api/visits/schedule-mvr-visit`;
        console.log('API Endpoint:', endpoint);
        console.log('Payload sent to API:', payload);

        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "POST",
        });

        console.log('API Response:', res);
        return res;
    } catch (error) {
        console.error('Error in handleVisitPlan:', error);
        throw error; // Ensure the error is rethrown for the caller to handle.
    }
};
export const handleRevisedPlanAPI = async ({ payload }) => {
    try {
        const endpoint = `api/visits/reschedule-mvr-visit`;
        console.log('API Endpoint:', endpoint);
        console.log('Payload sent to API:', payload);

        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "PATCH",
        });
        console.log('API Response:', res);
        return res;
    }
    catch (error) {
        console.error('Error in handleRevisePlan:', error);
        throw error; // Ensure the error is rethrown for the caller to handle.
    }
}

const formattedRegion = (locationData) => {
    if (locationData && locationData.length) {
        const regions = []
        const zones = []
        const areas = []
        const dealerCodeName = []
        locationData.forEach((el, index) => {
            if (regions.findIndex(region => region.value === el.region) === -1) {
                regions.push({ value: el.region, label: el.region })
            }
            if (zones.findIndex(zone => zone.value === el.zone) === -1) {
                zones.push({ value: el.zone, label: el.zone, region: el.region })
            }
            if (areas.findIndex(area => area.value === el.area) === -1) {
                areas.push({ value: el.area, label: el.area, zone: el.zone, region: el.region })
            }
            if (dealerCodeName.findIndex(dealer => dealer.value === el.dealerCode) === -1) {
                dealerCodeName.push({ value: el.dealerCode, label: el.dealerCodeName, zone: el.zone, area: el.area, region: el.region })
            }
        })
        return { regions, zones, areas, dealerCodeName }
    }
}

const FinancialDisplayValue = (financialYears) => {
    if (financialYears && financialYears.length) {
        const arr = []
        financialYears.forEach(el => {
            arr.push({ value: el, label: el })
        })
        return arr
    }
}