import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SwitchContainer = styled(Box)(() => ({
  "& .switch": {
    marginTop: '30px',
    position: "relative",
    display: "inline-block",
    width: "48px",
    height: "25px",
    backgroundColor: "#ccc",
    borderRadius: "12px",
    cursor: "pointer",
    padding: "2px",
    transition: "background-color 0.2s",
  },
  "& .switch.active": {
    backgroundColor: "#ff4500", 
  },
  "& .switch-handle": {
    position: "absolute",
    top: "2.2px",
    left: "2.2px",
    width: "20px",
    height: "20px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    transition: "0.2s",
  },
  "& .switch.active .switch-handle": {
    transform: "translateX(22px)",
  },
}));
