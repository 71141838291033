import styled from "@emotion/styled";
import { Box, } from "@mui/material";
import { color, display, fontSize, fontWeight, height, width } from "@mui/system";
import { border, borderRadius, padding } from "polished";



export const LogicMasterContainer = styled(Box)(() => ({
    '& .head_t': {
        textAlign: 'center',
        paddingTop: '10px',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#ff4500'
    },
    '& .logic_btn': {
        '& .logic_master_name': {
            width: '40%',
            '@media (max-width:408px)': {
                width: '100%',
            }
        },
        '& .add_logic_btn': {
            color: '#fff',
            border: 'none',
            outline: 'none',
            padding: '8px 23px',
            fontSize: '12px',
            backgroundColor: '#009000',
            height: '40px',
            borderRadius: '5px',
        },
        '& .dropdown_container': {
            display: "flex",
            justifyContent: 'flex-start',
            gap: '20px',
            marginTop: "20px",
            '& .custom_dropdown': {
                width: '40%',
                '@media (max-width:408px)': {
                    width: '100%',
                }
            },
            '& .options_dropdown': {
                width: '40%',
                '@media (max-width:408px)': {
                    width: '100%',
                }
            },
            '@media (max-width:408px)': {
                flexDirection: 'column',
            }

        },
        '& .questions_dropdown': {
            '& .drop_question': {
                width: '200px',
                height: '35px',
                border: 'none',
                curser: 'pointer',

            }
        },

    }


}));