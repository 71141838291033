import { apiCall } from "./authServices";


export const GetCredential = async () => {
    try{
        const endpoint = `api/credentials/get-credentials`
        const res = await apiCall({
            endpoint: endpoint,
            method: "GET",
        });
        return res;
    }catch(error){
        throw error;
    }
}

export const UpdateCredential = async ({payload}) => {
    try{
        const endpoint = `api/credentials/update-credentials`
        const res = await apiCall({
            endpoint: endpoint,
            method: "PUT",
            payload: payload
        });
        return res;
    }catch(error){
        throw error;
    }
}

export const TestCredentialEmail = async ({payload}) => {
    try{
        const endpoint = `api/credentials/test-email`
        const res = await apiCall({
            endpoint: endpoint,
            method: "POST",
            payload: payload
        });
        return res;
    }catch(error){
        throw error;
    }
}
