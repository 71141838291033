import React, { useState } from 'react';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    Tabs,
    Tab,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';

function TicketHistory() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleOpenModal = (ticket) => {
        setSelectedTicket(ticket);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedTicket(null);
    };

    const resolvedTickets = [
        { id: 12345, status: 'Resolved', date: new Date(), details: 'Resolved successfully after investigation.' },
        { id: 11223, status: 'Resolved', date: new Date(), details: 'Issue fixed with a patch.' }
    ];

    const pendingTickets = [
        { id: 67890, status: 'Pending', date: new Date(), details: 'Waiting for customer feedback.' }
    ];

    const formatDate = (date) => {
        return date.toLocaleString(); // Format the date to a readable string
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom style={{ color: '#ff4500', marginTop: '2rem' }}>
                Ticket History
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Below is the list of your past tickets:
            </Typography>

            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="ticket status tabs">
                <Tab label="Pending Tickets" />
                <Tab label="Resolved Tickets" />
            </Tabs>

            <List>
                {(selectedTab === 0 ? pendingTickets : resolvedTickets).map((ticket) => (
                    <ListItem key={ticket.id} style={{ borderBottom: '1px solid #ddd', paddingBottom: '1rem' }}>
                        <Box style={{ flex: 1 }}>
                            <ListItemText
                                primary={`Ticket ID: ${ticket.id}`}
                                secondary={`Status: ${ticket.status}`}
                            />
                            <Button
                                variant="text"
                                onClick={() => handleOpenModal(ticket)}
                                style={{ marginTop: '0.5rem', padding: 0, textDecoration: 'underline', fontSize: '14px' }}
                            >
                                View Details
                            </Button>
                        </Box>
                        <Box style={{ textAlign: 'right' }}>
                            <Typography variant="body2" color="textSecondary">
                                {formatDate(ticket.date)}
                            </Typography>
                        </Box>
                    </ListItem>
                ))}
            </List>

            {/* Modal for Ticket Details */}
            <Dialog open={modalOpen} onClose={handleCloseModal}>
                <DialogTitle>Ticket Details</DialogTitle>
                <DialogContent>
                    {selectedTicket && (
                        <Box>
                            <Typography variant="body1"><strong>Ticket ID:</strong> {selectedTicket.id}</Typography>
                            <Typography variant="body1"><strong>Status:</strong> {selectedTicket.status}</Typography>
                            <Typography variant="body1"><strong>Date:</strong> {formatDate(selectedTicket.date)}</Typography>
                            <Typography variant="body2" style={{ marginTop: '1rem' }}>
                                {selectedTicket.details}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default TicketHistory;
