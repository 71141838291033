import React, { useEffect, useState } from 'react'
import { ApprovalButton, ApprovalConatin, ApprvalFielContain, CloseButton } from '../../style/HO/Approval'
import { CommanTableContain } from '../../style/table'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Card, CardContain } from '../../style/Home'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom'
import { GetAllHo, GetApprovalHistory, SendForApproval, UpdateApproveHistory } from '../../services/approvalService'
import MultiSelectDropdown from '../../Components/multiselect/MultiSelect'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { extractDate } from '../../utils/helper'
// import SearchableDropdown from '../../Components/multiselect'
import { Loader } from '../../utils/loader'
import { Button} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom'

const Approval = () => {
  const location = useLocation();
  const [selectedHoData, setSelectedHoData] = useState([])
  const [selectedHo, setSelectedHo] = useState([]);
  const [loader, setLoader] = useState(false)
  const [hoData, setHoData] = useState([])
  const { data } = location.state || {};
  const [mvrData, setMvrData] = useState({});
  const [approvalDetails, setApprovalDetails] = useState([]);
  const authoritySelectHeader = ["S.No.", "Employee Code", "Employee Name", "Role", "Status", "Submitted Date", "action"];
  const mvrapprovalHeader = ["S.No.", "Employee Code", "Sender Name", "Role", "Status", "Submitted Date", "Accepted / Rejected",  "Remarks", "action"];
  const historyHeaders = ["S.No.", "Employee Code", "Employee Name", "Role", "Level", "Status", "Remarks", "Approval / Return Date"];

  const navigate = useNavigate();

  const fetchHoData = async () => {
    try {
      const res = await GetAllHo()
      if (res.success) {
        setHoData(res.data)
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  const fetchApprovalHistory = async () => {
    try {
      if (data?.id) {
        setLoader(true)
        const res = await GetApprovalHistory({ mvrId: data.id })
        if (res.success) {
          if (res?.data) {
            // setDisable(true)
          }
          setMvrData(res.data)
        }

      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    fetchHoData()
  }, [])

  useEffect(() => {
    fetchApprovalHistory()
  }, [])


  const handleSelectChange = (event) => {
    const value = typeof event === 'string' ? event : event?.target?.value;
    const updatedSelectedHo = selectedHo.includes(value)
      ? selectedHo.filter(el => el !== value)
      : [...selectedHo, value];

    const updatedSelectedHoData = selectedHo.includes(value)
      ? selectedHoData.filter(el => el.value !== value)
      : [...selectedHoData, hoData.find(el => el.value === value)];

    setSelectedHo(updatedSelectedHo);
    setSelectedHoData(updatedSelectedHoData);

  };

  const handleRemove = (value) => {
    if (value) {
      handleSelectChange(value)
    }
  }

  const handleSaveForApproval = async () => {
    try {
      if (data?.id) {
        setLoader(true)
        const res = await SendForApproval({ mvrId: data.id, payload: selectedHoData })
        if (res?.success) {
          toast.success('MVR sent for approval.')
          setMvrData([...selectedHoData])
          setSelectedHoData([])
          setSelectedHo([])
          await fetchApprovalHistory()
        }
      }
    } catch (error) {
      setLoader(false)
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  }

  if (!data?.id) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <h3>Failed to load data</h3>
      </Box>
    );
  }


  const handleSubmit = async (e, approval, index) => {
    e.preventDefault();
    const approvalData = approvalDetails[index];
    if (!approvalData || !approvalData.action || !approvalData.remark) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const res = await UpdateApproveHistory({ mvrId: data?.id, approvalId: approval?.approvalId, payload: approvalData })
      if (res?.success) {
        toast.success('MVR status has been updated.')
        await fetchApprovalHistory()
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };


  const handleApprovalChange = (index, field, value) => {
    const updatedDetails = [...approvalDetails];
    if (!updatedDetails[index]) {
      updatedDetails[index] = {};
    }
    updatedDetails[index][field] = value;
    setApprovalDetails(updatedDetails);
  };

  const handleViewClick =() => {
    navigate(`/mvr-preview`,{ state: {mvrId: data.id}});
  };

  return (
    <>
      {loader && <Loader fullScreen={true} />}
      <ToastContainer />
      <ApprovalConatin>
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <h4 className="ApprovalTitle">
        {data.versionName} ({data.procedureNumber})
      </h4>
      <Button 
        sx={{
          backgroundColor: '#ff4500',  
          color: 'white',              
          '&:hover': {
            backgroundColor: '#ff7f50', 
          }
        }}
        onClick={() => handleViewClick(data.id)}
      >
        <RemoveRedEyeIcon />
      </Button>
    </Box>
    <CardContain>
          <Card mt={2}>
            <Box sx={{ display: 'flex', alignItems: 'end', gap: '10px', justifyContent: 'start', marginBottom: '15px', flexWrap: 'wrap' }}>
              <ApprvalFielContain>
                <label htmlFor="addApprovel">Add Approve Authority</label>
                <MultiSelectDropdown
                  data={hoData}
                  selectedValues={selectedHo}
                  onChange={handleSelectChange}
                  label='Select'
                  disabled={mvrData?.approvalPending?.length > 0 || mvrData?.approvalHistory?.length > 0}
                />
              </ApprvalFielContain>
              <ApprovalButton disabled={mvrData?.approvalPending?.length > 0 || mvrData?.approvalHistory?.length > 0} onClick={handleSaveForApproval} variant='contained'>Send for Approval</ApprovalButton>
            </Box>
            <CommanTableContain>
              <Table>
                <TableHead>
                  <TableRow>
                    {authoritySelectHeader.map((header, index) => (
                      <TableCell key={index}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {selectedHoData.length > 0 ? (
                    selectedHoData.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{data?.value}</TableCell>
                        <TableCell>{data.display}</TableCell>
                        <TableCell>{data.role}</TableCell>
                        <TableCell>Pending</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <CloseButton onClick={() => handleRemove(data?.value)}>
                            <CloseIcon />
                          </CloseButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                        Please select authority
                      </TableCell>
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </CommanTableContain>
          </Card>
        </CardContain>
        <h4 style={{ marginTop: '20px' }} className='ApprovalTitle'>{data.versionName} ({data.procedureNumber})</h4>
        <CardContain>
          <Card mt={2}>
            <CommanTableContain>
              <Table>
                <TableHead>
                  <TableRow>
                    {mvrapprovalHeader.map((header, index) => (
                      <TableCell key={index}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mvrData?.approvalPending && mvrData.approvalPending.length > 0 ? (
                    mvrData.approvalPending.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{data?.senderId}</TableCell>
                        <TableCell>{data?.senderName}</TableCell>
                        <TableCell>{data?.senderRole?.toUpperCase()}</TableCell>
                        <TableCell>Pending</TableCell>
                        <TableCell>{extractDate(data?.submittedAt)}</TableCell>
                        <TableCell>
                          <select
                            name="accepet"
                            id="accepet"
                            disabled={!data?.editable}
                            value={approvalDetails[index]?.action || ""}
                            onChange={(e) => handleApprovalChange(index, "action", e.target.value)}
                          >
                            <option value="">select</option>
                            <option value="approve">Accept</option>
                            <option value="send back">Sent Back</option>
                          </select>
                        </TableCell>
                        <TableCell><textarea
                          name="remark"
                          disabled={!data?.editable}
                          id="remark"
                          placeholder="Remark"
                          value={approvalDetails[index]?.remark || ""}
                          onChange={(e) => handleApprovalChange(index, "remark", e.target.value)}
                        /></TableCell>
                        <TableCell>
                          <button
                            disabled={!data?.editable}
                            className='Submit' onClick={(e) => handleSubmit(e, data, index)}>Submit</button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                        MVR are not available for approval.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </CommanTableContain>
          </Card>
        </CardContain>
        <h4 style={{ marginTop: '20px' }} className='ApprovalTitle'>{data.versionName} ({data.procedureNumber})</h4>
        <CardContain>
          <Card mt={2}>
            <CommanTableContain>
              <Table>
                <TableHead>
                  <TableRow>
                    {historyHeaders.map((header, index) => (
                      <TableCell key={index}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mvrData?.approvalHistory && mvrData.approvalHistory.length > 0 ? (
                    mvrData.approvalHistory.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{data?.reciverId}</TableCell>
                        <TableCell>{data?.reciverName?.split("- ")?.[1]}</TableCell>
                        <TableCell>{data?.senderRole?.toUpperCase()}</TableCell>
                        <TableCell>{`L${data?.approvalSequence}`}</TableCell>
                        <TableCell>{data?.approvalStatus}</TableCell>
                        <TableCell>{data?.actionRemark}</TableCell>
                        <TableCell>{extractDate(data?.actionTakenAt)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </CommanTableContain>
          </Card>
        </CardContain>
      </ApprovalConatin>
    </>
  );
}

export default Approval;
