import { Box, Button, TableContainer } from "@mui/material";
import { color, fontSize, minWidth, styled, width } from "@mui/system";
import { Colors } from "../theme";
import { border, borderRadius, padding } from "polished";



export const CommanTableContain = styled(TableContainer)(() => ({
    maxHeight: '400px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 'none',
        borderTop: 'none',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    '& table thead tr:first-of-type th': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor: '#bec4cb',
        color: Colors.black,
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            display: 'block',
        },
    },
    '& table tr td': {
        backgroundColor: '#fff',
        padding: '7px',
        '& .Submit': {
            backgroundColor: 'green',
            color: '#fff',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            '&:disabled': {
                backgroundColor: 'gray',
                color: '#fff',
                cursor: 'not-allowed',
            }
        },
        '& .buttonRed': {
            color: 'red',
            cursor: 'pointer'
        },
        '& .revise_target': {
            width: '200px',
            '& .MuiInputBase-formControl': {
                height: '40px',
            },

        },
        '& .input_width': {
            width: '200px',
            height: '40px'
        },
        '& .upload_icon_m': {
            display: 'flex',
            margin: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        '& .img_p_t': {
            marginTop: '5px',
            '& .img_table_preview': {
                width: '40px',
                height: '40px',
                cursor: 'pointer',
            },
        },
        '& button.action': {
            color: '#000',
            border: 'none',
            outline: 'none',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            height: '30px',
            '&.save': {
                color: '#fff',
                backgroundColor: '#009000',
                '&:disabled': {
                    backgroundColor: 'gray',
                    color: "#fff",
                    cursor: 'not-allowed',
                }
            },

        },
        '& a': {
            fontSize: '12px',
            color: '#007FFF',
        },
        '& input,& textarea, & select': {
            fontSize: '12px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
        },
        '& .MuiStack-root': {
            padding: '0',
            '& .MuiFormControl-root': {
                minWidth: '150px',
                maxWidth: '150px',
                margin: "auto",
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                '& label': {
                    fontSize: '11px',
                    top: '-5px'
                },
                '& .MuiInputBase-input': {
                    padding: '9px 14px',
                    height: 'auto',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                }
            }
        }
    },
    '& table tr:last-of-type td': {
        borderBottom: 'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr': {
        '&:hover': {
            '& td.Form_accepted,& td.Form_submitted': {
                backgroundColor: Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration': {
                backgroundColor: Colors.formInprocessBg,
            },
            '& td.Form_pending': {
                backgroundColor: Colors.formInprocessBg,
            },
        },
        '& td': {
            '&.Form_accepted,&.Form_submitted': {
                color: Colors.formAccepted,
            },
            '&.Form_in-process,&.Form_reconsideration': {
                color: Colors.formInprcess,
            },
            '&.Form_pending': {
                color: Colors.formPending,
            }
        },
        '&.SecondHeader th': {
            backgroundColor: "white",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },

}))



export const CommanTableContainMVRApproval = styled(TableContainer)(() => ({
    maxHeight: '400px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 'none',
        borderTop: 'none',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    '& table thead tr:first-of-type th': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor: '#bec4cb',
        color: Colors.black,
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            display: 'block',
        },
    },
    '& .custom-cell': {
        '& .es_input': {
            outline: 'none',
            border: '1px solid #ddd',
        },
        '& .btn_s': {
            backgroundColor: '#009000',
            padding: '5px 10px',
            color: '#fff',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#017301',
            },
        },
    },
    '& table tr td': {
        backgroundColor: '#fff',
        padding: '7px',
        '& .Submit': {
            backgroundColor: 'green',
            color: '#fff',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            '&:disabled': {
                backgroundColor: 'gray',
                color: '#fff',
                cursor: 'not-allowed',
            }
        },

        '& button.action': {
            color: '#000',
            border: 'none',
            outline: 'none',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            height: '30px',
            '&.save': {
                color: '#fff',
                backgroundColor: '#009000',
                '&:disabled': {
                    backgroundColor: 'gray',
                    color: "#fff",
                    cursor: 'not-allowed',
                }
            },

        },
        '& a': {
            fontSize: '12px',
            color: '#007FFF',
        },
        '& input,& textarea, & select': {
            fontSize: '12px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
        },

        '& .MuiStack-root': {
            padding: '0',
            '& .MuiFormControl-root': {
                minWidth: '150px',
                maxWidth: '150px',
                margin: "auto",
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                '& label': {
                    fontSize: '11px',
                    top: '-5px'
                },
                '& .MuiInputBase-input': {
                    padding: '9px 14px',
                    height: 'auto',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                }
            }
        }
    },
    '& table tr:last-of-type td': {
        borderBottom: 'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr': {
        '&:hover': {
            '& td.Form_accepted,& td.Form_submitted': {
                backgroundColor: Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration': {
                backgroundColor: Colors.formInprocessBg,
            },
            '& td.Form_pending': {
                backgroundColor: Colors.formInprocessBg,
            },
        },
        '& td': {
            '&.Form_accepted,&.Form_submitted': {
                color: Colors.formAccepted,
            },
            '&.Form_in-process,&.Form_reconsideration': {
                color: Colors.formInprcess,
            },
            '&.Form_pending': {
                color: Colors.formPending,
            }
        },
        '&.SecondHeader th': {
            backgroundColor: "white",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },

}))


export const SmartSearchContain = styled(Box)(() => ({
    width: '200px',
    position: 'relative',
    // marginLeft:'auto',
    '& .MuiFormControl-root': {
        margin: '0'
    },
    '& label': {
        fontSize: '12px',
    },
    '& input': {
        fontSize: '12px',
        paddingRight: '25px',
        backgroundColor: '#fff',
        '&::placeholder': {
            fontSize: '12px',
        },
    },
    '& svg': {
        position: 'absolute',
        right: '3px',
        top: '7px',
        color: Colors.light_gray,
    },
}))

export const PaginationContain = styled(Box)(() => ({
    '& .MuiTablePagination-select': {
        zIndex: 11,
    },

}))

export const PdfButton = styled(Button)(() => ({
    backgroundColor: 'transparent',
    minWidth: '30px',
    borderRadius: '5px',
    color: Colors.pending,
}))

export const ContainerCustom = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    marginBottom: '10px',
    padding: ' 0',
    borderRadius: '5px',
}))

export const ContainerCustomDealer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    marginBottom: '10px',
    padding: ' 0',
    borderRadius: '5px',
}))
export const ContainerCustomDealerMaster = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    marginBottom: '10px',
    padding: '0',
    borderRadius: '5px',
}))
export const TableModal = styled(Box)(() => ({
    maxWidth: '500px',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column', // Ensures header, body, and footer are stacked vertically
    '& .ModalHeader': {
        position: 'relative',
        padding: '15px 10px',
        backgroundColor: Colors.primary,
        color: Colors.white,
        '& h4': {
            paddingRight: '20px',
            fontWeight: 400,
        },
        '& .CloseButton': {
            position: 'absolute',
            right: '5px',
            top: '5px',
            backgroundColor: 'transparent',
            color: Colors.white,
        },
    },
    '& .ModalBody': {
        flex: 1, // Makes the body take available space between header and footer
        overflow: 'hidden', // Ensures content does not overflow
        display: 'flex', // Centers the image
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        '& img': {
            maxWidth: '400px', // Image fits within the modal width
            maxHeight: '300px', // Image fits within the modal height
            objectFit: 'contain', // Ensures the image retains its aspect ratio
        },

    },
}));

export const CommanTableContainMom = styled(TableContainer)(() => ({
    maxHeight: '450px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 'none',
        borderTop: 'none',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    '& table thead tr:first-of-type th': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor: '#bec4cb',
        color: Colors.black,
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            display: 'block',
        },
    },
    '& table tr td': {
        backgroundColor: '#fff',
        padding: '7px',
        '& .Submit': {
            backgroundColor: 'green',
            color: '#fff',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            '&:disabled': {
                backgroundColor: 'gray',
                color: '#fff',
                cursor: 'not-allowed',
            }
        },
        '& button.action': {
            color: '#000',
            border: 'none',
            outline: 'none',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            height: '30px',
            '&.save': {
                color: '#fff',
                backgroundColor: '#009000',
                '&:disabled': {
                    backgroundColor: 'gray',
                    color: "#fff",
                    cursor: 'not-allowed',
                }
            },

        },
        '& a': {
            fontSize: '12px',
            color: '#007FFF',
        },
        '& input,& textarea, & select': {
            fontSize: '12px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
        },
        '& .MuiStack-root': {
            padding: '0',
            '& .MuiFormControl-root': {
                minWidth: '150px',
                maxWidth: '150px',
                margin: "auto",
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                '& label': {
                    fontSize: '11px',
                    top: '-5px'
                },
                '& .MuiInputBase-input': {
                    padding: '9px 14px',
                    height: 'auto',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                }
            }
        }
    },
    '& table tr:last-of-type td': {
        borderBottom: 'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr': {
        '&:hover': {
            '& td.Form_accepted,& td.Form_submitted': {
                backgroundColor: Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration': {
                backgroundColor: Colors.formInprocessBg,
            },
            '& td.Form_pending': {
                backgroundColor: Colors.formInprocessBg,
            },
        },
        '& td': {
            '&.Form_accepted,&.Form_submitted': {
                color: Colors.formAccepted,
            },
            '&.Form_in-process,&.Form_reconsideration': {
                color: Colors.formInprcess,
            },
            '&.Form_pending': {
                color: Colors.formPending,
            }
        },
        '&.SecondHeader th': {
            backgroundColor: "white",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },

}))

export const CommanTableContainMomPoint = styled(TableContainer)(() => ({
    maxHeight: '450px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 'none',
        borderTop: 'none',
        textAlign: 'center',
        textTransform: 'capitalize',
    },

    '& table thead tr:first-of-type th': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor: '#bec4cb',
        color: Colors.black,
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            display: 'block',
        },
    },
    '& table tr td': {
        backgroundColor: '#fff',
        padding: '7px',
        '& .Submit': {
            backgroundColor: 'green',
            color: '#fff',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            '&:disabled': {
                backgroundColor: 'gray',
                color: '#fff',
                cursor: 'not-allowed',
            },
            '& .target_date_box': {
                fontSize: '13px',
                color: '#000',
            },

        },
        '& button.action': {
            color: '#000',
            border: 'none',
            outline: 'none',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            height: '30px',
            '&.save': {
                color: '#fff',
                backgroundColor: '#009000',
                '&:disabled': {
                    backgroundColor: 'gray',
                    color: "#fff",
                    cursor: 'not-allowed',
                }
            },

        },
        '& a': {
            fontSize: '12px',
            color: '#007FFF',
        },
        '& input,& textarea, & select': {
            fontSize: '12px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
        },
        '& .MuiStack-root': {
            padding: '0',
            '& .MuiFormControl-root': {
                minWidth: '150px',
                maxWidth: '150px',
                margin: "auto",
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                '& label': {
                    fontSize: '11px',
                    top: '-5px'
                },
                '& .MuiInputBase-input': {
                    padding: '9px 14px',
                    height: 'auto',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                }
            }
        }
    },
    '& table tr:last-of-type td': {
        borderBottom: 'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr': {
        '&:hover': {
            '& td.Form_accepted,& td.Form_submitted': {
                backgroundColor: Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration': {
                backgroundColor: Colors.formInprocessBg,
            },
            '& td.Form_pending': {
                backgroundColor: Colors.formInprocessBg,
            },
        },
        '& td': {
            '&.Form_accepted,&.Form_submitted': {
                color: Colors.formAccepted,
            },
            '&.Form_in-process,&.Form_reconsideration': {
                color: Colors.formInprcess,
            },
            '&.Form_pending': {
                color: Colors.formPending,
            }
        },
        '&.SecondHeader th': {
            backgroundColor: "white",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },

}))

export const HoReportRadio = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '20px',
    flexWrap: 'wrap',
    margin: '20px 0',
    '& label': {
        display: 'flex',
        alignItems: 'center',
        '& input': {
            marginRight: '5px',
        },
    },
}))

export const CommanTableContainHierachyMaster = styled(TableContainer)(() => ({
    maxHeight: '450px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 'none',
        borderTop: 'none',
        textAlign: 'center',
        textTransform: 'capitalize',

    },
    '& table thead tr:first-of-type th': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor: '#bec4cb',
        color: Colors.black,
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            display: 'block',
        },
    },
    '& table tr td': {
        backgroundColor: '#fff',
        padding: '7px',

        '& input': {
            fontSize: '12px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            outline: 'none',
            border: '1px solid #ddd',
        },
        '& .select_btn': {
            border: '1px solid #ddd',
            padding: '7px 12px',
            borderRadius: '10px',


        },
        '& .select_btn.selected': {
            backgroundColor: '#ff4500',
            color: "#fff",
        },

        '& .MuiStack-root': {
            padding: '0',
            '& .MuiFormControl-root': {
                minWidth: '150px',
                maxWidth: '150px',
                margin: "auto",
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                '& label': {
                    fontSize: '11px',
                    top: '-5px'
                },
                '& .MuiInputBase-input': {
                    padding: '9px 14px',
                    height: 'auto',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                }
            }
        }
    },
    '& table tr:last-of-type td': {
        borderBottom: 'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr': {
        '&:hover': {
            '& td.Form_accepted,& td.Form_submitted': {
                backgroundColor: Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration': {
                backgroundColor: Colors.formInprocessBg,
            },
            '& td.Form_pending': {
                backgroundColor: Colors.formInprocessBg,
            },
        },
        '& td': {
            '&.Form_accepted,&.Form_submitted': {
                color: Colors.formAccepted,
            },
            '&.Form_in-process,&.Form_reconsideration': {
                color: Colors.formInprcess,
            },
            '&.Form_pending': {
                color: Colors.formPending,
            }
        },
        '&.SecondHeader th': {
            backgroundColor: "white",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },

}))


export const CommanTableContainDealerMaster = styled(TableContainer)(() => ({
    maxHeight: '450px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 'none',
        borderTop: 'none',
        textAlign: 'center',
        textTransform: 'capitalize',

    },
    '& table thead tr:first-of-type th': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor: '#bec4cb',
        color: Colors.black,
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            display: 'block',
        },
    },
    '& table tr td': {
        backgroundColor: '#fff',
        padding: '7px',

        '& input': {
            fontSize: '12px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            outline: 'none',
            border: '1px solid #ddd',
        },
        '& .select_btn': {
            border: '1px solid #ddd',
            padding: '7px 12px',
            borderRadius: '10px',
        },
        '& .select_btn.selected': {
            backgroundColor: '#ff4500',
            color: "#fff",
        },


        '& .MuiStack-root': {
            padding: '0',
            '& .MuiFormControl-root': {
                minWidth: '150px',
                maxWidth: '150px',
                margin: "auto",
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '5px',
                '& label': {
                    fontSize: '11px',
                    top: '-5px'
                },
                '& .MuiInputBase-input': {
                    padding: '9px 14px',
                    height: 'auto',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                }
            }
        }
    },
    '& table tr:last-of-type td': {
        borderBottom: 'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr': {
        '&:hover': {
            '& td.Form_accepted,& td.Form_submitted': {
                backgroundColor: Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration': {
                backgroundColor: Colors.formInprocessBg,
            },
            '& td.Form_pending': {
                backgroundColor: Colors.formInprocessBg,
            },
        },
        '& td': {
            '&.Form_accepted,&.Form_submitted': {
                color: Colors.formAccepted,
            },
            '&.Form_in-process,&.Form_reconsideration': {
                color: Colors.formInprcess,
            },
            '&.Form_pending': {
                color: Colors.formPending,
            }
        },
        '&.SecondHeader th': {
            backgroundColor: "white",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },

}))