// CreateManualCard.js
import React, { useState } from 'react';
import { CardCount, CardCreateManual, IconButtonContain, PublishButton } from '../../../style/HO/CreateManualCard';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { toTitleCase } from '../../../utils/helper';
import Grid from '@mui/material/Grid';

const CreateManualCard = ({ data, index, onDelete, onPublish, onCopy }) => {
  const navigate = useNavigate();
  const handlePdf = () => {
    navigate(`/mvr-preview`,{ state: {mvrId: data.id}});
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <CardCreateManual>
        <CardCount className='SnoText'>{index + 1}</CardCount>
        <CardCount><button onClick={() => onDelete(data.id)} className='DeleteButton'><CloseIcon /></button></CardCount>
        <ul>
          <li>MVR Version Name : <span>{data.versionName}</span></li>
          <li>MVR Procedure Number : <span>{data.procedureNumber}</span></li>
          <li>Created Date & Time : <span>{data.createdAt}</span></li>
          <li>Submitted Date : <span>{data.submittedDate}</span></li>
          <li>Status : <span>{toTitleCase(data.mvrStatus)}</span></li>
          <li>Approved / Return Date : <span>{data.approveReturnDate ? data.approveReturnDate : "N/A"}</span></li>
          <li>Valid From : <span>{data.validFrom}</span></li>
          <li>Valid To : <span>{data.validTo}</span></li>
          <li>
            Action :
            <IconButtonContain>
              <Link to='/mvrmanualcreation' state={{ mvrData: data }}><button className='Edit'><EditNoteIcon /></button></Link>
              {/* <Link to='/mvrmanualcreation'><button onClick={() => onEdit(data.id)} className='Edit'><EditNoteIcon/></button></Link> */}
              {/* <Link  to='/mvrmanualcreation'><button onClick={() => onEdit(data.id)} className='Edit'><EditNoteIcon/></button></Link> */}
              <button className='Pdf' onClick={handlePdf}><PictureAsPdfIcon/></button>              {/* <button onClick={() => onCopy(data.id)} className='Copy'><FileCopyIcon/></button> */}
            </IconButtonContain>
          </li>
          {/* <li> 
          Publish MVR : <Link to="/approval" state={{ data }}><PublishButton className='publish_disable_btn' disabled={data?.mvrStatus === 'draft' || false} onClick={() => onPublish(data)}>Publish</PublishButton></Link>
        </li> */}
          <li>
            Publish MVR : <Link to="/approval" state={{ data }}><PublishButton
              className="publish_disable"
              disabled={data?.mvrStatus === 'draft' || false}
              style={{
                backgroundColor: (data?.mvrStatus === 'draft' || false) ? '#ddd' : 'rgb(0, 128, 0)',
                color: '#fff',
                cursor: (data?.mvrStatus === 'draft' || false) ? 'not-allowed' : 'pointer',
              }}
              onClick={() => onPublish(data)}
            >
              Approve
            </PublishButton></Link>
          </li>
        </ul>
      </CardCreateManual>
    </Grid>
  );
};

export default CreateManualCard;
