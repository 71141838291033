import { apiCall } from "./authServices";

export const GetMvr = async () => {
    try{
        const endpoint = "api/logic/logic-get-mvr";
        const res = await apiCall({
            endpoint: endpoint,
            method: "GET",
        })
        return res;
    }catch(error){
        throw error;
    }
}

export const GetSelectedMvrChecklist = async (mvrId) => {
    try{
        const endpoint = `api/logic/logic-get-checklists/${mvrId}`
        const res = apiCall({
            endpoint: endpoint,
            method: "GET",
        })
        return res;
    }catch(error){
        throw error;
    }
}

export const AddLogic = async ({payload}) => {
    try{
        const endpoint = "api/logic/add-logic";
        let res = await apiCall({
            endpoint: endpoint,
            method: "POST",
            payload: payload,
        })
        return res;
    }catch(error){
        throw error;
    }
}

export const GetLogic = async ({payload}) => {
    try{
        const endpoint = "api/logic/get-logics";
        let res = await apiCall({
            endpoint: endpoint,
            method: "POST",
            payload: payload,
        })
        return res;
    }catch(error){
        throw error;
    }
}

export const DeleteLogics = async (logicId) => {
    try{
        const endpoint = `api/logic/delete-logics/${logicId}`
        let res = await apiCall({
            endpoint: endpoint,
            method: "DELETE",
        })
        return res;
    }catch(error){
        throw error;
    }
}