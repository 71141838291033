import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Menu as MenuIcon,
  AccountCircle,
  Close,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PreviewIcon from "@mui/icons-material/Preview";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DvrIcon from "@mui/icons-material/Dvr";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AddCardIcon from "@mui/icons-material/AddCard";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  HeaderContain,
  HeaderTitle,
  SideBarHeadContain,
  SideBarList,
  OnlineOffline,
  OnlineOnline
} from "../../style/header/Header";
import { useSelector, useDispatch } from "react-redux";
import { useMatches } from "../../style/theme";
import { setDrawer } from "../../features/DrawerSlice";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../services/authServices";
import { Roles } from "../../utils/constants";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TableChartIcon from "@mui/icons-material/TableChart";
import NotificationModal from "../../pages/Notification";
import { setUser, logout } from "../../features/userSlice";
import { MeData } from "../../services/MeServices";
import {
  GetPopUpCount
} from "../../services/popUpService";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import KeyIcon from '@mui/icons-material/Key';
const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { matches } = useMatches();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [updateUnreadCount, setUpdateUnreadCount] = useState(0);
  const drawer = useSelector((state) => state.drawer);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (matches) {
      setDrawerOpen(false);
    }
  }, [location, matches]);
  useEffect(() => {
    setDrawerOpen(!matches);
  }, [matches]);
  const [drawerOpen, setDrawerOpen] = useState(() => !matches);
  const [openDropdown, setOpenDropdown] = useState(null);
  const RoleType = useSelector((state) => state.role);

  useEffect(() => {
    if (matches) {
      dispatch(setDrawer(true));
    } else {
      dispatch(setDrawer(!drawerOpen));
    }
  }, [drawerOpen, dispatch, matches]);

  const getUserData = async () => {
    try {
      const userData = await MeData();
      if (userData?.success) {
        setUserData(userData?.user);
        dispatch(setUser(userData?.user));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const popUpCount = async () => {
    try {
      const response = await GetPopUpCount();
      if (response?.success) {
        setUnreadCount(response.unreadCount);
      }
    } catch (error) {
      console.log("Error fetching unread count:", error);
    }
  };


  useEffect(() => {
    popUpCount();
  }, [updateUnreadCount]);

  let navLinks = [
    { label: "Dashboard", link: "/home", icon: <DashboardIcon /> },
    {
      label: "MVR",
      link: "#",
      icon: <DvrIcon />,
      sublinks: [
        {
          label: "Mvr Evaluation Status",
          link: "/amdstatus",
          icon: <AddCardIcon />,
        },
        {
          label: "MoM Status Update",
          link: "/momstatus",
          icon: <AddCardIcon />,
        },
      ],
    },

    // {
    //   label: "QVR",
    //   link: "#",
    //   icon: <DvrIcon />,
    //   sublinks: [
    //     { label: "Create QVR Manual", link: "/qvrmanualcreation", icon: <DateRangeIcon /> },
    //   ],
    // },
    {
      label: "Schedule Visit",
      link: "/schedulevisit",
      icon: <CalendarMonthIcon />,
    },
    {
      label: "MoM Escalated Points",
      link: "/momescalatedpoints",
      icon: <AddCardIcon />,
    },
    { label: "Reports", link: "/reports", icon: <SummarizeIcon /> },
  ];

  if (userData?.userRightCreateManual) {
    navLinks.push({
      label: "Manage",
      link: "#",
      icon: <DvrIcon />,
      sublinks: [
        {
          label: "Create MVR Manual",
          link: "/hocreatemanual",
          icon: <AddCardIcon />,
        },
        { label: "MVR Schedule", link: "/mvr-schedule", icon: <AddCardIcon /> },
        { label: "MVR Approval", link: "/mvr-approval", icon: <AddCardIcon /> },
        {
          label: "Hierarchy Master",
          link: "/hierarchy-master",
          icon: <AddCardIcon />,
        },
        {
          label: "Logic Master",
          link: "/mvr-logic-master",
          icon: <AddCardIcon />,
        },
        {
          label: "Escalation Days",
          link: "/mom-escalation",
          icon: <AddCardIcon />,
        },
        {
          label: "Dealer Master",
          link: "/dealer-master",
          icon: <AddCardIcon />,
        },
        {
          label: "Pop Up Notification",
          link: "/popup-notification",
          icon: <NotificationAddIcon />,
        },
        {
          label: "Credentials",
          link: "/credential",
          icon: < KeyIcon />,
        },
      ],
    });
  }

  navLinks = [
    ...navLinks,
    { label: "Gallery", link: "/gallery", icon: <TableChartIcon /> },
    { label: "Support", link: "/support", icon: <SupportAgentOutlinedIcon /> },
    { label: "Log Out", link: "/", icon: <LogoutOutlinedIcon /> },
  ];

  const handleToggle = (label) => {
    setOpenDropdown((prev) => (prev === label ? null : label)); // Toggle open dropdown
  };

  const routeToTitleMap = {
    "/mvrmanualcreation": "Create MVR Manual",
    "/qvrmanualcreation": "Create QVR Manual",
    // Add more mappings as needed
  };

  // Determine the header title based on the current route
  const headerTitle = routeToTitleMap[location.pathname] || "Easy Go Audit";

  const handleLinkClick = (event, link) => {
    event.preventDefault();
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  };


  const [showOffline, setShowOffline] = useState(false);
  const [showOnline, setShowOnline] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setShowOffline(false); 
      setShowOnline(true); 
      setTimeout(() => setShowOnline(false), 3000); 
    };

    const handleOffline = () => {
      setShowOffline(true);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    if (!navigator.onLine) {
      setShowOffline(true); 
    }
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);


  return (
    <HeaderContain className="HeaderContain">
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "white" }}
        className={`Appbar ${drawer ? "" : "DrawerOpen"}`}
      >
        <OnlineOffline show={showOffline}>
          <h1>You are Offline</h1>
        </OnlineOffline>
        <OnlineOnline show={showOnline}>
          <h1>Back Online</h1>
        </OnlineOnline>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2, color: "#000", fontSize: "24px" }}
            className={drawer ? "" : "MenuButton"}
          >
            <MenuIcon />
          </IconButton>
          {/* <HeaderTitle variant="h6">EasyGo Audit</HeaderTitle> */}
          <HeaderTitle variant="h6">
            <Marquee gradient={false} speed={30}>
              {headerTitle}
            </Marquee>
          </HeaderTitle>

          <button className="NotificationIcon" onClick={handleOpen}>
            <NotificationsActiveIcon />
            <span className="NotificationCount" style={{ top: "-11px", right: "-14px" }}>{unreadCount}</span>
          </button>
          <NotificationModal open={open} handleClose={handleClose} handleUpdateUnreadCount={setUpdateUnreadCount} />
        </Toolbar>

      </AppBar>

      <Drawer
        sx={{
          width: 260,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 260,
            boxSizing: "border-box",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen} // Controls visibility based on state
        // onClose={toggleDrawer}
        ModalProps={{
          disableBackdropClick: true,
        }}
      >
        <SideBarHeadContain>
          <div className="SideBar">
            <Link to={"/profile"}>
              <IconButton sx={{ padding: "0" }}>
                <AccountCircle fontSize="large" />
              </IconButton>
            </Link>

            {/* User's Name in the center */}
            <div className="user_name_text">
              <span>{userData?.userName}</span>
              <span>{Roles[userData?.roleType]}</span>
              <span>{userData?.userId}</span>
            </div>
          </div>
          <IconButton
            onClick={() => setDrawerOpen(false)}
            sx={{ padding: "0" }}
          >
            <Close />
          </IconButton>
        </SideBarHeadContain>
        <SideBarList className="SidebarList">
          {Array.isArray(navLinks) && navLinks.length > 0 ? (
            navLinks.map((link, index) => (
              <React.Fragment key={index}>
                {/* Render top-level item */}
                {!link.sublinks ? (
                  <ListItem className="Border_top">
                    {link.label === "Log Out" ? (
                      <Link
                        to={link.link}
                        onClick={(e) => handleLinkClick(e, link)}
                        style={{ textDecoration: "none", color: "inherit" }}
                        className="LinkSideBar"
                      >
                        {link.icon}
                        <ListItemText primary={link.label} />
                      </Link>
                    ) : (
                      <Link
                        to={link.link}
                        onClick={link?.action}
                        style={{ textDecoration: "none", color: "inherit" }}
                        className="LinkSideBar"
                      >
                        {link.icon}
                        <ListItemText primary={link.label} />
                      </Link>
                    )}
                  </ListItem>
                ) : (
                  <>
                    {/* Render item with sublinks */}
                    <ListItem
                      onClick={() => handleToggle(link.label)}
                      aria-haspopup="true"
                      className="LinkSideBar Border_top"
                    >
                      {link.icon}
                      <ListItemText primary={link.label} />
                      <IconButton>
                        {openDropdown === link.label ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </ListItem>

                    {/* Render sublinks */}
                    <Collapse
                      in={openDropdown === link.label}
                      timeout="auto"
                      unmountOnExit
                      className="ContainDropDown"
                    >
                      <List disablePadding ms={2} className="DropDown">
                        {Array.isArray(link.sublinks) &&
                          link.sublinks.length > 0 ? (
                          link.sublinks.map((sublink, subIndex) => (
                            <ListItem
                              key={`sub-${link.label}-${subIndex}`}
                              sx={{ paddingLeft: 4 }}
                            >
                              <Link
                                to={sublink.link}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                                className="LinkSideBar"
                              >
                                {sublink.icon}
                                <ListItemText primary={sublink.label} />
                              </Link>
                            </ListItem>
                          ))
                        ) : (
                          <ListItem>
                            <ListItemText primary="No sublinks available" />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </>
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No nav links available" />
            </ListItem>
          )}
        </SideBarList>
      </Drawer>


    </HeaderContain>
  );
};

export default Sidebar;
