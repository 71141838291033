import React, { useState } from "react";
import {
    ModalContainModalPopUp
} from "../../style/HO/MVRManualCreation";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function PopUpModal({ onClose, data: content }) {
    const Drawer = useSelector((state) => state.drawer);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleNext = () => {
        if (currentImageIndex < content.length - 1) {
            setCurrentImageIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex((prevIndex) => prevIndex - 1);
        }
    };

    return (
        <div>
            <ToastContainer />
            <ModalContainModalPopUp className={Drawer ? " " : "DrawerOpen"}>
                <div className="CheckpointModal">
                    <div className="ModalHeader">
                        <h4>Popup Notification</h4>
                        {currentImageIndex === content.length - 1 && (
                            <button
                                className="CloseButton"
                                onClick={() => onClose && onClose()}
                            >
                                <CloseIcon />
                            </button>
                        )}
                    </div>
                    <div className="ModalBody">
                        <div className="content-container" style={{ scrollBehavior: "smooth" }}>
                            <div className="modal_images_group_scroll">
                                {content[currentImageIndex].documentType === "image" ? (

                                    <img
                                        src={content[currentImageIndex].documentUrl}
                                        alt={"Notification"}
                                        style={{ width: "100%", height: "auto" }}
                                    />

                                ) : (
                                    <div>
                                        <a
                                            href={content[currentImageIndex].documentUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"

                                        >
                                            {/* {content[currentImageIndex].documentUrl} */}
                                            <button className="popup_notification_preview">Preview</button>
                                        </a>
                                    </div>
                                )}

                                <p style={{ paddingBottom: '50px', paddingTop: '10px' }}><strong>Message:</strong> {content[currentImageIndex].message}</p>
                            </div>
                        </div>
                        <div className="ModalFooter">
                            {currentImageIndex !== 0 && (
                                <button onClick={handlePrevious} className="PreviousButton">
                                    <NavigateBeforeIcon />
                                </button>
                            )}

                            {currentImageIndex < content.length - 1 && (
                                <button onClick={handleNext} className="next_btn_modal">
                                    <NavigateNextIcon />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </ModalContainModalPopUp>
        </div>
    );
}

export default PopUpModal;
