import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Colors } from "../theme";


export const CreateManualCardContain = styled(Grid)(()=>({

}))
export const ManualTital = styled(Box)(()=>({
    marginTop:'10px'
}))
export const CardCreateManual = styled(Box)(()=>({
    position:'relative',
    borderRadius:'10px',
    overflow:'hidden',
    padding:'10px',
    boxShadow:'rgba(60, 64, 67, 0.3) 0px 2px 4px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 1px',
    backgroundColor:Colors.white,
    '& ul':{
        margin:'0',
        padding:'0',
        paddingTop:'25px',
        '& li':{
            fontSize:'12px',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            gap:'5px',
            marginBottom:'15px',
            '&:first-of-type':{
                marginTop:'15px',
            },
            '&:last-of-type':{
                marginTop:'20px',
            },
            '& span':{
                fontSize:'12px',
                fontWeight:500,
            }
        },  
    }
}))




export const CardCount = styled(Typography)(()=>({
    position:'absolute',
    right:'0',
    top:'0',
    backgroundColor:Colors.primary,
    color:Colors.white,
    padding:'5px 10px',
    width:'auto',
    height:'29px',
    textAlign:'center',
    '&.SnoText':{
        left:'0',
        width:'50px',
        fontSize:'12px',
    },
    '& .DeleteButton':{
        backgroundColor:'transparent',
        '& svg':{
            color:Colors.white,
        }
    }
}))

export const IconButtonContain = styled(Box)(()=>({
    display:'flex',
    gap:'5px',
    alignItems:'center',
    '& button':{
        backgroundColor:'transparent',
        '&.Edit svg':{
            color:Colors.edit,
        },
        '&.Pdf svg':{
            color:Colors.pdf,
        },
        '&.Delete svg':{
            color:Colors.delete
        },
        '&.Copy svg':{
            color:Colors.copy
        },
    }
}))

export const PublishButton = styled(Button)(()=>({
    backgroundColor:'transparent',  
    textTransform:'Capitalize',
    letterSpacing:'1px',  
    backgroundColor: 'green',
    color: '#fff',
    padding:'5px 10px',
    borderTopLeftRadius: '5x',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    position: 'absolute',
    right: '0',
}))


