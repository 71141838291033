import * as React from 'react';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { useSelector } from "react-redux";
import { ModalContain } from '../../style/HO/CreateManual';
import { ActionModal } from '../../style/utils/Confirmation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ConfirmationDialog({open=false, handleCancel, handleConfirm}) {
    const Drawer = useSelector((state) => state.drawer);
    
    return (
        <>
            {open && (
            <ModalContain className={Drawer ? " " : "DrawerOpen"}>
                <ActionModal>
                    <div className="IconContain"><DeleteForeverIcon/></div>
                    <h4 className='Title'>Are you sure you want to delete this?</h4>
                    <p>This action can't be undone!</p>
                    <div className="ButtonContain">
                        <Button variant="contained" className='Delete' onClick={handleConfirm}>Delete</Button>
                        <Button variant="contained" className='Cancel'  onClick={handleCancel}>Cancel</Button>
                    </div>

                </ActionModal>
          </ModalContain>
        )}
        </>
    );
}
